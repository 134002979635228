import React, { ChangeEvent, useState } from "react";
import { DescriptionAdderProps } from "./types";
import LoadingScreen from "../../../screens/LoadingScreen";
import InputField from "../../misc/InputField";
import { Button } from "../../misc/Button";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { useNavigate } from "react-router-dom";

const DescriptionAdder: React.FC<DescriptionAdderProps> = ({
    isMobile,
    listing,
    setListing,
    setCurrentStep,
    mlsId,
}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        console.log("name: ", name);
        console.log("value: ", value);
        if (!listing) return;

        setListing({
            ...listing,
            [name]:
                name === "Bedrooms" ||
                name === "Bathrooms" ||
                name === "Square Footage" ||
                name === "Year Built"
                    ? parseInt(value, 10)
                    : name === "lotSize"
                    ? parseFloat(value)
                    : name === "currentPrice"
                    ? parseFloat(value)
                    : value,
        });
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const payload = {
                mlsId: mlsId || listing?._id,
                footer: listing?.footer,
                latitude: listing?.latitude,
                longitude: listing?.longitude,
                formattedAddress: listing?.formattedAddress,
                additionalContent: listing?.additionalContent,
            };
            const response = await axios.post(
                `${backendUrl}/listing/create`,
                payload,
                {
                    headers,
                    withCredentials: true,
                }
            );

            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                toast.success(
                    <CustomToast
                        message="Listing creation initiated."
                        type="success"
                    />,
                    { autoClose: 3000 }
                );
                navigate("/listings");
            } else if (response.status === 410) {
                setLoading(false);
                toast.error(
                    <CustomToast
                        message="Your Listing Creation Limit has been reached. Please upgrade your plan to create more listings."
                        type="error"
                    />,
                    { autoClose: 5000 }
                );
            } else {
                throw new Error(
                    "Failed to initiate listing creation. Please try again."
                );
            }
        } catch (error) {
            setLoading(false);
            console.error("Error initiating listing creation:", error);
            toast.error(
                <CustomToast
                    message="Failed to initiate listing creation. Please try again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    if (!listing) {
        setTimeout(() => {
            window.location.href = "/listings";
        }, 3000);
        return <LoadingScreen />;
    }
    return (
        <main
            id="media-adder"
            className={`flex overflow-auto flex-col justify-center items-center min-h-full shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${
                isMobile ? "px-0 bg-white" : "bg-background"
            }`}
        >
            {loading && <LoadingScreen />}
            <div
                className={`bg-white flex flex-col ${
                    isMobile ? "w-[90%]" : "w-[70%]"
                } p-10`}
            >
                <h1 className="mb-6 text-primary text-3xl font-bold leading-none text-center">
                    Add Additional Information
                </h1>
                <div className="space-y-4">
                    <InputField
                        label="Description"
                        name="legalDescription"
                        placeholder="Description"
                        value={listing?.legalDescription}
                        onChange={handleInputChange}
                        type="textarea"
                        rows={6}
                        className="w-full"
                        disabled={true}
                    />

                    <div className="bg-gray-100 p-3 rounded-md">
                        <p className="text-sm text-[#d50000]">
                            <strong className="text-primary">Warning:</strong>{" "}
                            The property description is less than 500
                            characters. For the best quality posts, consider
                            adding more details about key features such as
                            recent upgrades, unique architectural elements,
                            nearby attractions, or community amenities.
                        </p>
                    </div>

                    <InputField
                        label="Additional Content"
                        name="additionalContent"
                        placeholder="Additional Content"
                        value={listing?.additionalContent || ""}
                        onChange={handleInputChange}
                        type="textarea"
                        rows={6}
                        className="w-full"
                    />
                </div>
                <div className="mt-6 flex justify-between items-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setCurrentStep(2);
                        }}
                    >
                        Back
                    </Button>
                    <Button onClick={handleSave}>Next</Button>
                </div>
            </div>
        </main>
    );
};

export default DescriptionAdder;
