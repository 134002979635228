import React, { useState } from "react";
import { CombinedMedia } from "../types";
import { FaPlay } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { MediaThumbnailsProps } from "./types";
import { Badge } from "../../../calendar/Badge";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { X } from "lucide-react";

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
    mediaItems,
    currentPage,
    itemsPerPage,
    toggleSelectMedia,
    selectedMedia,
    isEditing,
    carouselApi,
    combinedMedia,
    mlsApproved,
    carouselIndexMap,
    onMediaClick,
}) => {
    const isMobile = useIsMobile(1200);
    const [showMlsPopup, setShowMlsPopup] = useState(false);

    const itemsPerPageToUse = isMobile ? 4 : itemsPerPage;
    const startIndex = (currentPage - 1) * itemsPerPageToUse;
    const endIndex = startIndex + itemsPerPageToUse;

    const currentItems = mediaItems.slice(startIndex, endIndex);

    return (
        <div className="relative">
            <div
                className={`grid ${
                    isMobile ? "grid-cols-2" : "grid-cols-5"
                } gap-2`}
            >
                {currentItems.map((item, i) => {
                    const globalIndex = startIndex + i;
                    const mediaType = item.type;
                    const media = item as CombinedMedia;

                    const isSelected = selectedMedia.some(
                        (m) => m.id === media.id
                    );
                    const isDisabled = isEditing && mlsApproved && item.isMls;

                    const handleClick = () => {
                        if (isDisabled) {
                            setShowMlsPopup(true);
                            return;
                        }

                        if (isEditing) {
                            toggleSelectMedia(media);
                        } else {
                            if (carouselApi) {
                                carouselApi.scrollTo(globalIndex);
                                if (onMediaClick) {
                                    onMediaClick(globalIndex);
                                }
                            }
                        }
                    };

                    return (
                        <div
                            id={`media-${i}`}
                            key={media.id || item.name}
                            className={`relative h-32 cursor-pointer ${
                                isSelected
                                    ? "ring-2 ring-secondary rounded"
                                    : ""
                            } ${isDisabled ? "opacity-50" : ""}`}
                            onClick={handleClick}
                        >
                            {mlsApproved && isEditing && (
                                <Badge
                                    className="absolute top-1 right-1 z-40"
                                    variant="secondary"
                                >
                                    <span className="text-[10px]">
                                        {item.isMls ? "MLS" : "USER"}
                                    </span>
                                </Badge>
                            )}
                            <div className="w-[175px] h-[130px] overflow-hidden rounded">
                                {mediaType === "image" ? (
                                    <img
                                        src={item.thumbnail || item.url}
                                        alt={`${item.name}`}
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <div className="relative w-full h-full">
                                        <video
                                            src={item.url}
                                            className="w-full h-full object-cover"
                                            muted
                                            preload="metadata"
                                        />
                                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                                            <FaPlay
                                                className="text-white"
                                                size={24}
                                            />
                                        </div>
                                    </div>
                                )}
                                {isEditing && (
                                    <div className="absolute top-2 left-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl w-[25px] h-[25px]">
                                        {isSelected && (
                                            <FiCheckCircle className="text-secondary text-[18px]" />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* MLS Info Popup */}
            {showMlsPopup && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md mx-4 relative">
                        <button
                            onClick={() => setShowMlsPopup(false)}
                            className="absolute right-4 top-4 text-muted-foreground hover:text-foreground"
                        >
                            <X className="h-4 w-4 text-primary hover:text-secondary" />
                        </button>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-primary">
                                MLS Image
                            </h3>
                            <p className="text-sm text-muted-foreground">
                                This image is synced from your MLS. To update
                                MLS images, make changes in your MLS system—they
                                will automatically sync to MaraBot every hour.
                                You can also manually sync updates by clicking
                                the Sync button on the listing page.
                            </p>
                            <p className="text-sm text-muted-foreground">
                                Media uploaded directly to MaraBot can be edited
                                at any time.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MediaThumbnails;
