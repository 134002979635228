import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogContent } from "../misc/Dialog";
import { Button } from "../misc/Button";
import { FaTimes } from "react-icons/fa";
import { Post as PostType } from "../../types/posts";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { postTime } from "../campaign/types";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Textarea } from "../misc/Textarea";
import { Separator } from "../misc/Seperator";
import { cn } from "../../utils/classMerger";
import { CalendarIcon, Clock, Save } from "lucide-react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../misc/Select";
import { format, isBefore, setHours, setMinutes } from "date-fns";
import CustomDatePicker from "../misc/CutomDatePicker";
import { EMOJI_CATEGORIES } from "../misc/AllEmojis";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useIsMobile } from "../../hooks/useIsMobile";

interface EditPostDialogProps {
    isOpen: boolean;
    onClose: () => void;
    post: PostType;
    onSave: (updatedPost: Partial<PostType>) => void;
}

const EditPostDialog: React.FC<EditPostDialogProps> = ({
    isOpen,
    onClose,
    post,
    onSave,
}) => {
    const user = useSelector((state: RootState) => state.auth.user);

    const [content, setContent] = useState<string>(post.content);
    const [footer, setFooter] = useState<string>(post.footer || "");

    useEffect(() => {
        setContent(post.content);
        setFooter(post.footer || "");
    }, [post.content, post.footer]);

    const [charLimit, setCharLimit] = useState<number>(400);
    const [date, setDate] = useState<Date>(
        post.scheduledFor ? new Date(post.scheduledFor) : new Date()
    );
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    // We'll store time in 24-hour format internally.
    const initialHour = post.scheduledFor
        ? new Date(post.scheduledFor).getHours()
        : 0;
    const initialMinute = post.scheduledFor
        ? new Date(post.scheduledFor).getMinutes()
        : 0;
    const [time, setTime] = useState<postTime>({
        hour: initialHour,
        minute: initialMinute,
    });

    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Set character limit based on platform
        const platform = post.platform.toLowerCase();
        if (platform === "instagram") {
            setCharLimit(2200);
        } else if (platform === "facebook") {
            setCharLimit(63000);
        } else {
            setCharLimit(1000); // Default limit for other platforms
        }
    }, [post.platform]);

    // Compute period from time (24-hour format)
    const period = time.hour >= 12 ? "PM" : "AM";

    // Convert 24-hour hour to 12-hour format for the UI
    const displayHour = (() => {
        const h = time.hour % 12;
        return h === 0 ? 12 : h;
    })();

    // Hour and minute options (full range since we won't restrict them now)
    const hourOptions = Array.from({ length: 12 }, (_, i) => i + 1);
    const minuteOptions = Array.from({ length: 60 }, (_, i) => i);

    const handleSave = () => {
        if (content.trim().length === 0) {
            toast.error(
                <CustomToast message="Content cannot be empty." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        // Adjust the hour based on AM/PM
        let adjustedHour = displayHour;
        if (period === "PM" && displayHour !== 12) {
            adjustedHour += 12;
        } else if (period === "AM" && displayHour === 12) {
            adjustedHour = 0;
        }

        const scheduledDateTime = setHours(
            setMinutes(new Date(date), time.minute),
            adjustedHour
        );

        // Check if the selected time is in the past
        const now = new Date();
        if (isBefore(scheduledDateTime, now)) {
            toast.error(
                <CustomToast
                    message="Cannot schedule in the past."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        // Determine what changed
        const changes: Partial<PostType> = {};
        const userTimezone = user?.timeZone // IANA timezone string
            ? user.timeZone
            : "UTC";

        const newScheduledFor = scheduledDateTime.toLocaleString("en-US", {
            timeZone: userTimezone,
            hour12: false,
            timeZoneName: "short",
        });
        const newScheduledForDate = new Date(newScheduledFor).toISOString();

        const contentChanged = content !== post.content;
        const footerChanged = footer !== post.footer;
        const timeChanged = newScheduledForDate !== post.scheduledFor;

        if (contentChanged) changes.content = content;
        if (timeChanged) changes.scheduledFor = newScheduledForDate;
        if (footerChanged) changes.footer = footer;

        // If nothing changed, you could choose to do nothing or still call onSave:
        if (Object.keys(changes).length === 0) {
            return;
        }

        onSave(changes);
    };

    const handleHourChange = (value: string) => {
        // value is in 12-hour format. We'll store in state as 24-hour format
        const selectedHour12 = parseInt(value, 10);
        let newHour = selectedHour12;
        if (period === "PM" && selectedHour12 !== 12) {
            newHour = selectedHour12 + 12;
        } else if (period === "AM" && selectedHour12 === 12) {
            newHour = 0;
        }
        setTime((prev) => ({ ...prev, hour: newHour }));
    };

    const handleMinuteChange = (value: string) => {
        const newMinute = parseInt(value, 10);
        setTime((prev) => ({ ...prev, minute: newMinute }));
    };

    // Updated handlePeriodChange function
    const handlePeriodChange = (value: string) => {
        // Get the displayed hour (in 12-hour format) from the current state.
        const currentDisplayHour = time.hour % 12 === 0 ? 12 : time.hour % 12;
        // Convert the current displayed hour into 24-hour format based on the new period.
        const newHour =
            value === "AM"
                ? currentDisplayHour === 12
                    ? 0
                    : currentDisplayHour
                : currentDisplayHour === 12
                ? 12
                : currentDisplayHour + 12;
        setTime((prev) => ({ ...prev, hour: newHour }));
    };

    // Update the emoji insertion to check the available character space.
    const handleInsertEmoji = (emoji: string) => {
        if (!textareaRef.current) return;
        const start = textareaRef.current.selectionStart;
        const end = textareaRef.current.selectionEnd;

        // Calculate what the new content length would be after insertion.
        const newContentLength = content.length - (end - start) + emoji.length;
        // Make sure combined (content + footer) doesn't exceed charLimit.
        if (newContentLength + footer.length > charLimit) {
            toast.error(
                <CustomToast
                    message="Character limit reached, cannot insert emoji."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        const newContent =
            content.substring(0, start) + emoji + content.substring(end);
        setContent(newContent);

        // Force React to rerender with the new content before setting the selection
        setTimeout(() => {
            if (!textareaRef.current) return;
            textareaRef.current.focus();
            const newPosition = start + emoji.length;
            textareaRef.current.setSelectionRange(newPosition, newPosition);
        }, 0);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target as Node)
            ) {
                setIsPopoverOpen(false);
            }
        }

        if (isPopoverOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPopoverOpen]);

    const isMobile = useIsMobile(1100);
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="relative p-4 w-full min-h-full m-0 flex flex-col items-center justify-center bg-white">
                <div
                    className={`${
                        isMobile ? "mt-0 py-0" : "mt-10 pb-10"
                    } absolute top-0`}
                >
                    <button
                        className="absolute top-4 right-4 text-secondary hover:text-primary"
                        onClick={onClose}
                        aria-label="Close Dialog"
                    >
                        <FaTimes className="h-6 w-6" />
                    </button>

                    <Card
                        className={`w-full max-w-2xl mx-auto ${
                            isMobile && "mt-0"
                        }`}
                    >
                        <CardHeader>
                            <CardTitle>Edit Post</CardTitle>
                            <CardDescription className="text-center">
                                Edit the content and schedule of your social
                                media post here.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-4 pt-0">
                            <div className="space-y-2">
                                <label className="text-sm font-medium">
                                    Post Content
                                </label>
                                <Textarea
                                    ref={textareaRef}
                                    value={content}
                                    onChange={(e) => {
                                        const newContent = e.target.value;
                                        // Calculate available characters by subtracting footer length.
                                        const available =
                                            charLimit - footer.length;
                                        if (newContent.length > available) {
                                            // Optionally, you can toast a warning here.
                                            setContent(
                                                newContent.substring(
                                                    0,
                                                    available
                                                )
                                            );
                                        } else {
                                            setContent(newContent);
                                        }
                                    }}
                                    placeholder="Write your post content here..."
                                    className="min-h-[350px] resize-none"
                                />
                                {post.hasFooter ? (
                                    <div>
                                        <label className="text-sm font-medium">
                                            Post Footer
                                        </label>
                                        <Textarea
                                            value={footer}
                                            onChange={(e) => {
                                                const newFooter =
                                                    e.target.value;
                                                // Calculate available characters by subtracting content length.
                                                const available =
                                                    charLimit - content.length;
                                                if (
                                                    newFooter.length > available
                                                ) {
                                                    // Optionally, you can toast a warning here.
                                                    setFooter(
                                                        newFooter.substring(
                                                            0,
                                                            available
                                                        )
                                                    );
                                                } else {
                                                    setFooter(newFooter);
                                                }
                                            }}
                                            placeholder="Write your post footer here..."
                                            className="min-h-[200px] resize-none mt-2"
                                        />
                                    </div>
                                ) : null}
                                <div className="flex justify-between items-center text-sm text-muted-foreground">
                                    {isMobile ? null : (
                                        <div className="flex justify-between items-center">
                                            <Button
                                                id="insert-emoji-btn"
                                                onClick={() =>
                                                    setIsPopoverOpen(
                                                        !isPopoverOpen
                                                    )
                                                }
                                            >
                                                😊 Insert Emoji
                                            </Button>
                                        </div>
                                    )}

                                    {/* Custom Popover */}
                                    {isPopoverOpen && (
                                        <div
                                            className="absolute bg-white border shadow-lg rounded mt-2 p-2 w-80 z-50"
                                            style={{
                                                top: "150px",
                                                left: "50px",
                                            }}
                                            ref={popoverRef}
                                        >
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={() =>
                                                        setIsPopoverOpen(false)
                                                    }
                                                    className="p-1 hover:bg-gray-100 rounded"
                                                >
                                                    <FaTimes />
                                                </button>
                                            </div>
                                            <div className="max-h-[300px] overflow-auto p-2">
                                                {Object.entries(
                                                    EMOJI_CATEGORIES
                                                ).map(([category, emojis]) => (
                                                    <div
                                                        key={category}
                                                        className="mb-4"
                                                    >
                                                        <h3 className="mb-2 text-sm font-medium text-primary">
                                                            {category
                                                                .replace(
                                                                    /-/g,
                                                                    " "
                                                                )
                                                                .replace(
                                                                    /\b\w/g,
                                                                    (c) =>
                                                                        c.toUpperCase()
                                                                )}
                                                        </h3>
                                                        <div className="grid grid-cols-6 gap-2">
                                                            {emojis.map(
                                                                (emoji) => (
                                                                    <button
                                                                        key={
                                                                            emoji
                                                                        }
                                                                        onClick={() =>
                                                                            handleInsertEmoji(
                                                                                emoji
                                                                            )
                                                                        }
                                                                        className="h-8 w-8 flex items-center justify-center hover:bg-gray-100 rounded"
                                                                    >
                                                                        {emoji}
                                                                    </button>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    <span>
                                        {content.length + footer.length}/
                                        {charLimit} characters
                                    </span>
                                </div>
                            </div>
                            <Separator className="my-4" />
                            <div className="space-y-2">
                                <label className="text-sm font-medium">
                                    Post Time
                                </label>
                                <div
                                    className={`flex items-center space-x-2 ${
                                        isMobile
                                            ? "flex-col space-y-2"
                                            : "flex-row"
                                    }`}
                                >
                                    <Popover
                                        open={isCalendarOpen}
                                        onOpenChange={setIsCalendarOpen}
                                    >
                                        <PopoverTrigger asChild>
                                            <Button
                                                id="open-calendar-btn"
                                                variant="outline"
                                                className={cn(
                                                    "w-[240px] justify-start text-left font-normal",
                                                    !date &&
                                                        "text-muted-foreground"
                                                )}
                                            >
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {date ? (
                                                    format(date, "PPP")
                                                ) : (
                                                    <span>Change date</span>
                                                )}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            className="w-auto p-0"
                                            align="start"
                                        >
                                            <CustomDatePicker
                                                date={date}
                                                setDate={setDate}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                    <div className="flex items-center space-x-2">
                                        <Clock className="h-4 w-4 text-muted-foreground" />
                                        <Select
                                            value={displayHour.toString()}
                                            onValueChange={handleHourChange}
                                        >
                                            <SelectTrigger
                                                className="w-[70px]"
                                                id="hour-select"
                                            >
                                                <SelectValue placeholder="Hour" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {hourOptions.map((hour) => (
                                                    <SelectItem
                                                        key={hour}
                                                        value={hour.toString()}
                                                    >
                                                        {hour
                                                            .toString()
                                                            .padStart(2, "0")}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <Select
                                            value={time.minute.toString()}
                                            onValueChange={handleMinuteChange}
                                        >
                                            <SelectTrigger
                                                className="w-[70px]"
                                                id="minute-select"
                                            >
                                                <SelectValue placeholder="Min" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {minuteOptions.map((minute) => (
                                                    <SelectItem
                                                        key={minute}
                                                        value={minute.toString()}
                                                    >
                                                        {minute
                                                            .toString()
                                                            .padStart(2, "0")}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <Select
                                            value={period}
                                            onValueChange={handlePeriodChange}
                                        >
                                            <SelectTrigger
                                                className="w-[70px]"
                                                id="am-pm-select"
                                            >
                                                <SelectValue placeholder="AM/PM" />
                                            </SelectTrigger>
                                            <SelectContent id="am-pm-select">
                                                <SelectItem value="AM">
                                                    AM
                                                </SelectItem>
                                                <SelectItem value="PM">
                                                    PM
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter>
                            <Button
                                id="save-changes-btn"
                                className="w-full"
                                onClick={handleSave}
                            >
                                <Save className="mr-2 h-4 w-4" />
                                Save Changes
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditPostDialog;
