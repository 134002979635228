import { CalendarClock, BookOpen } from "lucide-react";
import { Card, CardContent } from "./Card";

export default function TutorialsComingSoon() {
    return (
        <div className="w-full py-12 flex flex-col items-center justify-center">
            <Card className="w-full max-w-3xl border-none shadow-lg bg-gradient-to-br from-background to-muted">
                <CardContent className="p-8 md:p-12">
                    <div className="flex flex-col items-center text-center space-y-8">
                        <div className="relative">
                            <div className="relative bg-secondary rounded-full p-4 border">
                                <BookOpen className="h-12 w-12 text-white" />
                            </div>
                        </div>

                        <div className="space-y-4">
                            <h2 className="text-3xl md:text-4xl font-bold tracking-tight text-primary">
                                Tutorials Coming Soon
                            </h2>
                            <p className="text-muted-foreground text-lg max-w-md mx-auto">
                                We're working hard to create helpful tutorials
                                to make your experience even better.
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
                            <div className="flex items-center gap-2 text-muted-foreground">
                                <CalendarClock className="h-5 w-5 text-secondary" />
                                <span className="text-primary">
                                    Launching in a few weeks
                                </span>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
