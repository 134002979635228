import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useRef,
    ReactNode,
} from "react";
import { io, Socket } from "socket.io-client";
import { getAuthHeader } from "../utils/authHeader";

// Define the shape of the SocketContext
interface SocketContextProps {
    socket: Socket | null;
    isAuthenticated: boolean;
}

// Create and export the SocketContext with a default value
export const SocketContext = createContext<SocketContextProps>({
    socket: null,
    isAuthenticated: false,
});

// Custom hook to use the SocketContext
export const useSocket = (): SocketContextProps => useContext(SocketContext);

// Define the props for the SocketProvider
interface SocketProviderProps {
    children: ReactNode;
}

// Create and export the SocketProvider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        const initializeSocket = async () => {
            try {
                const headers = await getAuthHeader();

                const token = headers.Authorization?.split(" ")[1]; // Extract Bearer token

                if (!token) {
                    throw new Error("Authentication token not found.");
                }

                // Initialize the Socket.io client
                const newSocket = io(
                    `${process.env.REACT_APP_BACKEND_URL}/progress`,
                    {
                        auth: {
                            token, // Send token during connection
                        },
                        withCredentials: true,
                        transports: ["websocket"], // Use WebSocket only
                    }
                );

                // Socket event listeners
                newSocket.on("connect", () => {
                    console.log("Connected to Socket.io server");
                });

                newSocket.on("authenticated", (data) => {
                    setIsAuthenticated(true);
                    console.log("Socket authenticated:", data);
                });

                newSocket.on("disconnect", (reason: string) => {
                    setIsAuthenticated(false);
                    console.log(
                        `Disconnected from Socket.io server: ${reason}`
                    );
                });

                newSocket.on("connect_error", (error: Error) => {
                    console.error("Socket connection error:", error.message);
                });

                newSocket.on("error", async (error: any) => {
                    console.error("Socket error:", error);
                    if (error === "Token expired") {
                        console.log("Token expired, regenerating token...");

                        try {
                            const newHeaders = await getAuthHeader();
                            const newToken =
                                newHeaders.Authorization?.split(" ")[1];

                            if (!newToken) {
                                console.error("Failed to regenerate token.");
                                return;
                            }

                            console.log("New token obtained, reconnecting...");

                            // Reconnect the socket with the new token
                            newSocket.auth = { token: newToken };
                            newSocket.disconnect();
                            newSocket.connect();
                        } catch (authError) {
                            console.error("Error refreshing token:", authError);
                        }
                    }
                });

                setSocket(newSocket);
                socketRef.current = newSocket;
            } catch (error: any) {
                console.error("Socket connection error:", error.message);
            }
        };
        initializeSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                console.log("Socket disconnected");
                socketRef.current = null;
            }
        };
    }, []);

    return (
        <SocketContext.Provider value={{ socket, isAuthenticated }}>
            {children}
        </SocketContext.Provider>
    );
};
