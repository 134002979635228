import React, { useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Label } from "../misc/Label";
import { Switch } from "../misc/Switch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Button } from "../../components/misc/Button";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { NotifPrefsDTO } from "./dto/notif-prefs.dto";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { setUser } from "../../store/authSlice";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";

const NotificationPreferences = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currUser, setCurrUser] = useState(
        useSelector((state: RootState) => state.auth.user)
    );

    const initialState = {
        prefFacebookNotify: currUser?.prefFacebookNotify || false,
        prefPostApproval: currUser?.prefPostApproval || false,
    };

    const [facebookNotifs, setFacebookNotifs] = React.useState<boolean>(
        initialState.prefFacebookNotify
    );

    const [approveNotifs, setApproveNotifs] = React.useState<boolean>(
        initialState.prefPostApproval
    );

    const handleToggleFactory = (
        setState: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        const ret = () => setState((prevState) => !prevState);
        return ret;
    };
    const changesMade = (): boolean => {
        return (
            facebookNotifs !== initialState.prefFacebookNotify ||
            approveNotifs !== initialState.prefPostApproval
        );
    };
    const handleSubmit = async () => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const headers = await getAuthHeader();
        const data: NotifPrefsDTO = {
            facebookNotifs,
            approveNotifs,
        };
        await axios
            .patch(`${backendUrl}/settings/notifications`, data, { headers })
            .then(() =>
                axios.get(`${backendUrl}/settings/personal-info`, { headers })
            )
            .then((response) => {
                dispatch(setUser(response.data));
                setCurrUser(response.data);
                changesMade();
            })
            .then(() =>
                toast.success(
                    <CustomToast
                        message={"Notification preferences updated"}
                        type={"success"}
                    />,
                    { autoClose: 3000 }
                )
            )
            .catch((error) => {
                console.error(
                    "Error updating notification preferences:",
                    error
                );
                toast.error(
                    <CustomToast
                        message={"Error updating notification preferences"}
                        type={"error"}
                    />,
                    { autoClose: 3000 }
                );
            });
    };
    const isMobile = useIsMobile(1200);

    return (
        <Card
            className={`w-full max-w-4xl mx-auto ${
                isMobile ? "h-full m-0 rounded-none" : "h-auto"
            }`}
        >
            <div className="p-4">
                <Button
                    id="back-to-settings-btn"
                    onClick={() => navigate("/settings")}
                    className="mb-4 flex items-center"
                >
                    <ArrowLeft className="mr-2 h-5 w-5" /> Back to Settings
                </Button>
            </div>
            <CardHeader className="mb-0 pb-0">
                <CardTitle>Notification Preferences</CardTitle>
                <CardDescription>
                    Manage your notification preferences
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col flex-[0.8]">
                        <Label
                            htmlFor="facebook-notifications"
                            className="font-semibold text-primary"
                        >
                            Facebook Post Notifications
                        </Label>
                        <span className="text-sm text-neutral-500 mt-2">
                            This option will notify you via email whenever a
                            Facebook post is published to your business profile,
                            allowing you to quickly share it on your personal
                            page.
                        </span>
                    </div>
                    <Switch
                        onClick={handleToggleFactory(setFacebookNotifs)}
                        checked={facebookNotifs}
                        id="facebook-notifications"
                    />
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col flex-[0.8]">
                        <Label
                            htmlFor="facebook-notifications"
                            className="font-semibold text-primary"
                        >
                            Unapproved Post Notifications
                        </Label>
                        <span className="text-sm text-neutral-500 mt-2">
                            This option will notify you via email whenever an
                            unapproved post is found on your profile, reminding
                            you to approve it.
                        </span>
                    </div>
                    <Switch
                        onClick={handleToggleFactory(setApproveNotifs)}
                        checked={approveNotifs}
                        id="approve-notifications"
                    />
                </div>
            </CardContent>
            <div className="flex justify-center margin mb-4">
                {changesMade() && (
                    <Button id="save-changes-btn" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                )}
            </div>
        </Card>
    );
};

export default NotificationPreferences;
