import React, { useState } from "react";
import DragAndDropMedia from "./DragAndDrop";
import { AssetType } from "../../types/asset-type";
import { MediaType } from "../../types/mediaType";

interface AssetsUploaderProps {
    listingId: string;
    onAssetsUploaded: () => void;
    assetType: AssetType;
    type?: MediaType;
}

const AssetsUploader: React.FC<AssetsUploaderProps> = ({
    listingId,
    onAssetsUploaded,
    assetType,
    type,
}) => {
    const [isUploading, setIsUploading] = useState(false);

    return (
        <div className="border p-4 rounded-md">
            <h4 className="text-base font-semibold mb-2 text-primary">
                Upload New Assets
            </h4>
            <DragAndDropMedia
                type={type || "image"}
                onUploadStart={() => {}}
                onUploadComplete={onAssetsUploaded}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                assetType={assetType}
                isAdhoc={true}
            />
        </div>
    );
};

export default AssetsUploader;
