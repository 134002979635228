import React, { useEffect, useState } from "react";
import ChoosePlatforms from "../../components/campaign/ChoosePlatforms";
import StepProgressBar from "../../components/progress-bar";
import ChooseFrequency from "../../components/campaign/ChooseFrequency";
import ChoosePostTime from "../../components/campaign/ChoosePostTime";
import ChooseCampaignOptions from "../../components/campaign/ChooseCampaignOptions";
import { useLocation } from "react-router-dom";
import {
    CampaignPlatform,
    ContentLength,
    LocationState,
    SocialPlatforms,
    SocialsConnected,
} from "../../components/campaign/types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../../components/misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import LoadingScreen from "../LoadingScreen";
import CampaignGenerationMaintenance from "../../components/campaign/CampaignGenerationMaintenance";
import { ListingType } from "../../types/userListing";
import { useIsMobile } from "../../hooks/useIsMobile";

const CreateCampaign = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(true);

    const [isOnline, setIsOnline] = useState<boolean>(false);

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [selectedPlatforms, setSelectedPlatforms] =
        useState<SocialsConnected>({
            facebook: false,
            instagram: false,
        });
    const [frequency, setFrequency] = React.useState("daily");
    const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
    const [date, setDate] = React.useState<Date>();

    const [campaignPlatforms, setCampaignPlatforms] =
        useState<CampaignPlatform[]>();

    useEffect(() => {
        const platforms: CampaignPlatform[] = [];
        if (selectedPlatforms.facebook) {
            platforms.push({
                name: SocialPlatforms.Facebook,
                postsPerDay: 1,
                postTimes: [{ hour: 8, minute: 30 }],
                contentLengths: [ContentLength.Long],
            });
        }
        if (selectedPlatforms.instagram) {
            platforms.push({
                name: SocialPlatforms.Instagram,
                postsPerDay: 1,
                postTimes: [{ hour: 8, minute: 30 }],
                contentLengths: [ContentLength.Short],
            });
        }
        setCampaignPlatforms(platforms);
    }, [selectedPlatforms]);

    const [autoApprove, setAutoApprove] = React.useState<boolean>(true);
    const [soldPost, setSoldPost] = React.useState<boolean>(true);
    const [useEmojis, setUseEmojis] = React.useState<boolean>(true);
    const [usePrice, setUsePrice] = React.useState<boolean>(true);

    const listingId =
        (location.state as LocationState)?.listingId ||
        sessionStorage.getItem("createCampaign");

    const listingType =
        (location.state as LocationState)?.listingType ||
        sessionStorage.getItem("listingType");

    const from =
        (location.state as LocationState)?.from ||
        sessionStorage.getItem("from");

    useEffect(() => {
        if (listingId && listingType && from) {
            // Store listingId in session storage
            console.log("Listing Id: ", listingId);
            sessionStorage.setItem("createCampaign", listingId);
            sessionStorage.setItem("listingType", listingType);
            sessionStorage.setItem("from", from);
        } else {
            toast.error(
                <CustomToast
                    message="Listing Id and/or Listing Type is not found. Try again from listings page."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            navigate(-1);
        }
    }, [listingId, listingType, navigate, from]);

    useEffect(() => {
        const fetchStatus = async () => {
            setLoading(true);
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            try {
                const response = await axios.get(
                    `${backendUrl}/listing/campaign-check`,
                    { headers }
                );

                if (response.status === 200) {
                    setIsOnline(response.data);
                }
            } catch (error: unknown) {
                console.error("Failed to fetch status:", error);
                setIsOnline(false);
            } finally {
                setLoading(false);
            }
        };

        fetchStatus();
    }, []);

    let progressSteps = [
        { label: "Choose Platforms", isActive: true, isCompleted: false },
        { label: "Choose Frequency", isActive: false, isCompleted: false },
        { label: "Choose Post Time", isActive: false, isCompleted: false },
        {
            label: "Choose Campaign Options",
            isActive: false,
            isCompleted: false,
        },
    ];

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <ChoosePlatforms
                        setCurrentStep={setCurrentStep}
                        selectedPlatforms={selectedPlatforms}
                        setSelectedPlatforms={setSelectedPlatforms}
                        from={from}
                        listingId={listingId}
                    />
                );
            case 2:
                progressSteps[0].isCompleted = true;
                progressSteps[1].isActive = true;
                return (
                    <ChooseFrequency
                        setCurrentStep={setCurrentStep}
                        frequency={frequency}
                        setFrequency={setFrequency}
                        selectedDays={selectedDays}
                        setSelectedDays={setSelectedDays}
                        date={date}
                        setDate={setDate}
                        selectedPlatforms={selectedPlatforms}
                    />
                );
            case 3:
                progressSteps[0].isCompleted = true;
                progressSteps[1].isActive = true;
                progressSteps[1].isCompleted = true;
                progressSteps[2].isActive = true;
                return (
                    <ChoosePostTime
                        setCurrentStep={setCurrentStep}
                        campaignPlatforms={campaignPlatforms}
                        setCampaignPlatforms={setCampaignPlatforms}
                    />
                );
            case 4:
                progressSteps[0].isCompleted = true;
                progressSteps[1].isActive = true;
                progressSteps[1].isCompleted = true;
                progressSteps[2].isActive = true;
                progressSteps[2].isCompleted = true;
                progressSteps[3].isActive = true;
                return (
                    <ChooseCampaignOptions
                        setCurrentStep={setCurrentStep}
                        autoApprove={autoApprove}
                        setAutoApprove={setAutoApprove}
                        soldPost={soldPost}
                        setSoldPost={setSoldPost}
                        useEmojis={useEmojis}
                        setUseEmojis={setUseEmojis}
                        createCampaign={handleCreateCampaign}
                        listingType={listingType}
                        usePrice={usePrice}
                        setUsePrice={setUsePrice}
                    />
                );
            default:
                return (
                    <ChoosePlatforms
                        setCurrentStep={setCurrentStep}
                        selectedPlatforms={selectedPlatforms}
                        setSelectedPlatforms={setSelectedPlatforms}
                        from={from}
                        listingId={listingId}
                    />
                );
        }
    };

    const handleCreateCampaign = async () => {
        if (!listingId || !listingType) {
            toast.error(
                <CustomToast
                    message="Listing Id and/or Listing Type is not found. Try again from listings / promotions page."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }
        const platforms = [];
        if (selectedPlatforms.facebook) platforms.push("Facebook");
        if (selectedPlatforms.instagram) platforms.push("Instagram");
        console.log(platforms);
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const url =
                listingType === "Promotion"
                    ? `${backendUrl}/promotions/campaign`
                    : `${backendUrl}/listing/campaign`;

            const payload = {
                listingId: listingId,
                listingType:
                    listingType === "MLSListing"
                        ? ListingType.MLSListing
                        : listingType === "Listing"
                        ? ListingType.RentcastListing
                        : null,
                schedule: {
                    platforms: campaignPlatforms,
                    frequency:
                        frequency.charAt(0).toUpperCase() + frequency.slice(1),
                    dayOfWeek: selectedDays.map(
                        (day) =>
                            day.charAt(0).toUpperCase() +
                            day.slice(1).toLowerCase()
                    ),
                },
                emojis: useEmojis,
                startDate: date,
                autoApprove: autoApprove,
                soldPost: soldPost,
                usePrice: usePrice,
            };

            const response = await axios.post(url, payload, { headers });

            if (response.status === 201) {
                setLoading(false);
                toast.success(
                    <CustomToast
                        message="Campaign generation started!"
                        type="success"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
                // clear session storage
                sessionStorage.removeItem("createCampaign");
                sessionStorage.removeItem("listingType");
                sessionStorage.removeItem("from");
                sessionStorage.removeItem("listingId");

                if (listingType === "Promotion") {
                    navigate("/promotions");
                } else {
                    navigate("/listing", { state: { listingId: listingId } });
                }
            }
        } catch (error: unknown) {
            console.error(error);
            setLoading(false);
            toast.error(
                <CustomToast
                    message="An error occurred while creating the campaign. Please try again."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const isMobile = useIsMobile(900);

    console.log("Is Online: ", isOnline);
    if (!isOnline && !loading) {
        return <CampaignGenerationMaintenance />;
    }

    return (
        <main
            className={`flex overflow-hidden flex-col justify-center items-center min-h-full ${
                isMobile ? "bg-white" : "bg-background"
            } shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5`}
        >
            {loading && <LoadingScreen />}
            <section
                className={`flex flex-col items-center ${
                    isMobile ? "p-4 h-[84vh]" : "px-14 py-20"
                } max-w-full bg-white rounded-2xl h-full w-[905px] max-md:px-5 max-md:pb-24 relative`}
            >
                <header className="flex flex-col mx-auto w-full max-md:max-w-full">
                    <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                        Create a Campaign
                    </h1>
                    <StepProgressBar
                        steps={progressSteps.filter((step) => step)}
                    />
                </header>
                <div className="flex flex-col mx-auto mt-2 w-full max-md:max-w-full">
                    {renderStep()}
                </div>
            </section>
        </main>
    );
};

export default CreateCampaign;
