import React, { useEffect, useState } from "react";
import { customStatuses } from "../../utils/customStatuses";
import { Loader } from "lucide-react";
import { useIsMobile } from "../../hooks/useIsMobile";

const RandomStatus = () => {
    const [customStatus, setCustomStatus] = useState<string>("");

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        const updateStatus = () => {
            // Pick a random status from our list
            const randomIndex = Math.floor(
                Math.random() * customStatuses.length
            );
            setCustomStatus(customStatuses[randomIndex]);

            // Set a random delay between 1.5 and 3 seconds before updating again
            const randomDelay =
                Math.floor(Math.random() * (3000 - 1500 + 1)) + 1500;
            timer = setTimeout(updateStatus, randomDelay);
        };

        updateStatus();

        return () => clearTimeout(timer);
    }, []);

    const isMobile = useIsMobile(700);

    return (
        <div className="absolute w-full h-full flex items-center justify-center bg-white bg-opacity-50 backdrop-blur-sm p-4 z-50">
            <div
                className={`relative ${
                    isMobile ? "w-full max-w-md" : "w-1/3"
                } rounded-lg bg-white p-6 shadow-lg z-50 border border-primary`}
            >
                <div>
                    <div className="flex items-center justify-center my-4">
                        <dt className="flex items-center justify-center font-medium text-xl text-accent">
                            <Loader className="mr-2 h-8 w-8 animate-spin text-secondary" />
                            {customStatus}
                        </dt>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RandomStatus;
