import { useEffect, useState, useMemo } from "react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../misc/Dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../misc/Tabs";
import { Button } from "../misc/Button";
import { ArchiveMedia, MediaPickerDialogProps } from "./types";
import { UnifiedMedia } from "../listings/slideshow/types";
import { Assets } from "../../types/asset-type";
import PostMediaSelector from "./PostMediaSelector";
import { ResourceType } from "../content/types";
import { PromotionType } from "../promotions/types";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";

const MediaPickerDialog: React.FC<MediaPickerDialogProps> = ({
    isOpen,
    onClose,
    onNext,
    media,
    selectedMediaIds,
    setSelectedMediaIds,
    isMobile,
    platform,
    listingId,
    fetchMedia,
}) => {
    const [activeResourceType, setActiveResourceType] =
        useState<ResourceType | null>(ResourceType.Image);

    const [activePromotionType, setActivePromotionType] =
        useState<PromotionType | null>(null);

    const maxMedia = 10;
    const [minMedia, setMinMedia] = useState<number>(
        platform === "facebook" ? 0 : 1
    );

    const [activeTab, setActiveTab] = useState<
        "images" | "videos" | "promotionImages" | "assets"
    >(
        media?.images?.length
            ? "images"
            : media?.videos?.length
            ? "videos"
            : media?.promotionImages?.length
            ? "promotionImages"
            : "assets"
    );

    // Combine all media into a single list
    const allMedia: UnifiedMedia[] = useMemo(() => {
        if (!media) return [];

        const mapAssetsToUnifiedMedia = (asset: Assets): UnifiedMedia => ({
            ...asset,
            mediaType: asset.assetType === "Video" ? "video" : "image",
        });

        const mapArchiveToUnifiedMedia = (
            archive: ArchiveMedia
        ): UnifiedMedia => ({
            ...archive,
            mediaType: archive.type === "Removed Video" ? "video" : "image",
        });

        return [
            ...(media.images || []).map(
                (image) => ({ ...image, mediaType: "image" } as UnifiedMedia)
            ),
            ...(media.videos || []).map(
                (video) => ({ ...video, mediaType: "video" } as UnifiedMedia)
            ),
            ...(media.promotionImages || []).map(
                (image) => ({ ...image, mediaType: "image" } as UnifiedMedia)
            ),
            ...(media.assets || []).map(mapAssetsToUnifiedMedia),
            ...(media.archived || []).map(mapArchiveToUnifiedMedia),
        ];
    }, [media]);

    // Determine if a video or images are selected
    const hasVideoSelected = useMemo(
        () =>
            selectedMediaIds.some((id) => {
                const mediaItem = allMedia.find((item) => item.id === id);
                return mediaItem?.mediaType === "video";
            }),
        [selectedMediaIds, allMedia]
    );

    const hasImageSelected = useMemo(
        () =>
            selectedMediaIds.some((id) => {
                const mediaItem = allMedia.find((item) => item.id === id);
                return mediaItem?.mediaType === "image";
            }),
        [selectedMediaIds, allMedia]
    );

    const handleMediaSelect = (id: string) => {
        setSelectedMediaIds((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((mediaId) => mediaId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    useEffect(() => {
        setMinMedia(platform === "facebook" ? 0 : 1);
    }, [platform]);

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className={`max-w-4xl bg-white ${
                    isMobile ? "w-full h-full p-4" : ""
                }`}
            >
                <DialogHeader className="relative">
                    <DialogTitle>Select Media</DialogTitle>
                </DialogHeader>
                <Tabs
                    value={activeTab}
                    onValueChange={(value: string) =>
                        setActiveTab(
                            value as
                                | "images"
                                | "videos"
                                | "promotionImages"
                                | "assets"
                        )
                    }
                    className="mt-4"
                >
                    <TabsList>
                        {media?.images && media?.images?.length > 0 && (
                            <TabsTrigger value="images">Images</TabsTrigger>
                        )}

                        {media.videos && media?.videos?.length > 0 && (
                            <TabsTrigger value="videos">Videos</TabsTrigger>
                        )}

                        {media.promotionImages &&
                            media?.promotionImages?.length > 0 && (
                                <TabsTrigger value="promotionImages">
                                    Promotion
                                </TabsTrigger>
                            )}

                        <TabsTrigger value="assets">Shared Media</TabsTrigger>
                    </TabsList>

                    {/* Images Tab now uses assetImage upload and will switch to assets tab on upload */}
                    <TabsContent value="images">
                        <PostMediaSelector
                            images={media.images}
                            assets={media.assets}
                            selectedMedia={selectedMediaIds}
                            setSelectedMedia={setSelectedMediaIds}
                            onSelect={handleMediaSelect}
                            maxMedia={maxMedia}
                            minMedia={minMedia}
                            showAssetsUploader={true}
                            listingId={listingId}
                            onAssetsUploaded={fetchMedia}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            type="assetImage"
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Videos Tab now uses assetVideo upload and will switch to assets tab on upload */}
                    <TabsContent value="videos">
                        <PostMediaSelector
                            videos={media.videos}
                            assets={media.assets}
                            selectedMedia={selectedMediaIds}
                            setSelectedMedia={setSelectedMediaIds}
                            onSelect={handleMediaSelect}
                            maxMedia={maxMedia}
                            minMedia={minMedia}
                            showAssetsUploader={true}
                            listingId={listingId}
                            onAssetsUploaded={fetchMedia}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            type="assetVideo"
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    {/* Promotion Tab remains unchanged */}
                    <TabsContent value="promotionImages">
                        <PostMediaSelector
                            promotionImages={media.promotionImages}
                            assets={media.assets}
                            selectedMedia={selectedMediaIds}
                            setSelectedMedia={setSelectedMediaIds}
                            onSelect={handleMediaSelect}
                            maxMedia={maxMedia}
                            minMedia={minMedia}
                            showAssetsUploader={true}
                            listingId={listingId}
                            onAssetsUploaded={fetchMedia}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            type="assetImage"
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>

                    <TabsContent value="assets">
                        <PostMediaSelector
                            assets={media.assets}
                            selectedMedia={selectedMediaIds}
                            setSelectedMedia={setSelectedMediaIds}
                            onSelect={handleMediaSelect}
                            maxMedia={maxMedia}
                            minMedia={minMedia}
                            showAssetsUploader={true}
                            listingId={listingId}
                            onAssetsUploaded={fetchMedia}
                            hasVideoSelected={hasVideoSelected}
                            hasImageSelected={hasImageSelected}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            type="asset"
                            activeResourceType={activeResourceType}
                            setActiveResourceType={setActiveResourceType}
                            activePromotionType={activePromotionType}
                            setActivePromotionType={setActivePromotionType}
                        />
                    </TabsContent>
                </Tabs>
                <div className="my-4 flex flex-row justify-center">
                    <p className="text-sm text-gray-500">
                        If you’d like to return to the prior screen without
                        saving changes, please hit cancel.
                    </p>
                </div>
                <div className="flex justify-between mt-4">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (selectedMediaIds.length < minMedia) {
                                toast.error(
                                    <CustomToast
                                        message="Please select at least one media"
                                        type="error"
                                    />,
                                    { autoClose: 3000 }
                                );
                            } else {
                                onNext();
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default MediaPickerDialog;
