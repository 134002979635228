import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "./Button";
import { FaFileImage, FaFileVideo } from "react-icons/fa6";
import axios, { AxiosProgressEvent } from "axios";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "./CustomToast";
import { v4 as uuidv4 } from "uuid";
import { DragAndDropMediaProps } from "../listings/types";
import { useIsMobile } from "../../hooks/useIsMobile";
import { FaCloudUploadAlt } from "react-icons/fa";
import FeatureItem from "../onboarding/FeatureItem";

interface FileWithPreview {
    id: string;
    file: File;
    preview: string;
}

// Define maximum video size (50 MB)
const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50 MB in bytes
const MAX_IMAGE_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

const DragAndDropMedia: React.FC<DragAndDropMediaProps> = ({
    type,
    listingId,
    onUploadStart,
    onUploadComplete,
    propertyAddress1,
    mlsApproved,
    assetType,
    isUploading,
    setIsUploading,
    listingType,
    isAdhoc = false,
}) => {
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const processingTimerRef = useRef<NodeJS.Timeout | null>(null);

    const isMobile = useIsMobile(700);

    // Clean up object URLs to prevent memory leaks
    useEffect(() => {
        return () => {
            files.forEach((fileWithPreview) =>
                URL.revokeObjectURL(fileWithPreview.preview)
            );
        };
    }, [files]);

    const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleFiles = useCallback(
        (selectedFiles: File[]) => {
            let validFiles: File[] = [];
            let invalidFiles: string[] = [];

            selectedFiles.forEach((file) => {
                const isImage =
                    file.type.startsWith("image/") && file.type !== "image/gif";
                const isVideo = file.type.startsWith("video/");

                switch (type) {
                    case "image":
                    case "logo":
                    case "assetImage":
                        if (isImage) {
                            if (file.size > MAX_IMAGE_SIZE) {
                                invalidFiles.push(
                                    `${file.name} (exceeds 25 MB)`
                                );
                            } else {
                                validFiles.push(file);
                            }
                        } else {
                            invalidFiles.push(file.name);
                        }
                        break;
                    case "video":
                    case "assetVideo":
                        if (isVideo) {
                            if (file.size > MAX_VIDEO_SIZE) {
                                invalidFiles.push(
                                    `${file.name} (exceeds 50 MB)`
                                );
                            } else {
                                validFiles.push(file);
                            }
                        } else {
                            invalidFiles.push(file.name);
                        }
                        break;
                    case "asset":
                        if (isImage || isVideo) {
                            if (isVideo && file.size > MAX_VIDEO_SIZE) {
                                invalidFiles.push(
                                    `${file.name} (exceeds 50 MB)`
                                );
                            } else if (isImage && file.size > MAX_IMAGE_SIZE) {
                                validFiles.push(file);
                            } else {
                                invalidFiles.push(file.name);
                            }
                        } else {
                            invalidFiles.push(file.name);
                        }
                        break;
                    default:
                        // Default behavior if needed
                        break;
                }
            });

            // Map valid files to include preview URLs
            const newFiles: FileWithPreview[] = validFiles.map((file) => ({
                id: uuidv4(),
                file,
                preview: URL.createObjectURL(file),
            }));

            setFiles((prevFiles) => [...prevFiles, ...newFiles]);

            // Notify user about invalid or oversized files
            if (invalidFiles.length > 0) {
                const message =
                    type === "video" ||
                    type === "assetVideo" ||
                    type === "asset"
                        ? `The following video file(s) exceed the 50 MB limit or are invalid and were not added: ${invalidFiles.join(
                              ", "
                          )}`
                        : `The following file(s) are invalid and were not added: ${invalidFiles.join(
                              ", "
                          )}`;
                toast.error(<CustomToast message={message} type="error" />, {
                    autoClose: 5000,
                });
            }
        },
        [type]
    );

    const removeFile = (idToRemove: string) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.filter(
                (file) => file.id !== idToRemove
            );
            const removedFile = prevFiles.find(
                (file) => file.id === idToRemove
            );
            if (removedFile) {
                URL.revokeObjectURL(removedFile.preview);
            }
            return updatedFiles;
        });
    };

    const onDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            const droppedFiles = Array.from(e.dataTransfer.files);
            handleFiles(droppedFiles);
        },
        [handleFiles]
    );

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            handleFiles(Array.from(e.target.files));
            // Reset the input value to allow uploading the same file again if needed
            e.target.value = "";
        }
    };

    const handleImageUpload = async () => {
        if (files.length === 0) {
            toast.error(
                <CustomToast message="No files to upload." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        setIsUploading(true);
        setUploadError(null);
        setUploadProgress(0);

        onUploadStart?.();

        const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
        const authHeader = await getAuthHeader();

        try {
            const signedUrlPromises = files.map(async (fileWithPreview) => {
                const extension = fileWithPreview.file.name
                    .split(".")
                    .pop()
                    ?.toLowerCase();
                if (!extension) {
                    throw new Error(
                        `File ${fileWithPreview.file.name} has no extension.`
                    );
                }

                const filename = `${uuidv4()}.${extension}`;

                const response = await axios.post(
                    `${backendUrl}/listing/signed-url`,
                    {
                        filename,
                        contentType: fileWithPreview.file.type,
                        type: "image",
                    },
                    { headers: authHeader }
                );
                return {
                    file: fileWithPreview.file,
                    signedUrl: response.data.signedUrl,
                    filename,
                };
            });

            const signedUrls = await Promise.all(signedUrlPromises);

            const totalMB =
                files.reduce((acc, file) => acc + file.file.size, 0) / 1e6;
            const processingTimeMs = totalMB * 400;

            const uploadPromises = signedUrls.map(
                async ({ file, signedUrl, filename }) => {
                    await axios.put(signedUrl, file, {
                        headers: {
                            "Content-Type": file.type,
                        },
                        onUploadProgress: (
                            progressEvent: AxiosProgressEvent
                        ) => {
                            if (progressEvent.total) {
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                );
                                setUploadProgress((prevProgress) => {
                                    const uploadPhaseMax = 80;
                                    const uploadPercentage =
                                        (percentCompleted / 100) *
                                        uploadPhaseMax;

                                    return Math.max(
                                        prevProgress,
                                        uploadPercentage
                                    );
                                });
                            }
                        },
                    });

                    return { filename: `${listingId}/${file.name}` };
                }
            );

            await Promise.all(uploadPromises);

            setUploadProgress(80);

            const expectedProcessingTimeMs = processingTimeMs;

            const processingStartTime = Date.now();

            processingTimerRef.current = setInterval(() => {
                const now = Date.now();
                const elapsed = now - processingStartTime;
                const progressFraction = elapsed / expectedProcessingTimeMs;
                const newProgress = 80 + 19 * progressFraction;

                if (newProgress >= 99) {
                    setUploadProgress(99);
                    clearInterval(processingTimerRef.current!);
                    processingTimerRef.current = null;
                } else {
                    setUploadProgress(newProgress);
                }
            }, 100);

            const patchPromise = axios.patch(
                `${backendUrl}/listing/images`,
                {
                    listingId,
                    listingType,
                    filenames: signedUrls.map(({ filename }) => filename),
                    names: signedUrls.map(({ file }) => file.name),
                },
                { headers: authHeader }
            );

            await patchPromise;

            if (processingTimerRef.current) {
                clearInterval(processingTimerRef.current);
                processingTimerRef.current = null;
            }
            setUploadProgress(100);

            toast.success(
                <CustomToast
                    message="Images uploaded successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );

            onUploadComplete?.();
            setFiles([]);
        } catch (error: unknown) {
            console.error(error);
            setUploadError("An unexpected error occurred.");
            toast.error(
                <CustomToast
                    message="An unexpected error occurred."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setIsUploading(false);

            if (processingTimerRef.current) {
                clearInterval(processingTimerRef.current);
                processingTimerRef.current = null;
            }

            setTimeout(() => setUploadProgress(0), 1000);
        }
    };

    const handleVideoUpload = async () => {
        if (files.length === 0) {
            toast.error(
                <CustomToast message="No files to upload." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        setIsUploading(true);
        setUploadError(null);
        setUploadProgress(0);

        onUploadStart?.();

        try {
            const authHeader = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

            for (let i = 0; i < files.length; i++) {
                const fileWithPreview = files[i];

                // Generate filename
                const baseAddress = propertyAddress1
                    ? propertyAddress1.replace(/\s+/g, "_")
                    : "video";
                const randomSalt = uuidv4().split("-")[0]; // Get first segment
                const counter = i + 1;
                const extension = fileWithPreview.file.name.split(".").pop();
                const filename = `${baseAddress}-${randomSalt}-${counter}.${extension}`;
                const contentType = fileWithPreview.file.type;
                // Request signed URL from backend
                const signedUrlResponse = await axios.post(
                    `${backendUrl}/listing/signed-url`,
                    {
                        filename,
                        contentType,
                        type: "video",
                    },
                    { headers: authHeader }
                );

                const { signedUrl } = signedUrlResponse.data;

                // Upload file to GCS
                await axios.put(signedUrl, fileWithPreview.file, {
                    headers: {
                        "Content-Type": fileWithPreview.file.type,
                    },
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        }
                    },
                });

                // Inform backend of the uploaded video
                await axios.patch(
                    `${backendUrl}/listing/videos/${listingId}`,
                    {
                        filename,
                        listingType,
                    },
                    { headers: authHeader }
                );
            }

            toast.success(
                <CustomToast
                    message="Videos uploaded successfully."
                    type="success"
                />,
                {
                    autoClose: 3000,
                }
            );

            onUploadComplete?.();

            setFiles([]);
        } catch (error: unknown) {
            setUploadError("An unexpected error occurred.");
            toast.error(
                <CustomToast
                    message="An unexpected error occurred."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const handleAssetsUpload = async () => {
        if (files.length === 0) {
            toast.error(
                <CustomToast message="No files to upload." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        setIsUploading(true);
        setUploadError(null);
        setUploadProgress(0);

        onUploadStart?.();

        const imageFiles = files.filter((fileWithPreview) =>
            fileWithPreview.file.type.startsWith("image/")
        );
        const videoFiles = files.filter((fileWithPreview) =>
            fileWithPreview.file.type.startsWith("video/")
        );

        try {
            const authHeader = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

            const totalMB =
                files.reduce((acc, file) => acc + file.file.size, 0) / 1e6;
            const processingTimeMs = totalMB * 400;

            const signedUrlPromises = [
                ...imageFiles.map(async (fileWithPreview) => {
                    const extension = fileWithPreview.file.name
                        .split(".")
                        .pop()
                        ?.toLowerCase();
                    if (!extension) {
                        throw new Error(
                            `File ${fileWithPreview.file.name} has no extension.`
                        );
                    }

                    const filename = `${uuidv4()}.${extension}`;
                    const response = await axios.post(
                        `${backendUrl}/assets/signed-url`,
                        {
                            filename,
                            contentType: fileWithPreview.file.type,
                            type: "image",
                        },
                        { headers: authHeader }
                    );
                    return {
                        file: fileWithPreview.file,
                        signedUrl: response.data.signedUrl,
                        filename,
                        originalName: fileWithPreview.file.name,
                    };
                }),
                ...videoFiles.map(async (fileWithPreview, index) => {
                    const extension = fileWithPreview.file.name
                        .split(".")
                        .pop()
                        ?.toLowerCase();
                    if (!extension) {
                        throw new Error(
                            `File ${fileWithPreview.file.name} has no extension.`
                        );
                    }

                    const baseAddress = propertyAddress1
                        ? propertyAddress1.replace(/\s+/g, "_")
                        : "asset_video";
                    const randomSalt = uuidv4().split("-")[0];
                    const counter = index + 1;
                    const filename = `${baseAddress}-${randomSalt}-${counter}.${extension}`;
                    const response = await axios.post(
                        `${backendUrl}/assets/signed-url`,
                        {
                            filename,
                            contentType: fileWithPreview.file.type,
                            type: "video",
                        },
                        { headers: authHeader }
                    );
                    return {
                        file: fileWithPreview.file,
                        signedUrl: response.data.signedUrl,
                        filename,
                        originalName: fileWithPreview.file.name,
                    };
                }),
            ];

            const signedUrls = await Promise.all(signedUrlPromises);

            const uploadPromises = signedUrls.map(
                async ({ file, signedUrl, filename }) => {
                    await axios.put(signedUrl, file, {
                        headers: {
                            "Content-Type": file.type,
                        },
                        onUploadProgress: (
                            progressEvent: AxiosProgressEvent
                        ) => {
                            if (progressEvent.total) {
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                );
                                setUploadProgress((prevProgress) => {
                                    const uploadPhaseMax = 80;
                                    const uploadPercentage =
                                        (percentCompleted / 100) *
                                        uploadPhaseMax;
                                    return Math.max(
                                        prevProgress,
                                        uploadPercentage
                                    );
                                });
                            }
                        },
                    });
                    return {
                        filename,
                        originalName: file.name,
                    };
                }
            );

            const uploadedAssets = await Promise.all(uploadPromises);

            setUploadProgress(80);

            const expectedProcessingTimeMs = processingTimeMs;

            const processingStartTime = Date.now();

            const processingTimer = setInterval(() => {
                const now = Date.now();
                const elapsed = now - processingStartTime;
                const progressFraction = elapsed / expectedProcessingTimeMs;
                const newProgress = 80 + Math.min(progressFraction * 19, 19);

                setUploadProgress(newProgress);

                if (elapsed >= expectedProcessingTimeMs) {
                    clearInterval(processingTimer);
                }
            }, 100);

            const imageFilenames = uploadedAssets
                .map(({ filename }) => filename.toLowerCase())
                .filter(
                    (filename) =>
                        filename.endsWith(".png") ||
                        filename.endsWith(".jpg") ||
                        filename.endsWith(".jpeg") ||
                        filename.endsWith(".webp")
                );
            const videoFilenames = uploadedAssets
                .map(({ filename }) => filename.toLowerCase())
                .filter(
                    (filename) =>
                        filename.endsWith(".mp4") ||
                        filename.endsWith(".mov") ||
                        filename.endsWith(".avi") ||
                        filename.endsWith(".wmv") ||
                        filename.endsWith(".flv") ||
                        filename.endsWith(".mkv")
                );

            const assetTypes = [
                "logo",
                "assetImage",
                "assetVideo",
                "Image",
                "Video",
                "Logo",
            ];

            const determinedListingType = assetType
                ? assetTypes.includes(assetType || "")
                    ? null
                    : "Promotion"
                : mlsApproved
                ? "MLSListing"
                : "RentcastListing";

            const backendPromises = [
                imageFilenames.length > 0
                    ? axios.post(
                          `${backendUrl}/assets/images`,
                          {
                              listingId,
                              listingType: determinedListingType,
                              filenames: imageFilenames,
                              names: imageFilenames.map((filename) =>
                                  filename.split("/").pop()
                              ),
                              assetType,
                          },
                          { headers: authHeader }
                      )
                    : null,
                videoFilenames.length > 0
                    ? axios.post(
                          `${backendUrl}/assets/videos`,
                          {
                              filenames: videoFilenames,
                              listingId,
                              listingType: determinedListingType,
                          },
                          { headers: authHeader }
                      )
                    : null,
            ].filter(Boolean);

            await Promise.all(backendPromises);

            clearInterval(processingTimer);
            setUploadProgress(100);

            toast.success(
                <CustomToast
                    message="Assets uploaded successfully."
                    type="success"
                />,
                {
                    autoClose: 3000,
                }
            );

            onUploadComplete?.();

            setFiles([]);
        } catch (error: unknown) {
            console.error(error);
            setUploadError("An unexpected error occurred.");
            toast.error(
                <CustomToast
                    message="An unexpected error occurred."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const handleUpload = () => {
        if (type === "image") {
            handleImageUpload();
        } else if (type === "video") {
            handleVideoUpload();
        } else if (
            type === "asset" ||
            type === "assetImage" ||
            type === "assetVideo" ||
            type === "logo"
        ) {
            handleAssetsUpload();
        }
    };

    return (
        <div
            className={`w-full relative mx-auto py-4 ${
                isMobile ? "pt-6 px-0" : "pt-8 p-8"
            }`}
        >
            {/* Drag and Drop Area */}
            <div
                onDragOver={onDragOver}
                onDrop={onDrop}
                className={`border-2 border-dashed rounded-lg p-6 sm:p-8 text-center cursor-pointer hover:border-gray-400 transition-colors px-4`}
                onClick={handleButtonClick}
                tabIndex={0}
                role="button"
                aria-label={`Drag and drop area for ${
                    type === "image" || type === "assetImage"
                        ? "images"
                        : type === "video" || type === "assetVideo"
                        ? "videos"
                        : type === "asset"
                        ? "images and videos"
                        : type === "logo"
                        ? "logo"
                        : "media"
                }`}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        handleButtonClick();
                    }
                }}
            >
                <FaCloudUploadAlt
                    className="mx-auto text-secondary mb-4"
                    size={isMobile ? 36 : 48} // Adjust icon size based on device
                />
                <p
                    className={`text-primary mb-2 ${
                        isMobile ? "text-base" : "text-lg"
                    }`}
                >
                    Drag and drop your{" "}
                    {type === "image" || type === "assetImage"
                        ? "images"
                        : type === "video" || type === "assetVideo"
                        ? "videos"
                        : type === "asset"
                        ? "images and videos"
                        : type === "logo"
                        ? "logos"
                        : "media"}{" "}
                    here
                </p>
                <p
                    className={`text-primary text-sm ${
                        isMobile ? "text-xs" : ""
                    }`}
                >
                    or
                </p>
                <Button
                    id="select-files-button"
                    type="button"
                    size="sm"
                    className={`mt-2 ${isMobile ? "text-sm px-4 py-2" : ""}`}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        handleButtonClick();
                    }}
                >
                    Select Files
                </Button>
                <input
                    id="media-upload-input"
                    ref={fileInputRef}
                    type="file"
                    multiple
                    accept={
                        type === "image" || type === "assetImage"
                            ? "image/png, image/jpeg, image/webp"
                            : type === "video" || type === "assetVideo"
                            ? "video/*"
                            : type === "asset"
                            ? "image/png, image/jpeg, image/webp, video/*"
                            : "image/png, image/jpeg, image/webp, video/*"
                    }
                    className="hidden"
                    onChange={handleFileInputChange}
                    aria-label="File input"
                />
            </div>

            {/* Optional: Inform users about video size limit */}
            <div className="mt-2 text-sm text-gray-500">
                {(type === "image" || type === "assetImage") && (
                    <p>Maximum image size: 25 MB per file.</p>
                )}
                {(type === "video" ||
                    type === "assetVideo" ||
                    type === "asset") && (
                    <p>Maximum video size: 50 MB per file.</p>
                )}
            </div>

            {/* File Previews */}
            {files.length > 0 && (
                <div className="mt-6">
                    <div
                        className={`grid gap-4 ${
                            isMobile
                                ? "grid-cols-2"
                                : "grid-cols-3 md:grid-cols-4"
                        }`}
                    >
                        {files.map((fileWithPreview) => (
                            <div
                                key={fileWithPreview.id} // Updated key
                                className="relative group"
                            >
                                {fileWithPreview.file.type.startsWith(
                                    "image/"
                                ) ? (
                                    <img
                                        src={fileWithPreview.preview}
                                        alt={fileWithPreview.file.name}
                                        className="w-full h-32 sm:h-40 object-cover rounded-md"
                                    />
                                ) : (
                                    <video
                                        src={fileWithPreview.preview}
                                        className="w-full h-32 sm:h-40 object-cover rounded-md"
                                        controls
                                        muted
                                    />
                                )}
                                <button
                                    onClick={() =>
                                        removeFile(fileWithPreview.id)
                                    }
                                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity h-6 w-6 flex justify-center items-center focus:outline-none"
                                    aria-label={`Remove ${fileWithPreview.file.name}`}
                                >
                                    <span className="text-sm font-bold">
                                        &times;
                                    </span>
                                </button>
                                {fileWithPreview.file.type.startsWith(
                                    "image/"
                                ) ? (
                                    <FaFileImage
                                        className="absolute bottom-1 left-1 text-secondary"
                                        size={isMobile ? 16 : 20} // Adjust icon size based on device
                                    />
                                ) : (
                                    <FaFileVideo
                                        className="absolute bottom-1 left-1 text-secondary"
                                        size={isMobile ? 16 : 20}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 flex flex-col items-start">
                        <Button
                            id="upload-button"
                            type="button"
                            onClick={handleUpload}
                            disabled={isUploading}
                            className={`w-full sm:w-auto ${
                                isMobile ? "text-sm px-4 py-2" : ""
                            }`}
                        >
                            {isUploading
                                ? `Uploading... ${Math.round(uploadProgress)}%`
                                : "Upload"}
                        </Button>
                        {isUploading && (
                            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                                <div
                                    className="bg-secondary h-2.5 rounded-full transition-width duration-300"
                                    style={{
                                        width: `${Math.round(uploadProgress)}%`,
                                    }}
                                ></div>
                            </div>
                        )}
                        {uploadError && (
                            <span className="text-red-500 text-sm mt-2">
                                {uploadError}
                            </span>
                        )}
                    </div>
                </div>
            )}

            <div className="bg-gray-100 p-3 rounded-md mt-4">
                <p className="text-sm text-primary font-bold">Note:</p>
                <div className="text-primary text-sm flex flex-col">
                    <FeatureItem
                        size="sm"
                        text={
                            isAdhoc
                                ? "New media will be added to Shared Media, while Campaign Media will remain unchanged."
                                : "New media will only take effect with the next campaign renewal."
                        }
                    />
                    {!isAdhoc ? (
                        <>
                            <FeatureItem
                                size="sm"
                                text="Deleting media cannot be undone"
                            />
                            <FeatureItem
                                size="sm"
                                text="Deleted media will remain available in current campaigns but cannot be used in future renewals."
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default DragAndDropMedia;
