import React, { useEffect, useState } from "react";
import { SelectedAddress } from "../../../types/selectedAddress";
import CreateUserListing from "./CreateUserListing";
import ConfirmUserListing from "./ConfirmUserListing";
import UpdateInformation from "./UpdateInformation";
import { UserListing } from "../../../types/userListing";
import MediaAdder from "./MediaAdder";
import { useIsMobile } from "../../../hooks/useIsMobile";
import DescriptionAdder from "./DescriptionAdder";

interface CreateListingsProps {
    mlsApproved: boolean;
    mlsWelcome: boolean;
}

const CreateListings: React.FC<CreateListingsProps> = ({
    mlsApproved,
    mlsWelcome,
}) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [selectedAddress, setSelectedAddress] =
        useState<SelectedAddress | null>(null);
    const [mlsId, setMlsId] = useState<string>("");

    const [listing, setListing] = useState<UserListing | null>(null);
    const [originalPropertyData, setOriginalPropertyData] =
        useState<UserListing | null>(null);

    const isMobile = useIsMobile(700);

    useEffect(() => {
        setOriginalPropertyData(null);
        setListing(null);
    }, [mlsId]);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <CreateUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                        setMlsId={setMlsId}
                        mlsWelcome={mlsWelcome}
                    />
                );
            case 2:
                return (
                    <ConfirmUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                        listing={listing}
                        setCreatedListing={setListing}
                    />
                );
            case 3:
                return (
                    <UpdateInformation
                        listing={listing}
                        setListing={setListing}
                        mlsApproved={mlsApproved}
                        setCurrentStep={setCurrentStep}
                        isMobile={isMobile}
                        originalPropertyData={originalPropertyData}
                        setOriginalPropertyData={setOriginalPropertyData}
                    />
                );
            case 4:
                return (
                    <MediaAdder
                        isMobile={isMobile}
                        mlsApproved={mlsApproved}
                        propertyData={listing}
                        setPropertyData={setListing}
                        setCurrentStep={setCurrentStep}
                    />
                );
            case 5:
                return (
                    <DescriptionAdder
                        isMobile={isMobile}
                        listing={listing}
                        setListing={setListing}
                        setCurrentStep={setCurrentStep}
                        mlsId={mlsId}
                    />
                );
            default:
                return (
                    <CreateUserListing
                        setCurrentStep={setCurrentStep}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        mlsApproved={mlsApproved}
                        mlsId={mlsId}
                        setMlsId={setMlsId}
                        mlsWelcome={mlsWelcome}
                    />
                );
        }
    };

    useEffect(() => {
        console.log("selectedAddress in UserListings:", selectedAddress);
    }, [selectedAddress]);

    return <div className="relative w-full h-full">{renderStep()}</div>;
};

export default CreateListings;
