import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Login from "../screens/LoginScreen";
import SignupScreen from "../screens/SignupScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import OnboardingScreen from "../screens/user/OnboardingScreen";
import PreLogin from "../screens/PreLogin";
import VerifyEmailScreen from "../screens/VerifyEmailScreen";
import PausedScreen from "../screens/PausedScreen";
import MaintenanceScreen from "../screens/user/MaintenanceScreen";
import ResubscribeScreen from "../screens/user/ResubscribeScreen";
import LoggedInView from "./LoggedInView";
import ConnectSocialsScreen from "../screens/ConnectSocialsScreen";
import { ConnectionsRedirect } from "../screens/ConnectionsRedirect";
import HelpScreen from "../screens/user/HelpScreen";
import TutorialDetail from "../components/help/tutorials/TutorialDetails";

const verifyEmailRoutes = (
    <>
        <Route path="verify-email" element={<VerifyEmailScreen />} />
        <Route path="*" element={<Navigate to="/verify-email" />} />
    </>
);

const loginRoutes = (
    <>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="*" element={<Navigate to="/login" />} />
    </>
);
const connectSocialsRoutes = (
    <>
        <Route path="/connections/redirect" element={<ConnectionsRedirect />} />
        <Route path="/connect-socials" element={<ConnectSocialsScreen />} />
        <Route path="/help/" element={<HelpScreen />} />
        <Route path="/help/*" element={<TutorialDetail />} />
        <Route path="*" element={<Navigate to="/connect-socials" />} />
    </>
);
const resubscribeRoutes = (role: string) => (
    <>
        <Route
            path="/resubscribe"
            element={<ResubscribeScreen role={role} />}
        />
        <Route path="*" element={<Navigate to="/resubscribe" />} />
    </>
);
const onboardingRoutes = (role: string, isAdmin: boolean) => (
    <>
        <Route
            path="/onboarding"
            element={<OnboardingScreen role={role} isAdmin={isAdmin} />}
        />
        <Route path="/help/" element={<HelpScreen />} />
        <Route path="/help/*" element={<TutorialDetail />} />
        <Route path="*" element={<Navigate to="/onboarding" />} />
    </>
);
const AppRoutes: React.FC = () => {
    const {
        user,
        role,
        isAdminView,
        isAuthenticated,
        isOnboarded,
        emailVerified,
        mlsApproved,
        isActive,
        maintenance,
        isSubscribed,
        socialsConnected,
        isAdmin,
    } = useSelector((state: RootState) => state.auth);

    console.log("maintenance: ", maintenance);
    // Check if the app is in development mode
    const isDevMode =
        process.env.REACT_APP_ENV === "development" ||
        process.env.REACT_APP_ENV === "qa";
    const preLoginPassed = localStorage.getItem("preLoginPassed") === "true";

    // Handle development mode with pre-login checks
    const getRoutes = () => {
        if (isDevMode && !preLoginPassed) {
            return <Route path="*" element={<PreLogin />} />;
        } else if (user && isAuthenticated && role) {
            if (!isOnboarded && !isAdminView) {
                return onboardingRoutes(role, isAdmin);
            } else {
                if (!emailVerified) {
                    return verifyEmailRoutes;
                }

                if (!socialsConnected && isSubscribed)
                    return connectSocialsRoutes;
                else if (isSubscribed) {
                    return LoggedInView({
                        isAdmin,
                        isAdminView,
                        user,
                        mlsApproved,
                        maintenance,
                    });
                } else {
                    return resubscribeRoutes(role);
                }
            }
        } else {
            return loginRoutes;
        }
    };

    if (user && !isActive) {
        return (
            <Routes>
                <Route path="*" element={<PausedScreen />} />
            </Routes>
        );
    }

    if (user && !isAdmin && maintenance) {
        return (
            <Routes>
                <Route
                    path="*"
                    element={<MaintenanceScreen isAdmin={isAdmin} />}
                />
            </Routes>
        );
    }

    return <Routes>{getRoutes()}</Routes>;
};

export default AppRoutes;
