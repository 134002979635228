import React, { useState, useEffect, useRef } from "react";
import { loadStripe, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../../screens/LoadingScreen";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

const appearance: Appearance = {
    theme: "stripe",
};

interface PaymentProps {
    advanceStep: () => void;
    goBackStep: () => void;
    role: string | null;
    isOnboarding: boolean;
    refresh?: boolean;
}

const Payment: React.FC<PaymentProps> = ({
    advanceStep,
    goBackStep,
    role,
    isOnboarding,
    refresh = false,
}) => {
    const [clientSecret, setClientSecret] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [purchaseDetails, setPurchaseDetails] = useState<{
        name: string;
        price: number;
        amountDue: number;
        nextDate: number;
    } | null>(null);

    const hasFetched = useRef(false);

    useEffect(() => {
        if (role === "TEAM_USER") {
            goBackStep();
            return;
        }
    }, [role, goBackStep]);

    useEffect(() => {
        if (hasFetched.current) return; // Prevent multiple calls
        hasFetched.current = true;

        const fetchPaymentIntent = async () => {
            setLoading(true);

            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const currentUser = auth.currentUser;
                if (!currentUser) throw new Error("User not authenticated");
                const token = await getIdToken(currentUser, true);

                const response = await axios.get(
                    `${backendUrl}/onboarding/create-stripe-account`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                if (response.data.status.toLowerCase() === "active") {
                    window.location.reload();
                    return;
                }

                if (!response.data.clientSecret) {
                    throw new Error("Client secret not found");
                }

                setClientSecret(response.data.clientSecret);
                setPurchaseDetails(response.data.purchaseDetails);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error creating payment intent:", error);
                toast.error(
                    <CustomToast
                        message="Error creating payment intent. Please try again."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setLoading(false);
            }
        };
        fetchPaymentIntent();
    }, []);

    if (loading) return <LoadingScreen />;

    if (!clientSecret) {
        toast.error(
            <CustomToast
                message="Error creating payment intent. Please try again."
                type="error"
            />,
            {
                autoClose: 3000,
            }
        );
        goBackStep();
        return null;
    }

    return (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            {purchaseDetails && (
                <PaymentForm
                    details={purchaseDetails}
                    isOnboarding={isOnboarding}
                    advanceStep={advanceStep}
                    goBackStep={goBackStep}
                    refresh={refresh}
                />
            )}
        </Elements>
    );
};

export default Payment;
