export interface ChoosePlatformsProps {
    setCurrentStep: (step: number) => void;
    selectedPlatforms: SocialsConnected;
    setSelectedPlatforms: (platforms: SocialsConnected) => void;
    from: string | null;
    listingId: string | null;
}

export interface LocationState {
    listingId: string;
    listingType: string;
    from: string;
}

export interface SocialsConnected {
    facebook: boolean;
    instagram: boolean;
}

export interface ConnectedSocialsData {
    displayName: string;
    id: string;
    pageName: string;
    platform: string;
    profileUrl: string;
    userImage: string;
    username?: string;
}

export interface ChooseFrequencyProps {
    setCurrentStep: (step: number) => void;
    frequency: string;
    setFrequency: (frequency: string) => void;
    selectedDays: string[];
    setSelectedDays: (days: string[]) => void;
    date: Date | undefined;
    setDate: (date: Date) => void;
    selectedPlatforms: SocialsConnected;
}

export const weekDays = [
    { id: "Monday", label: "Monday" },
    { id: "Tuesday", label: "Tuesday" },
    { id: "Wednesday", label: "Wednesday" },
    { id: "Thursday", label: "Thursday" },
    { id: "Friday", label: "Friday" },
    { id: "Saturday", label: "Saturday" },
    { id: "Sunday", label: "Sunday" },
];

export interface ChooseCampaignOptionsProps {
    setCurrentStep: (step: number) => void;
    autoApprove: boolean;
    setAutoApprove: React.Dispatch<React.SetStateAction<boolean>>;
    soldPost: boolean;
    setSoldPost: React.Dispatch<React.SetStateAction<boolean>>;
    useEmojis: boolean;
    setUseEmojis: React.Dispatch<React.SetStateAction<boolean>>;
    createCampaign: () => void;
    listingType: string | null;
    usePrice: boolean;
    setUsePrice: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PostTimeProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    campaignPlatforms: CampaignPlatform[] | undefined;
    setCampaignPlatforms: React.Dispatch<
        React.SetStateAction<CampaignPlatform[] | undefined>
    >;
}

export interface postTime {
    hour: number;
    minute: number;
}

export interface CampaignProgress {
    currentStep: string;
    totalPosts?: number;
    postsCreated?: number;
}

export const allPlatforms = ["Facebook", "Instagram"];

export enum ContentLength {
    Short = "Short",
    Medium = "Medium",
    Long = "Long",
}

export enum SocialPlatforms {
    Facebook = "Facebook",
    Instagram = "Instagram",
}

export interface CampaignPlatform {
    name: SocialPlatforms;
    postsPerDay: number;
    postTimes: { hour: number; minute: number }[];
    contentLengths: ContentLength[];
}
