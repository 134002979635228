import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../components/navigation-bars/TopBar";
import SideBar from "../components/navigation-bars/SideBar";
import { User } from "../types/user";
import { useIsMobile } from "../hooks/useIsMobile";

interface Props {
    isAdmin: boolean;
    user: User | null;
    isAdminView: boolean;
    maintenance: boolean;
}

const UserLayout: React.FC<Props> = ({
    isAdmin,
    user,
    isAdminView,
    maintenance,
}) => {
    const isMobile = useIsMobile(900);

    useEffect(() => {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };
        setVh();
        window.addEventListener("resize", setVh);
        return () => window.removeEventListener("resize", setVh);
    }, []);

    return (
        <div
            className="flex flex-col"
            style={{ height: "calc(var(--vh, 1vh) * 100)" }}
        >
            {/* TopBar with safe area inset */}
            <header
                className="h-16 flex items-center px-4 flex-shrink-0"
                style={{ paddingTop: "env(safe-area-inset-top)" }}
            >
                <TopBar
                    isAdmin={isAdmin}
                    user={user}
                    isAdminView={isAdminView}
                />
            </header>

            {maintenance && isAdmin && (
                <header>
                    <div className="flex items-center justify-center h-16 bg-red-700">
                        <h1 className="text-center text-2xl font-bold text-white">
                            Maintenance Mode
                        </h1>
                    </div>
                </header>
            )}

            {/* Main Content Area */}
            <div className="flex flex-1 overflow-hidden">
                {/* Desktop Sidebar */}
                {!isMobile && (
                    <aside className="bg-background text-white flex-shrink-0">
                        <SideBar />
                    </aside>
                )}

                {/* Page Content with overscroll settings and safe area inset */}
                <main
                    className="relative flex-1 bg-background overflow-auto overscroll-contain scrolling-touch"
                    style={
                        isMobile
                            ? {
                                  paddingBottom:
                                      "calc(env(safe-area-inset-bottom) + 80px)",
                              }
                            : {}
                    }
                >
                    <Outlet />
                </main>
            </div>

            {/* Mobile Sidebar */}
            {isMobile && <SideBar />}
        </div>
    );
};

export default UserLayout;
