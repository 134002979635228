import React, { useEffect, useState } from "react";
import StepProgressBar from "../../progress-bar";
import { SelectedAddress } from "../../../types/selectedAddress";
import { Button } from "../../misc/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../../misc/CustomToast";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import {
    Features,
    ComfirmListing,
    UserListing,
} from "../../../types/userListing";
import LoadingScreen from "../../../screens/LoadingScreen";
import LeafletMap from "../LeafletMap";
import { FaBath, FaCalendarDays, FaLocationDot, FaStar } from "react-icons/fa6";
import { BsHousesFill } from "react-icons/bs";
import { FaBed, FaRegClock, FaScroll } from "react-icons/fa";
import { MdOutlinePriceChange, MdSquareFoot } from "react-icons/md";
import { PiMapPinSimpleAreaFill } from "react-icons/pi";
import { compileAddress } from "../../../utils/addressCompiler";
import { useNavigate } from "react-router-dom";
import InputField from "../../misc/InputField";
import { MLSFeatures } from "../types";
import DataLoadingError from "../../misc/DataLoadingError";
import { useIsMobile } from "../../../hooks/useIsMobile";
import StatusOverlay from "../../misc/StatusOverlay";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/authSlice";
import { RootState } from "../../../store/store";
import { User } from "../../../types/user";

interface CreateUserListingProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    selectedAddress: SelectedAddress | null;
    mlsApproved: boolean | null;
    mlsId: string;
    listing: UserListing | null;
    setCreatedListing: React.Dispatch<React.SetStateAction<UserListing | null>>;
}

const ConfirmUserListing: React.FC<CreateUserListingProps> = ({
    setCurrentStep,
    selectedAddress,
    mlsApproved,
    mlsId,
    listing,
    setCreatedListing,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user: User | null = useSelector(
        (state: RootState) => state.auth.user
    );

    const [loading, setLoading] = useState<boolean>(listing ? false : true);
    const [error, setError] = useState<boolean>(false);
    const [statusAcknowledged, setStatusAcknowledged] =
        useState<boolean>(false);
    const [overlay, setOverlay] = useState<{
        isOpen: boolean;
        title: string;
        message: string;
        messageTwo?: string;
        allowNext: boolean;
    }>({
        isOpen: false,
        title: "",
        message: "",
        messageTwo: "",
        allowNext: false,
    });

    const isMobile = useIsMobile(900);

    useEffect(() => {
        const fetchListingData = async () => {
            if (listing) return;
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                let payload;

                if (mlsApproved) {
                    if (mlsId) {
                        payload = { mlsId };
                    } else if (selectedAddress) {
                        payload = { address: selectedAddress };
                    }
                } else {
                    payload = { address: compileAddress(selectedAddress!) };
                }

                const response = await axios.post(
                    `${backendUrl}/listing`,
                    payload,
                    {
                        headers,
                        withCredentials: true,
                    }
                );

                if (response.status === 200) {
                    setLoading(false);
                    console.log("Listing data fetched successfully:", response);
                    setCreatedListing(response.data);

                    if (
                        (!response.data.latitude || !response.data.longitude) &&
                        response.data.formattedAddress
                    ) {
                        try {
                            const apiKey =
                                process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
                            if (!apiKey) {
                                throw new Error(
                                    "Google Geocoding API key is not set."
                                );
                            }
                            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                                response.data.formattedAddress
                            )}&key=${apiKey}`;
                            const geocodingResponse = await axios.get(
                                geocodingUrl
                            );
                            if (geocodingResponse.data.status === "OK") {
                                const location =
                                    geocodingResponse.data.results[0].geometry
                                        .location;
                                // Update the listing with new coordinates
                                const updatedListing: UserListing = {
                                    ...response.data,
                                    latitude: location.lat,
                                    longitude: location.lng,
                                };
                                setCreatedListing(updatedListing);
                            }
                        } catch (error) {
                            console.error(
                                "Error fetching coordinates from Google:",
                                error
                            );
                            toast.error(
                                <CustomToast
                                    message="Failed to fetch coordinates."
                                    type="error"
                                />,
                                { autoClose: 3000 }
                            );
                        }
                    }
                    toast.success(
                        <CustomToast
                            message="Property details fetched successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                } else {
                    setLoading(false);
                    console.error("Failed to fetch listing data:", response);
                    toast.error(
                        <CustomToast
                            message="Unable to fetch property details. Please check the address and try again."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                    setTimeout(() => {
                        setCurrentStep(1);
                    }, 3000);
                }
            } catch (error: unknown) {
                setLoading(false);
                console.error("Error fetching listing data:", error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchListingData();
    }, [
        selectedAddress,
        setCurrentStep,
        mlsId,
        mlsApproved,
        setCreatedListing,
        listing,
    ]);

    const [progressSteps] = useState([
        { label: "Property Address", isActive: true, isCompleted: true },
        { label: "Confirm Property", isActive: true, isCompleted: false },
    ]);

    const handleBack = () => {
        setCurrentStep(1);
    };

    const continueSave = async () => {
        if (mlsApproved) {
            if (
                listing?.legalDescription &&
                listing?.legalDescription?.length >= 500
            ) {
                try {
                    setLoading(true);
                    const headers = await getAuthHeader();
                    const backendUrl = process.env.REACT_APP_BACKEND_URL;
                    const payload = {
                        mlsId: mlsId || listing?._id,
                        footer: listing?.footer,
                        latitude: listing?.latitude,
                        longitude: listing?.longitude,
                        formattedAddress: listing?.formattedAddress,
                    };
                    const response = await axios.post(
                        `${backendUrl}/listing/create`,
                        payload,
                        {
                            headers,
                            withCredentials: true,
                        }
                    );

                    if (response.status === 200 || response.status === 201) {
                        if (user) {
                            dispatch(setUser({ ...user, mlsWelcome: false }));
                        }

                        setLoading(false);
                        toast.success(
                            <CustomToast
                                message="Listing creation initiated."
                                type="success"
                            />,
                            { autoClose: 3000 }
                        );
                        navigate("/listings");
                    } else if (response.status === 410) {
                        setLoading(false);
                        toast.error(
                            <CustomToast
                                message="Your Listing Creation Limit has been reached. Please upgrade your plan to create more listings."
                                type="error"
                            />,
                            { autoClose: 5000 }
                        );
                    } else {
                        throw new Error(
                            "Failed to initiate listing creation. Please try again."
                        );
                    }
                } catch (error) {
                    setLoading(false);
                    console.error("Error initiating listing creation:", error);
                    toast.error(
                        <CustomToast
                            message="Failed to initiate listing creation. Please try again."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                }
            } else {
                setCreatedListing(listing);
                setCurrentStep(5);
            }
        } else {
            setCreatedListing(listing);
            setCurrentStep(3);
        }
    };

    useEffect(() => {
        if (
            !loading &&
            listing &&
            listing.mlsStatus !== "Active" &&
            !statusAcknowledged &&
            mlsApproved
        ) {
            const status = listing.mlsStatus || "";
            let title = "";
            let message = "";
            let messageTwo = "";
            let allowNext = false;
            if (status === "ComingSoon") {
                title = "Coming Soon Listing";
                message =
                    "This listing is marked as Coming Soon. Posts from this campaign will mention that this is a Coming Soon listing.";
                allowNext = true;
            } else if (status === "ActiveUnderContract") {
                title = "Listing Under Contract";
                message = "This listing is currently under contract.";
                messageTwo =
                    "Do you want to proceed with creating the listing?";
                allowNext = true;
            } else if (status === "Closed") {
                title = "Listing Closed";
                message =
                    "This listing is closed. If you want to create a congratulations/sold post, please go to the Schedule and create an Ad Hoc post.";
                allowNext = false;
            } else if (
                [
                    "Canceled",
                    "Delete",
                    "Expired",
                    "Incomplete",
                    "Withdrawn",
                    "Hold",
                    "Pending",
                ].includes(status)
            ) {
                title = "Invalid Listing Status";
                message = `This listing is marked as ${status}.`;
                messageTwo = "Please update the MLS before proceeding.";
                allowNext = false;
            } else {
                title = "Invalid Listing Status";
                message = `This listing has an invalid status: ${status}.`;
                messageTwo = "Please update the MLS before proceeding.";
                allowNext = false;
            }

            setOverlay({
                isOpen: true,
                title,
                message,
                messageTwo,
                allowNext,
            });
        }
    }, [loading, listing, statusAcknowledged, mlsApproved]);

    const handleSaveAndNext = async () => {
        if (
            listing?.mlsStatus === "Active" ||
            statusAcknowledged ||
            !mlsApproved
        ) {
            continueSave();
        }
    };

    const booleanFields: Set<string> = new Set([
        "cooling",
        "fireplace",
        "garage",
        "heating",
        "pool",
        "accessibility",
        "basement",
        "spa",
        "view",
        "waterfront",
    ]);

    const typeOrAssociatedFields: Set<string> = new Set([
        "coolingType",
        "fireplaceType",
        "garageType",
        "heatingType",
        "poolType",
        "viewType",
        "accessibilityType",
        "basementType",
        "spaFeatures",
        "waterfrontFeatures",
    ]);

    const booleanToFieldsMap: Record<string, string[]> = {
        cooling: ["coolingType"],
        fireplace: ["fireplaceType", "fireplaceFeatures"],
        garage: ["garageType", "garageSpaces"],
        heating: ["heatingType"],
        pool: ["poolType"],
        accessibility: ["accessibilityType"],
        basement: ["basementType"],
        spa: ["spaFeatures"],
        view: ["viewType"],
        waterfront: ["waterfrontFeatures"],
    };

    const fieldToBooleanMap: Record<string, string> = {
        coolingType: "cooling",
        fireplaceType: "fireplace",
        fireplaceFeatures: "fireplace",
        garageType: "garage",
        garageSpaces: "garage",
        heatingType: "heating",
        poolType: "pool",
        accessibilityType: "accessibility",
        basementType: "basement",
        spaFeatures: "spa",
        viewType: "view",
        waterfrontFeatures: "waterfront",
    };

    const formatKey = (key: string): string => {
        return key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())
            .trim();
    };

    const isBooleanField = (key: string): boolean => booleanFields.has(key);
    const isTypeOrAssociatedField = (key: string): boolean =>
        typeOrAssociatedFields.has(key);

    if (error) {
        return (
            <DataLoadingError
                heading="Failed to fetch property details."
                text="No MLS data found for the provided MLS ID or address."
                textTwo="Please verify the MLS number or address and try again."
                setCurrentStep={setCurrentStep}
                step={1}
            />
        );
    }

    return (
        <main
            className={`flex overflow-auto flex-col justify-center items-center min-h-full shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${
                isMobile ? "px-0 bg-white" : "bg-background"
            }`}
        >
            <StatusOverlay
                isOpen={overlay.isOpen}
                title={overlay.title}
                message={overlay.message}
                messageTwo={overlay.messageTwo}
                allowNext={overlay.allowNext}
                onNext={() => {
                    setStatusAcknowledged(true);
                    setOverlay((prev) => ({ ...prev, isOpen: false }));
                }}
                onBack={handleBack}
            />
            <section
                className={`flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl h-full ${
                    isMobile ? "w-full px-5 pb-24" : "w-[905px]"
                } relative`}
            >
                {loading || !listing ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <header className="flex flex-col mx-auto w-full">
                            <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                                Confirm Property
                            </h1>
                            <StepProgressBar
                                steps={progressSteps.filter((step) => step)}
                            />
                        </header>
                        <div
                            className={`w-full ${
                                isMobile ? "max-w-full" : "max-w-3xl"
                            } mt-4 mx-auto rounded-lg border bg-card text-card-foreground shadow-sm text-primary`}
                        >
                            <div className="flex flex-col space-y-1.5 p-6">
                                {listing.latitude !== 0 &&
                                    listing.longitude !== 0 && (
                                        <div className="w-full h-80 z-0">
                                            <LeafletMap
                                                latitude={listing.latitude}
                                                longitude={listing.longitude}
                                                address={
                                                    listing.formattedAddress
                                                }
                                            />
                                        </div>
                                    )}
                                <div
                                    className={`${
                                        isMobile ? "text-xl" : "text-2xl"
                                    } font-bold flex items-center gap-2 leading-none tracking-tight`}
                                >
                                    <FaLocationDot className="h-6 w-6 text-secondary" />
                                    {listing.formattedAddress}
                                </div>
                            </div>
                            <div className="space-y-6 p-6 pt-0">
                                {listing.propertyType ? (
                                    <div className="flex items-center gap-2">
                                        <BsHousesFill className="h-6 w-6 text-secondary" />
                                        <span>{listing.propertyType}</span>
                                    </div>
                                ) : null}
                                <div className="grid grid-cols-2 gap-4">
                                    {listing.bedrooms ? (
                                        <div className="flex items-center gap-2">
                                            <FaBed className="h-6 w-6 text-secondary" />
                                            <span>{listing.bedrooms} Beds</span>
                                        </div>
                                    ) : null}
                                    {listing.bathrooms ? (
                                        <div className="flex items-center gap-2">
                                            <FaBath className="h-6 w-6 text-secondary" />
                                            <span>
                                                {listing.bathrooms} Baths
                                            </span>
                                        </div>
                                    ) : null}
                                    {listing.squareFootage ? (
                                        <div className="flex items-center gap-2">
                                            <MdSquareFoot className="h-6 w-6 text-secondary" />
                                            <span>
                                                {listing.squareFootage} sq ft
                                            </span>
                                        </div>
                                    ) : null}
                                    {listing.lotSize ? (
                                        <div className="flex items-center gap-2">
                                            <PiMapPinSimpleAreaFill className="h-6 w-6 text-secondary" />
                                            <span>
                                                {mlsApproved
                                                    ? listing.lotSize
                                                    : (
                                                          parseInt(
                                                              listing.lotSize
                                                          ) / 43560
                                                      ).toFixed(2)}{" "}
                                                acres
                                            </span>
                                        </div>
                                    ) : null}
                                    {listing.yearBuilt ? (
                                        <div className="flex items-center gap-2">
                                            <FaCalendarDays className="h-6 w-6 text-secondary" />
                                            <span>
                                                Built {listing.yearBuilt}
                                            </span>
                                        </div>
                                    ) : null}
                                    {!mlsApproved && listing.lastSaleDate ? (
                                        <div className="flex items-center gap-2">
                                            <FaRegClock className="h-6 w-6 text-secondary" />
                                            <span>
                                                Last Sale Date:{" "}
                                                {new Date(
                                                    listing.lastSaleDate
                                                ).toLocaleDateString("en-US", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                })}
                                            </span>
                                        </div>
                                    ) : null}
                                    {listing.currentPrice &&
                                    listing.currentPrice > 0 ? (
                                        <div className="flex items-center gap-2">
                                            <MdOutlinePriceChange className="h-6 w-6 text-secondary" />
                                            <span>
                                                {mlsApproved
                                                    ? `Price: `
                                                    : `Last Sale Price: `}{" "}
                                                ${" "}
                                                {listing?.currentPrice.toLocaleString()}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                {listing.legalDescription ? (
                                    <div>
                                        <div className="flex">
                                            <FaScroll className="h-6 w-6 text-secondary" />
                                            <span className="ml-2">
                                                Description
                                            </span>
                                        </div>
                                        <p className="text-sm">
                                            {listing.legalDescription}
                                        </p>
                                    </div>
                                ) : null}
                                {listing.features &&
                                    typeof listing.features === "object" &&
                                    Object.keys(listing.features).length >
                                        0 && (
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2 flex items-center gap-2 text-primary">
                                                <FaStar className="h-6 w-6 text-secondary" />
                                                Features
                                            </h3>
                                            <div className="flex flex-wrap gap-2">
                                                {Object.entries(
                                                    listing.features
                                                ).map(([key, value]) => {
                                                    if (isBooleanField(key)) {
                                                        if (value === true) {
                                                            const relatedFields =
                                                                booleanToFieldsMap[
                                                                    key
                                                                ] || [];
                                                            return relatedFields.map(
                                                                (
                                                                    relatedKey
                                                                ) => {
                                                                    const relatedValue =
                                                                        listing
                                                                            .features[
                                                                            relatedKey as keyof (
                                                                                | Features
                                                                                | MLSFeatures
                                                                            )
                                                                        ];
                                                                    if (
                                                                        relatedValue !==
                                                                            undefined &&
                                                                        relatedValue !==
                                                                            null &&
                                                                        relatedValue !==
                                                                            ""
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                                                                key={
                                                                                    relatedKey
                                                                                }
                                                                            >
                                                                                {`${formatKey(
                                                                                    relatedKey
                                                                                )}: ${relatedValue}`}
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                }
                                                            );
                                                        }
                                                        return null;
                                                    }

                                                    if (
                                                        isTypeOrAssociatedField(
                                                            key
                                                        )
                                                    ) {
                                                        const correspondingBoolean =
                                                            fieldToBooleanMap[
                                                                key
                                                            ];
                                                        if (
                                                            correspondingBoolean &&
                                                            listing.features[
                                                                correspondingBoolean as keyof (
                                                                    | Features
                                                                    | MLSFeatures
                                                                )
                                                            ] === true
                                                        ) {
                                                            return null;
                                                        }
                                                        if (
                                                            value !==
                                                                undefined &&
                                                            value !== null &&
                                                            value !== ""
                                                        ) {
                                                            if (
                                                                key ===
                                                                "garageSpaces"
                                                            ) {
                                                                return (
                                                                    <div
                                                                        className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        {`Garage Spaces: ${value}`}
                                                                    </div>
                                                                );
                                                            }
                                                            return (
                                                                <div
                                                                    className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                                                    key={key}
                                                                >
                                                                    {`${formatKey(
                                                                        key
                                                                    )}: ${value}`}
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    }

                                                    if (
                                                        value !== undefined &&
                                                        value !== null &&
                                                        value !== ""
                                                    ) {
                                                        return (
                                                            <div
                                                                className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                                                key={key}
                                                            >
                                                                {`${formatKey(
                                                                    key
                                                                )}: ${String(
                                                                    value
                                                                ).replace(
                                                                    /,(\S)/g,
                                                                    ", $1"
                                                                )}`}
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    )}
                                {listing.footer && (
                                    <div className="mt-6 p-6 border-t">
                                        <h3 className="text-lg font-semibold mb-4">
                                            Agent Information
                                        </h3>
                                        <div className="space-y-4">
                                            <InputField
                                                label="Agent Name"
                                                placeholder="Ex: John Doe"
                                                type="text"
                                                value={listing.footer.agentName}
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "agentName",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <InputField
                                                label="Agent Phone"
                                                placeholder="Ex: XXX-XXX-XXXX"
                                                type="text"
                                                value={
                                                    listing.footer.agentPhone
                                                }
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "agentPhone",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <InputField
                                                label="Agency"
                                                placeholder="Ex: Best Realty"
                                                type="text"
                                                value={
                                                    listing.footer.agentAgency
                                                }
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "agentAgency",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <InputField
                                                label="Agency Phone"
                                                placeholder="Ex: 555-123-4567"
                                                type="text"
                                                value={
                                                    listing.footer.agencyPhone
                                                }
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "agencyPhone",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <InputField
                                                label="Source MLS"
                                                placeholder="Ex: WestPenn"
                                                type="text"
                                                disabled={mlsApproved === true}
                                                value={
                                                    listing.footer.sourceMLS ||
                                                    ""
                                                }
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "sourceMLS",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <InputField
                                                label="Source MLS ID"
                                                placeholder="Ex: 1234567"
                                                type="text"
                                                value={
                                                    listing.footer
                                                        .sourceMLSId || ""
                                                }
                                                disabled={mlsApproved === true}
                                                onChange={(e) =>
                                                    handleAgentDetailChange(
                                                        "sourceMLSId",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <div
                    className={`w-full flex justify-between mt-8 ${
                        isMobile ? "gap-4" : "gap-20"
                    }`}
                >
                    <Button
                        id="confirm-listing-back-btn"
                        className={`${isMobile ? "w-full" : "w-1/3"}`}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    {listing?.mlsStatus === "Active" ||
                    listing?.mlsStatus === "ActiveUnderContract" ||
                    listing?.mlsStatus === "ComingSoon" ||
                    !mlsApproved ? (
                        <Button
                            id="confirm-listing-save-btn"
                            className={`${isMobile ? "w-full" : "w-1/3"}`}
                            onClick={handleSaveAndNext}
                            disabled={
                                listing?.mlsStatus !== "Active" &&
                                listing?.mlsStatus !== "ActiveUnderContract" &&
                                listing?.mlsStatus !== "ComingSoon" &&
                                !!mlsApproved
                            }
                        >
                            Next
                        </Button>
                    ) : null}
                </div>
                {mlsApproved &&
                listing?.mlsStatus !== "Active" &&
                listing?.mlsStatus !== "ActiveUnderContract" &&
                listing?.mlsStatus !== "ComingSoon" ? (
                    <>
                        {listing?.mlsStatus === "Closed" ? (
                            <div className="mt-4 text-center">
                                <p className="text-sm text-gray-500">
                                    This listing is closed. If you want to
                                    create a congratulations/sold post, please
                                    go to the Schedule and create an Ad Hoc
                                    post.
                                </p>
                            </div>
                        ) : (
                            <div className="mt-4 text-center">
                                <p className="text-sm text-gray-500">
                                    This listing is marked as{" "}
                                    {listing?.mlsStatus}. Please update the MLS
                                    before proceeding.
                                </p>
                            </div>
                        )}
                    </>
                ) : null}
            </section>
        </main>
    );

    // Handler for updating agent details
    function handleAgentDetailChange(
        field: keyof ComfirmListing["footer"],
        value: string
    ) {
        setCreatedListing((prev) => {
            if (!prev) return prev;
            return {
                ...prev,
                footer: {
                    ...prev.footer,
                    [field]: value,
                },
            };
        });
    }
};

export default ConfirmUserListing;
