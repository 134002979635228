import React from "react";
import { ContentLength, PostTimeProps } from "./types";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../misc/Select";
import { Button } from "../misc/Button";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { timeStringToPostTime, postTimeToTimeString } from "./utils/timeUtils";
import { useIsMobile } from "../../hooks/useIsMobile";
import facebookLogo from "../../assets/images/facebook_logo.png";
import instagramLogo from "../../assets/images/instagram_logo.png";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { FaClock } from "react-icons/fa6";
import TimePicker from "../misc/TimePicker";

const MAX_POSTS_PER_DAY = {
    Facebook: 3,
    Instagram: 1,
};

const CONTENT_LENGTH_OPTIONS = {
    Facebook: [ContentLength.Short, ContentLength.Medium, ContentLength.Long],
    Instagram: [ContentLength.Short],
};

const ChoosePostTime: React.FC<PostTimeProps> = ({
    setCurrentStep,
    campaignPlatforms,
    setCampaignPlatforms,
}) => {
    // Ensure that for each platform we have an array of content lengths matching postsPerDay.
    React.useEffect(() => {
        setCampaignPlatforms((prevPlatforms) => {
            if (!prevPlatforms) return prevPlatforms;
            return prevPlatforms.map((platformObj) => {
                const postsCount = platformObj.postsPerDay || 1;
                let newContentLengths: ContentLength[] =
                    platformObj.contentLengths;
                if (
                    !newContentLengths ||
                    newContentLengths.length !== postsCount
                ) {
                    newContentLengths = Array.from(
                        { length: postsCount },
                        (_, index) => {
                            const options: ContentLength[] =
                                CONTENT_LENGTH_OPTIONS[platformObj.name] || [
                                    ContentLength.Short,
                                ];
                            if (
                                platformObj.contentLengths &&
                                platformObj.contentLengths[index]
                            ) {
                                return platformObj.contentLengths[index];
                            }
                            return options[0];
                        }
                    );
                }
                return { ...platformObj, contentLengths: newContentLengths };
            });
        });
    }, [setCampaignPlatforms]);

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        const newIsDisabled = !campaignPlatforms?.every(
            (platform) =>
                platform.postTimes?.length === platform.postsPerDay &&
                platform.postTimes.every(
                    (time) =>
                        typeof time.hour === "number" &&
                        !isNaN(time.hour) &&
                        typeof time.minute === "number" &&
                        !isNaN(time.minute)
                ) &&
                platform.contentLengths?.length === platform.postsPerDay &&
                platform.contentLengths.every((length: string) => length !== "")
        );
        setIsDisabled(newIsDisabled);
    }, [campaignPlatforms]);

    // Navigation handlers
    const handleBack = () => {
        setCurrentStep(2);
    };

    const nextHandler = () => {
        if (!isDisabled) {
            setCurrentStep(4);
        } else {
            toast.error(
                <CustomToast
                    message="Please configure all posts before proceeding."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    // Update postsPerDay, and update both postTimes and contentLengths arrays
    const handlePostsPerDayChange = (platformName: string, value: string) => {
        const postsPerDay = Number.parseInt(value);
        setCampaignPlatforms((prevPlatforms) => {
            if (!prevPlatforms) return prevPlatforms;
            return prevPlatforms.map((platformObj) => {
                if (platformObj.name === platformName) {
                    const newPostTimes = Array.from(
                        { length: postsPerDay },
                        (_, index) => {
                            if (
                                platformObj.postTimes &&
                                index < platformObj.postTimes.length
                            ) {
                                return platformObj.postTimes[index];
                            }
                            const hour = 9 + index * 3;
                            return { hour, minute: 0 };
                        }
                    );
                    const options = CONTENT_LENGTH_OPTIONS[platformName] || [
                        "Short",
                    ];
                    const newContentLengths = Array.from(
                        { length: postsPerDay },
                        (_, index) => {
                            if (
                                platformObj.contentLengths &&
                                index < platformObj.contentLengths.length
                            ) {
                                return platformObj.contentLengths[index];
                            }
                            return options[0];
                        }
                    );
                    return {
                        ...platformObj,
                        postsPerDay,
                        postTimes: newPostTimes,
                        contentLengths: newContentLengths,
                    };
                }
                return platformObj;
            });
        });
    };

    const handlePostTimeChange = (
        platformName: string,
        postIndex: number,
        value: string
    ) => {
        const newTime = timeStringToPostTime(value);
        setCampaignPlatforms((prevPlatforms) => {
            if (!prevPlatforms) return prevPlatforms;
            return prevPlatforms.map((platformObj) => {
                if (platformObj.name === platformName) {
                    const updatedPostTimes = [...platformObj.postTimes];
                    updatedPostTimes[postIndex] = newTime;
                    return { ...platformObj, postTimes: updatedPostTimes };
                }
                return platformObj;
            });
        });
    };

    const handleContentLengthChange = (
        platformName: string,
        postIndex: number,
        value: ContentLength
    ) => {
        setCampaignPlatforms((prevPlatforms) => {
            if (!prevPlatforms) return prevPlatforms;
            return prevPlatforms.map((platformObj) => {
                if (platformObj.name === platformName) {
                    const updatedContentLengths = [
                        ...platformObj.contentLengths,
                    ];
                    updatedContentLengths[postIndex] = value;
                    return {
                        ...platformObj,
                        contentLengths: updatedContentLengths,
                    };
                }
                return platformObj;
            });
        });
    };

    // Inject your custom time input styles
    React.useEffect(() => {
        const style = document.createElement("style");
        style.textContent = `
      .custom-time-input::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
      }
      .custom-time-input::-webkit-datetime-edit-fields-wrapper {
        padding: 0;
      }
      .custom-time-input::-webkit-datetime-edit-hour-field,
      .custom-time-input::-webkit-datetime-edit-minute-field,
      .custom-time-input::-webkit-datetime-edit-ampm-field {
        padding: 0 4px;
      }
      .custom-time-input::-webkit-datetime-edit-hour-field:focus,
      .custom-time-input::-webkit-datetime-edit-minute-field:focus,
      .custom-time-input::-webkit-datetime-edit-ampm-field:focus {
        background-color: #E9C468;
        color: #193456;
      }
    `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const getPlatformIcon = (platform: string) => {
        switch (platform.toLowerCase()) {
            case "facebook":
                return (
                    <img
                        src={facebookLogo}
                        alt="Facebook Logo"
                        className="w-6 h-6"
                    />
                );
            case "instagram":
                return (
                    <img
                        src={instagramLogo}
                        alt="Instagram Logo"
                        className="w-6 h-6"
                    />
                );
            default:
                return null;
        }
    };

    const getPlatformName = (platform: string) => {
        switch (platform.toLowerCase()) {
            case "facebook":
                return "Facebook";
            case "instagram":
                return "Instagram";
            default:
                return platform;
        }
    };

    const isMobile = useIsMobile(900);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Post Frequency and Time</CardTitle>
                <CardDescription className="text-center">
                    Set your posts per day, posting times and content lengths
                    for each platform.
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="grid gap-6">
                    {campaignPlatforms?.map((platform) => (
                        <Card key={platform.name} className="shadow-md">
                            <CardHeader className="flex justify-start flex-row items-center gap-3">
                                {getPlatformIcon(platform.name)}
                                <div className="flex-1">
                                    <CardTitle className="text-left">
                                        {getPlatformName(platform.name)}
                                    </CardTitle>
                                    <CardDescription>
                                        Configure your posting preferences
                                    </CardDescription>
                                </div>
                                <div className="w-[180px]">
                                    <Select
                                        value={platform.postsPerDay.toString()}
                                        onValueChange={(value) =>
                                            handlePostsPerDayChange(
                                                platform.name,
                                                value
                                            )
                                        }
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Posts per day" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Array.from(
                                                {
                                                    length: MAX_POSTS_PER_DAY[
                                                        platform.name
                                                    ],
                                                },
                                                (_, index) => (
                                                    <SelectItem
                                                        key={index + 1}
                                                        value={(
                                                            index + 1
                                                        ).toString()}
                                                    >
                                                        {index + 1} post
                                                        {index > 0
                                                            ? "s"
                                                            : ""}{" "}
                                                        per day
                                                    </SelectItem>
                                                )
                                            )}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </CardHeader>
                            <CardContent>
                                {isMobile ? (
                                    <div className="space-y-4">
                                        {Array.from(
                                            { length: platform.postsPerDay },
                                            (_, index) => (
                                                <div
                                                    key={index}
                                                    className="p-4 border rounded-md"
                                                >
                                                    <div className="font-medium mb-2 text-center">
                                                        Post {index + 1}
                                                    </div>
                                                    <div className="flex justify-center">
                                                        <div className="relative flex justify-center items-center">
                                                            <input
                                                                type="time"
                                                                className="custom-time-input w-[150px] pl-10 pr-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#E9C468] bg-white"
                                                                value={postTimeToTimeString(
                                                                    platform
                                                                        .postTimes[
                                                                        index
                                                                    ]
                                                                )}
                                                                onChange={(e) =>
                                                                    handlePostTimeChange(
                                                                        platform.name,
                                                                        index,
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <Popover>
                                                                <PopoverTrigger
                                                                    asChild
                                                                >
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="icon"
                                                                        className="absolute left-1.5 top-1/2 transform -translate-y-1/2 text-gray-400 bg-transparent hover:bg-transparent hover:text-secondary"
                                                                    >
                                                                        <FaClock className="h-4 w-4" />
                                                                        <span className="sr-only">
                                                                            Open
                                                                            time
                                                                            picker
                                                                        </span>
                                                                    </Button>
                                                                </PopoverTrigger>
                                                                <PopoverContent
                                                                    className="w-auto p-0"
                                                                    align="start"
                                                                >
                                                                    <TimePicker
                                                                        time={postTimeToTimeString(
                                                                            platform
                                                                                .postTimes[
                                                                                index
                                                                            ]
                                                                        )}
                                                                        onChange={(
                                                                            newTimeStr
                                                                        ) =>
                                                                            handlePostTimeChange(
                                                                                platform.name,
                                                                                index,
                                                                                newTimeStr
                                                                            )
                                                                        }
                                                                    />
                                                                </PopoverContent>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="flex items-center justify-center space-x-2">
                                                            {CONTENT_LENGTH_OPTIONS[
                                                                platform.name
                                                            ].map((option) => (
                                                                <label
                                                                    key={option}
                                                                    className={`flex items-center justify-center px-3 py-1 rounded-full text-sm cursor-pointer transition-colors ${
                                                                        platform
                                                                            .contentLengths[
                                                                            index
                                                                        ] ===
                                                                        option
                                                                            ? "bg-secondary text-white"
                                                                            : "bg-muted hover:bg-lightSecondary"
                                                                    }`}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        name={`${platform.name}-post-${index}-length`}
                                                                        value={
                                                                            option
                                                                        }
                                                                        checked={
                                                                            platform
                                                                                .contentLengths[
                                                                                index
                                                                            ] ===
                                                                            option
                                                                        }
                                                                        onChange={() =>
                                                                            handleContentLengthChange(
                                                                                platform.name,
                                                                                index,
                                                                                option as ContentLength
                                                                            )
                                                                        }
                                                                        className="sr-only"
                                                                    />
                                                                    {option}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-[100px_250px_1fr] gap-x-4">
                                        {/* Header */}
                                        <div className="w-[100px] font-bold pb-2 text-primary text-center">
                                            Post
                                        </div>
                                        <div className="w-[250px] font-bold pb-2 text-primary text-center">
                                            Time
                                        </div>
                                        <div className="font-bold pb-2 text-primary text-center">
                                            Content Length
                                        </div>
                                        {Array.from(
                                            { length: platform.postsPerDay },
                                            (_, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="w-[100px] font-medium pt-2 text-center">
                                                        Post {index + 1}
                                                    </div>
                                                    <div className="pt-2 flex justify-center">
                                                        <div className="relative flex justify-center items-center">
                                                            <input
                                                                type="time"
                                                                className="custom-time-input w-[150px] pl-10 pr-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#E9C468] bg-white"
                                                                value={postTimeToTimeString(
                                                                    platform
                                                                        .postTimes[
                                                                        index
                                                                    ]
                                                                )}
                                                                onChange={(e) =>
                                                                    handlePostTimeChange(
                                                                        platform.name,
                                                                        index,
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <Popover>
                                                                <PopoverTrigger
                                                                    asChild
                                                                >
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="icon"
                                                                        className="absolute left-1.5 top-1/2 transform -translate-y-1/2 text-gray-400 bg-transparent hover:bg-transparent hover:text-secondary"
                                                                    >
                                                                        <FaClock className="h-4 w-4" />
                                                                        <span className="sr-only">
                                                                            Open
                                                                            time
                                                                            picker
                                                                        </span>
                                                                    </Button>
                                                                </PopoverTrigger>
                                                                <PopoverContent
                                                                    className="w-auto p-0"
                                                                    align="start"
                                                                >
                                                                    <TimePicker
                                                                        time={postTimeToTimeString(
                                                                            platform
                                                                                .postTimes[
                                                                                index
                                                                            ]
                                                                        )}
                                                                        onChange={(
                                                                            newTimeStr
                                                                        ) =>
                                                                            handlePostTimeChange(
                                                                                platform.name,
                                                                                index,
                                                                                newTimeStr
                                                                            )
                                                                        }
                                                                    />
                                                                </PopoverContent>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <div className="flex items-center justify-center space-x-4">
                                                            {CONTENT_LENGTH_OPTIONS[
                                                                platform.name
                                                            ].map((option) => (
                                                                <label
                                                                    key={option}
                                                                    className={`flex items-center justify-center px-3 py-1 rounded-full text-sm cursor-pointer transition-colors ${
                                                                        platform
                                                                            .contentLengths[
                                                                            index
                                                                        ] ===
                                                                        option
                                                                            ? "bg-secondary text-white"
                                                                            : "bg-muted hover:bg-lightSecondary"
                                                                    }`}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        name={`${platform.name}-post-${index}-length`}
                                                                        value={
                                                                            option
                                                                        }
                                                                        checked={
                                                                            platform
                                                                                .contentLengths[
                                                                                index
                                                                            ] ===
                                                                            option
                                                                        }
                                                                        onChange={() =>
                                                                            handleContentLengthChange(
                                                                                platform.name,
                                                                                index,
                                                                                option as ContentLength
                                                                            )
                                                                        }
                                                                        className="sr-only"
                                                                    />
                                                                    {option}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        )}
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </div>

                <div className="mt-8 flex justify-between">
                    <Button variant="secondary" onClick={handleBack}>
                        Back
                    </Button>
                    <Button onClick={nextHandler}>Next</Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default ChoosePostTime;
