import React, { useEffect, useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "../../misc/Tabs";
import { CombinedMedia, TabsProps } from "./types";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import ImagesTab from "./tabs/ImagesTab";
import VideosTab from "./tabs/VideosTab";
import DetailsTab from "./tabs/DetailsTab";
import TabButtons from "./tabs/TabButtons";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { getChanges } from "./getChanges";

const TabsComponent: React.FC<TabsProps> = ({
    images,
    videos,
    setIsLoading,
    carouselApi,
    propertyData,
    fetchListing,
    fetchAssets,
    setPropertyData,
    originalPropertyData,
    setOriginalPropertyData,
    combinedMedia,
    mlsApproved,
    setShowSaveWarning,
    activeTab,
    setActiveTab,
    combinedImages,
    combinedVideos,
    carouselIndices,
    setCarouselIndices,
    isUploading,
    setIsUploading,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<CombinedMedia[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        if (!isEditing) {
            setSelectedMedia([]);
        }
    }, [isEditing]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    const toggleSelectMedia = (media: CombinedMedia) => {
        setSelectedMedia(
            selectedMedia.some((m) => m.id === media.id)
                ? selectedMedia.filter((m) => m.id !== media.id)
                : [...selectedMedia, media]
        );
    };

    const handleUploadComplete = () => {
        fetchListing();
        fetchAssets();
    };

    const handleSave = async () => {
        if (!propertyData || !originalPropertyData) return;

        const changes = getChanges(originalPropertyData, propertyData);

        if (Object.keys(changes).length === 0) {
            setIsEditing(false);
            toast.success(<CustomToast message="Saved." type="success" />, {
                autoClose: 3000,
            });
            return;
        }

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.patch(
                `${backendUrl}/listing`,
                {
                    ...changes,
                    listingId: propertyData._id,
                    auto:
                        (propertyData.listingType === "MLSListing" &&
                            mlsApproved) ||
                        (propertyData.listingType === "RentcastListing" &&
                            !mlsApproved),
                },
                { headers }
            );

            if (response.status === 200) {
                setOriginalPropertyData(propertyData);
                setIsEditing(false);

                if (
                    propertyData.campaignStatus?.toString().toLowerCase() ===
                    "active"
                ) {
                    setShowSaveWarning(true);
                } else {
                    toast.success(
                        <CustomToast
                            message="Listing updated successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );
                }
            } else {
                throw new Error("Failed to update listing.");
            }
        } catch (error) {
            console.error("Error saving listing:", error);
            toast.error(
                <CustomToast message="Failed to save changes." type="error" />,
                { autoClose: 3000 }
            );
        }
    };

    const isMobile = useIsMobile(1200);

    const handleCarouselScroll = (newIndex: number) => {
        setCarouselIndices((prev) => ({
            ...prev,
            [activeTab]: newIndex,
        }));
    };

    return (
        <div className={`${isMobile ? "my-2" : "my-0 px-0"}`}>
            <Tabs
                value={activeTab}
                onValueChange={(value: string) =>
                    setActiveTab(value as "images" | "videos" | "details")
                }
            >
                <div
                    className={`flex ${
                        isMobile
                            ? "flex-col"
                            : "flex-row justify-between items-center"
                    }`}
                >
                    <TabsList
                        className={`${
                            isMobile
                                ? "flex overflow-x-auto space-x-2 pb-2"
                                : "space-x-4 w-full"
                        }`}
                    >
                        <TabsTrigger value="details">Details</TabsTrigger>
                        <TabsTrigger value="images">
                            Images {isMobile ? "" : `(${images.length})`}
                        </TabsTrigger>
                        <TabsTrigger value="videos">
                            Videos {isMobile ? "" : `(${videos.length})`}
                        </TabsTrigger>
                    </TabsList>

                    <TabButtons
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        selectedMedia={selectedMedia}
                        handleSave={handleSave}
                        setIsLoading={setIsLoading}
                        propertyData={propertyData}
                        fetchListing={fetchListing}
                        fetchAssets={fetchAssets}
                        setSelectedMedia={setSelectedMedia}
                        mlsApproved={mlsApproved}
                        isMobile={isMobile}
                        originalPropertyData={originalPropertyData}
                        setPropertyData={setPropertyData}
                        mediaLength={selectedMedia.length}
                    />
                </div>

                {/* Details Tab */}
                <DetailsTab
                    propertyData={propertyData}
                    setPropertyData={setPropertyData}
                    isEditing={isEditing}
                    handleSave={handleSave}
                    mlsApproved={mlsApproved}
                />

                {/* Images Tab */}
                <ImagesTab
                    images={images}
                    listingId={propertyData._id}
                    handleUploadComplete={handleUploadComplete}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    combinedMedia={combinedImages}
                    mlsApproved={mlsApproved}
                    setSelectedMedia={setSelectedMedia}
                    setIsLoading={setIsLoading}
                    itemsPerPage={10}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onCarouselScroll={handleCarouselScroll}
                    propertyStatus={propertyData.status || ""}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    listingType={propertyData.listingType}
                />

                {/* Videos Tab */}
                <VideosTab
                    videos={videos}
                    listingId={propertyData._id}
                    address1={propertyData.formattedAddress.split(",")[0]}
                    handleUploadComplete={handleUploadComplete}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    combinedMedia={combinedVideos}
                    mlsApproved={mlsApproved}
                    setSelectedMedia={setSelectedMedia}
                    setIsLoading={setIsLoading}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={10}
                    onCarouselScroll={handleCarouselScroll}
                    propertyStatus={propertyData.status || ""}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    listingType={propertyData.listingType}
                />
            </Tabs>
        </div>
    );
};

export default TabsComponent;
