import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OptionType {
    label: string;
    value: string;
}

interface CalendarState {
    selectedView: OptionType;
    selectedListingAddress: OptionType[];
    selectedPlatform: OptionType[];
    selectedApprovalStatus: OptionType[];
    currentDate: Date;
    dayView: Date | null;
}

const initialState: CalendarState = {
    selectedView: { label: "Weekly View", value: "weekly" },
    selectedListingAddress: [],
    selectedPlatform: [],
    selectedApprovalStatus: [],
    currentDate: new Date(),
    dayView: null,
};

const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<OptionType>) {
            state.selectedView = action.payload;
        },
        setSelectedListingAddress(state, action: PayloadAction<OptionType[]>) {
            state.selectedListingAddress = action.payload;
        },
        setSelectedPlatform(state, action: PayloadAction<OptionType[]>) {
            state.selectedPlatform = action.payload;
        },
        setSelectedApprovalStatus(state, action: PayloadAction<OptionType[]>) {
            state.selectedApprovalStatus = action.payload;
        },
        setCurrentDate(state, action: PayloadAction<Date>) {
            state.currentDate = action.payload;
        },
        setDayView(state, action: PayloadAction<Date | null>) {
            state.dayView = action.payload;
        },
        resetFilters(state) {
            state.selectedListingAddress = [];
            state.selectedPlatform = [];
            state.selectedApprovalStatus = [];
        },
        resetCalendarState() {
            return initialState;
        },
    },
});

export const {
    setSelectedView,
    setSelectedListingAddress,
    setSelectedPlatform,
    setSelectedApprovalStatus,
    setCurrentDate,
    setDayView,
    resetFilters,
    resetCalendarState,
} = calendarSlice.actions;

export default calendarSlice.reducer;
