import React, { useState } from "react";
import { Dialog, DialogContent } from "../misc/Dialog";
import { Button } from "../misc/Button";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../misc/Card";
import { Textarea } from "../misc/Textarea";
import { RefreshCcw } from "lucide-react";
import { Separator } from "../misc/Seperator";

interface RegeneratePostDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onRegenerate: (instructions: string) => void;
}

const RegeneratePostDialog: React.FC<RegeneratePostDialogProps> = ({
    isOpen,
    onClose,
    onRegenerate,
}) => {
    const [instructions, setInstructions] = useState<string>("");
    const charLimit = 400;

    const handleRegenerate = () => {
        if (instructions.trim().length === 0) {
            toast.error(
                <CustomToast
                    message="Instructions cannot be empty."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }

        setInstructions("");
        onRegenerate(instructions);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= charLimit) {
            setInstructions(value);
        } else {
            // Optionally, you can provide feedback when the limit is reached
            toast.info(
                <CustomToast
                    message={`You have reached the ${charLimit} character limit.`}
                    type="error"
                />,
                { autoClose: 3000 }
            );
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="relative p-4 w-full h-full m-0 flex flex-col items-center justify-center bg-white">
                <button
                    className="absolute top-4 right-4 text-secondary hover:text-primary"
                    onClick={onClose}
                    aria-label="Close Dialog"
                >
                    <FaTimes className="h-6 w-6" />
                </button>
                {/* <div className="bg-gray-100 p-3 mt-10 rounded-md max-w-2xl">
                    <p className="text-sm text-neutral-500">
                        <strong className="text-primary">Note:</strong> For the
                        best results, focus your instructions on details from
                        the existing post. For example, if the post highlights
                        the back deck, you can refine it by emphasizing the
                        views. Avoid unrelated topics, as they may be ignored.
                    </p>
                </div> */}
                <Card className="w-full max-w-2xl mx-auto">
                    <CardHeader>
                        <CardTitle>Regenerate Post Content</CardTitle>
                        <CardDescription className="text-center">
                            Provide instructions on how you would like to
                            regenerate the post content.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <label
                                className="text-sm font-semibold text-primary"
                                htmlFor="instructions"
                            >
                                Post Regeneration Instructions
                            </label>
                            <Textarea
                                id="instructions"
                                value={instructions}
                                onChange={handleChange}
                                placeholder="Write your post content here..."
                                className="min-h-[300px] resize-none"
                                maxLength={charLimit}
                            />
                            <p
                                className={`text-sm text-right ${
                                    instructions.length > charLimit
                                        ? "text-red-500"
                                        : "text-muted-foreground"
                                }`}
                            >
                                {instructions.length}/{charLimit} characters
                            </p>
                        </div>
                        <Separator className="my-4" />
                    </CardContent>
                    <CardFooter>
                        <Button
                            className="w-full"
                            onClick={handleRegenerate}
                            disabled={instructions.trim().length === 0}
                        >
                            <RefreshCcw className="mr-2 h-4 w-4" />
                            Regenerate
                        </Button>
                    </CardFooter>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default RegeneratePostDialog;
