import { Button } from "../../components/misc/Button";
import PlanDetails from "../../components/onboarding/PlanDetails";
import Payment from "../../components/onboarding/Payment";
import AddTeamUsers from "../../components/onboarding/AddTeamUsers";
import { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { useNavigate } from "react-router-dom";

interface ResubProps {
    role: string;
}
const ResubscribeScreen: React.FC<ResubProps> = ({ role }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const navigate = useNavigate();

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };
    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <PlanDetails
                        goBackStep={handleBack}
                        advanceStep={handleNext}
                        role={role}
                        isOnboarding={false}
                    />
                );
            case 2:
                return (
                    <Payment
                        isOnboarding={false}
                        goBackStep={handleBack}
                        advanceStep={handleNext}
                        role={role}
                        refresh={true}
                    />
                );
            case 3:
                return <AddTeamUsers role={role} />;
            default:
                return (
                    <PlanDetails
                        goBackStep={handleBack}
                        advanceStep={handleNext}
                        role={role}
                        isOnboarding={false}
                    />
                );
        }
    };
    return (
        <div>
            <div className="absolute top-0 right-0 p-4 flex flex-col">
                <Button onClick={handleLogout}>Logout</Button>
            </div>
            {renderStep()}
        </div>
    );
};

export default ResubscribeScreen;
