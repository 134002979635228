import React, { useLayoutEffect } from "react";
import DetailsTab from "../listing/tabs/DetailsTab";
import { UpdateInformationProps } from "./types";
import LoadingScreen from "../../../screens/LoadingScreen";
import { Tabs } from "../../misc/Tabs";
import { Button } from "../../misc/Button";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import StatusOverlay from "../../misc/StatusOverlay";
import { getChanges } from "../listing/getChanges";
import { setUser } from "../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const UpdateInformation: React.FC<UpdateInformationProps> = ({
    listing,
    setListing,
    mlsApproved,
    setCurrentStep,
    isMobile,
    originalPropertyData,
    setOriginalPropertyData,
}) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);

    const [overlay, setOverlay] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    useLayoutEffect(() => {
        const element = document.getElementById("update-information");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const handleSave = async () => {
        if (
            listing?._id &&
            listing?._id !== "" &&
            listing?._id?.split("-").length === 1
        ) {
            if (!listing || !originalPropertyData) return;

            const changes = getChanges(originalPropertyData, listing);

            console.log("Changes detected:", changes);
            if (Object.keys(changes).length === 0) {
                setCurrentStep(4);
            }

            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await axios.patch(
                    `${backendUrl}/listing`,
                    {
                        ...changes,
                        listingId: listing._id.toString(),
                        auto: true,
                    },
                    { headers }
                );

                if (response.status === 200) {
                    setCurrentStep(4);
                } else {
                    throw new Error("Failed to update listing.");
                }
            } catch (error) {
                console.error("Error saving listing:", error);
                toast.error(
                    <CustomToast
                        message="Failed to save changes."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            } finally {
                setLoading(false);
            }
        } else {
            try {
                setLoading(true);
                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const payload = { ...listing };
                const response = await axios.post(
                    `${backendUrl}/listing/create`,
                    payload,
                    {
                        headers,
                        withCredentials: true,
                    }
                );

                if (response.status === 201 || response.status === 200) {
                    if (user) {
                        dispatch(setUser({ ...user, mlsWelcome: false }));
                    }

                    setLoading(false);
                    toast.success(
                        <CustomToast
                            message="Listing created successfully."
                            type="success"
                        />,
                        { autoClose: 3000 }
                    );

                    setListing(response.data);
                    setOriginalPropertyData(response.data);
                    setCurrentStep(4);
                } else {
                    setLoading(false);
                    toast.error(
                        <CustomToast
                            message="Failed to create listing. Please try again."
                            type="error"
                        />,
                        { autoClose: 3000 }
                    );
                }
            } catch (error) {
                setLoading(false);
                console.error("Error creating listing:", error);
                toast.error(
                    <CustomToast
                        message="Failed to create listing. Please try again."
                        type="error"
                    />,
                    { autoClose: 3000 }
                );
            }
        }
    };

    if (!listing || !setListing) {
        setTimeout(() => {
            window.location.href = "/listings";
        }, 3000);
        return <LoadingScreen />;
    }

    return (
        <main
            id="update-information"
            className={`flex flex-col justify-center items-center min-h-full shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${
                isMobile ? "px-0 bg-white" : "bg-background"
            }`}
        >
            {loading && <LoadingScreen />}
            <StatusOverlay
                isOpen={overlay}
                title="Update Property Information"
                message="Verify Property Information"
                messageTwo="Please review and update the property details to proceed."
                messageThree="Accurate information is key to optimal campaign performance."
                allowNext={true}
                onNext={() => {
                    setOverlay(false);
                }}
            />
            <div className={`${isMobile ? "w-[90%]" : "w-[80%]"} py-10`}>
                <h1 className="mb-6 text-primary text-2xl font-bold leading-none text-center">
                    Add Additional Information
                </h1>
                <Tabs value="details" defaultValue="details">
                    <DetailsTab
                        isEditing={true}
                        mlsApproved={mlsApproved}
                        propertyData={listing}
                        setPropertyData={setListing}
                    />
                </Tabs>

                <div className="mt-6 flex justify-between items-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setCurrentStep(2);
                        }}
                    >
                        Back
                    </Button>
                    <Button onClick={handleSave}>Next</Button>
                </div>
            </div>
        </main>
    );
};

export default UpdateInformation;
