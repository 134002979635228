import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import LoadingScreen from "../../../screens/LoadingScreen";
import { getAuthHeader } from "../../../utils/authHeader";
import { ListingType, UserListing } from "../../../types/userListing";
import { Images } from "../../../types/listingImages";
import { ListingVideos } from "../../../types/listingVideos";
import { CarouselApi } from "../../misc/Carousel";
import { Assets } from "../../../types/asset-type";
import axios from "axios";
import Slideshow from "../slideshow/Slideshow";
import ListingHeader from "../listing/ListingHeader";
import DataLoadingError from "../../misc/DataLoadingError";
import MediaCarousel from "../../misc/MediaCarousel";
import { CombinedImage, CombinedMedia, CombinedVideo } from "../listing/types";
import TabsComponent from "../listing/Tabs";
import CampaignCard from "../listing/CampaignCard";
import ImageTagCard from "../listing/ImageTagCard";
import SlideshowGeneratorCard from "../listing/SlideshowGeneratorCard";
import { AllListings } from "../../../types/allListings";
import { useSocket } from "../../../context/SocketContext";
import { CampaignProgress } from "../../campaign/types";
import UserNotesCard from "../listing/UserNotesCard";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { JobStatus } from "../slideshow";
import { ImCross } from "react-icons/im";
import { FiAlertCircle } from "react-icons/fi";
import NonMLSWarning from "./NonMLSWarning";

interface LocationState {
    listingId: string;
}

interface ListingViewProps {
    mlsApproved: boolean | null;
}

const ListingView: React.FC<ListingViewProps> = ({ mlsApproved }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as LocationState | undefined;
    const [isGenerating, setIsGenerating] = useState(false);
    const [propertyData, setPropertyData] = useState<UserListing | null>(null);
    const [originalPropertyData, setOriginalPropertyData] =
        useState<UserListing | null>(null);
    const [allListing, setAllListing] = useState<AllListings | null>(null);
    const [images, setImages] = useState<Images[]>([]);
    const [videos, setVideos] = useState<ListingVideos[]>([]);
    const [assets, setAssets] = useState<Assets[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
    const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);

    const [jobId, setJobId] = useState<string | null>(null);
    const [jobStatus, setJobStatus] = useState<JobStatus | null>(null);
    const [jobListingId, setJobListingId] = useState<string | null>(null);
    const [isJobInProgress, setIsJobInProgress] = useState(false);
    const [showSaveWarning, setShowSaveWarning] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [activeTab, setActiveTab] = useState<"images" | "videos" | "details">(
        "details"
    );

    const [displayed, setDisplayed] = useState<boolean>(false);
    const displayedRef = useRef<boolean>(false); // Ref to track dismissal
    const [convertError, setConvertError] = useState(false);

    const [carouselIndices, setCarouselIndices] = useState<{
        images: number;
        videos: number;
        details: number;
    }>({
        images: 0,
        videos: 0,
        details: 0,
    });

    const isMobile = useIsMobile(1300);

    const { socket, isAuthenticated } = useSocket(); // Ensure `isAuthenticated` is available
    const subscribedCampaignsRef = useRef<Set<string>>(new Set());

    const openSlideshow = () => setIsSlideshowOpen(true);
    const closeSlideshow = () => setIsSlideshowOpen(false);

    const [nonMlsExists, setNonMlsExists] = useState(false);

    const fetchListing = useCallback(async () => {
        try {
            if (state) {
                // Clear images and videos before fetching
                // setImages([]);
                // setVideos([]);

                const headers = await getAuthHeader();
                const backendUrl = process.env.REACT_APP_BACKEND_URL;

                const response = await fetch(
                    `${backendUrl}/listing/${state.listingId}`,
                    {
                        headers,
                        method: "GET",
                    }
                );

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();

                if (data) {
                    const updatedData: UserListing = data;
                    setPropertyData(updatedData);
                    setOriginalPropertyData(updatedData);

                    const allListingData: AllListings = {
                        id: data._id,
                        formattedAddress: data.formattedAddress,
                        status: data.status,
                        latitude: data.latitude,
                        longitude: data.longitude,
                        primaryImage: data.primaryImage,
                        campaignStatus: data.campaignStatus,
                        campaignType: data.campaignType,
                        generatedPosts: data.generatedPosts,
                        publishedPosts: data.publishedPosts,
                        scheduledPosts: data.scheduledPosts,
                        unapprovedPosts: data.unapprovedPosts,
                        contentRefresh: data.contentRefresh,
                        progress: data.progress,
                        color: data.color,
                        campaignId: data.campaignId,
                        campaignProgress: data.campaignProgress,
                        imageCount: data.imageCount,
                        listingType: data.listingType,
                        convertError: data.convertError,
                    };

                    setAllListing(allListingData);

                    const shouldShowNonMLSWarning =
                        allListingData?.campaignStatus?.toLowerCase() !==
                            "processing" &&
                        mlsApproved === true &&
                        allListingData.listingType !== ListingType.MLSListing &&
                        !displayedRef.current; // Use ref

                    setNonMlsExists(shouldShowNonMLSWarning);

                    setConvertError(allListingData.convertError || false);

                    setImages(data.images || []);
                    setVideos(data.videos || []);
                }
            } else {
                toast.error(
                    <CustomToast
                        message="Listing Retrieval Failed"
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );

                setTimeout(() => {
                    navigate("/listings");
                }, 3000);
            }
        } catch (error) {
            console.error("Error fetching listing:", error);
            toast.error(
                <CustomToast message="Failed to load listing." type="error" />,
                {
                    autoClose: 3000,
                }
            );
        }
    }, [state, navigate, mlsApproved]);

    const fetchAssets = useCallback(async () => {
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(
                `${backendUrl}/assets/image-assets`,
                {
                    headers,
                }
            );

            if (response.data) {
                if (Array.isArray(response.data)) {
                    setAssets(response.data);
                } else if (
                    response.data.assets &&
                    Array.isArray(response.data.assets)
                ) {
                    setAssets(response.data.assets);
                } else {
                    console.error("Assets data is not an array", response.data);
                    setAssets([]);
                }
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 404) {
                    // Corrected to access status via error.response
                    console.log("No assets found for listing");
                } else {
                    console.error("Error fetching assets:", error);
                    toast.error(
                        <CustomToast
                            message="Failed to load assets."
                            type="error"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } else {
                console.log("Error fetching assets:", error);
                toast.error(
                    <CustomToast
                        message="Failed to load assets."
                        type="error"
                    />,
                    {
                        autoClose: 3000,
                    }
                );
            }
        }
    }, []);

    const fetchSlideshowJob = useCallback(async () => {
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(
                `${backendUrl}/listing/slideshow-jobs`,
                {
                    headers,
                }
            );

            if (response.status === 200) {
                const {
                    jobId: fetchedJobId,
                    jobStatus: fetchedJobStatus,
                    listingId,
                } = response.data;

                if (fetchedJobId && fetchedJobId.length > 0) {
                    setJobId(fetchedJobId);
                    setJobStatus(fetchedJobStatus);
                    setJobListingId(listingId);
                }

                // Optionally, handle listingId if needed
            } else {
                throw new Error("Error fetching slideshow jobs");
            }
        } catch (error) {
            console.error("Error fetching slideshow jobs:", error);
            // Optionally, handle errors (e.g., toast notifications)
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchListing(),
                    fetchAssets(),
                    fetchSlideshowJob(),
                ]);
            } catch (error) {
                setIsLoading(true);
                toast.error(
                    <CustomToast message="Failed to load data." type="error" />,
                    {
                        autoClose: 3000,
                    }
                );
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [fetchListing, fetchAssets, fetchSlideshowJob]);

    useEffect(() => {
        console.log("Job ID:", jobId);
        console.log("Job Status:", jobStatus);
        setIsJobInProgress(
            jobId !== null &&
                (jobStatus === JobStatus.QUEUED ||
                    jobStatus === JobStatus.PROCESSING)
        );
    }, [jobId, jobStatus, jobListingId, propertyData]);

    // Extract campaignId and campaignStatus using useMemo for stability
    const campaignId = useMemo(() => allListing?.campaignId, [allListing]);
    const campaignStatus = useMemo(
        () => allListing?.campaignStatus,
        [allListing]
    );

    useEffect(() => {
        if (!socket || !isAuthenticated || !campaignId) return;

        const currentSubscribedCampaigns = subscribedCampaignsRef.current;

        // Only subscribe if the campaign is in "PROCESSING" status and not already subscribed
        if (
            campaignStatus === "PROCESSING" &&
            !currentSubscribedCampaigns.has(campaignId)
        ) {
            socket.emit("subscribeToCampaign", campaignId);
            currentSubscribedCampaigns.add(campaignId);
            console.log(`Subscribed to campaign: ${campaignId}`);
        }

        const handleProgressUpdate = (data: {
            entityId: string;
            entityType: "campaign";
            progress: CampaignProgress;
        }) => {
            if (
                data.entityType === "campaign" &&
                data.entityId === campaignId
            ) {
                setAllListing((prevAllListing) => {
                    if (!prevAllListing) return prevAllListing;

                    const updatedCampaignStatus =
                        data.progress.currentStep === "Completed"
                            ? "ACTIVE"
                            : prevAllListing.campaignStatus;

                    return {
                        ...prevAllListing,
                        campaignProgress: data.progress,
                        campaignStatus: updatedCampaignStatus,
                    };
                });

                if (
                    data.progress.currentStep === "Completed" ||
                    data.progress.currentStep.toLowerCase() === "error"
                ) {
                    fetchListing();
                }
            }
        };

        // Listen for progress updates
        socket.on("progressUpdate", handleProgressUpdate);

        // Cleanup function
        return () => {
            // Remove the progress update listener
            socket.off("progressUpdate", handleProgressUpdate);

            // Unsubscribe only if the campaign was subscribed in this effect
            if (
                campaignStatus === "PROCESSING" &&
                currentSubscribedCampaigns.has(campaignId)
            ) {
                socket.emit("unsubscribeFromCampaign", campaignId);
                currentSubscribedCampaigns.delete(campaignId);
                console.log(`Unsubscribed from campaign: ${campaignId}`);
            }
        };
    }, [socket, isAuthenticated, campaignId, campaignStatus, fetchListing]);

    const combinedMedia: CombinedMedia[] = [
        ...images.map(
            (image) => ({ ...image, type: "image" } as CombinedImage)
        ),
        ...videos.map(
            (video) => ({ ...video, type: "video" } as CombinedVideo)
        ),
    ];

    const combinedImages = useMemo(
        () =>
            images.map(
                (image) => ({ ...image, type: "image" } as CombinedImage)
            ),
        [images]
    );

    const combinedVideos = useMemo(
        () =>
            videos.map(
                (video) => ({ ...video, type: "video" } as CombinedVideo)
            ),
        [videos]
    );

    useEffect(() => {
        if (carouselApi) {
            const index = carouselIndices[activeTab] || 0;
            carouselApi.scrollTo(index);
        }
    }, [activeTab, carouselApi, carouselIndices]);

    if (isLoading) return <LoadingScreen />;

    if (!propertyData) {
        setTimeout(() => {
            navigate("/listings");
        }, 3000);
        return (
            <DataLoadingError
                heading="Data Loading Error"
                text="The requested property data could not be loaded. You will be
              redirected back in a few seconds."
            />
        );
    }

    console.log("Non MLS Exists:", nonMlsExists);
    return (
        <div className="container mx-auto px-4 py-8">
            {nonMlsExists && !displayed && (
                <NonMLSWarning
                    listingId={propertyData.id || propertyData._id.toString()}
                    open={nonMlsExists && !displayed}
                    setOpen={(open: boolean) => {
                        if (!open) {
                            setDisplayed(true);
                            displayedRef.current = true;
                        }
                        setNonMlsExists(open);
                    }}
                    setDisplayed={(value: boolean) => {
                        setDisplayed(value);
                        if (value) {
                            displayedRef.current = true;
                        }
                    }}
                    convertError={convertError}
                    setConvertError={setConvertError}
                />
            )}
            <div className={`flex ${isMobile ? "flex-col" : "flex-row gap-8"}`}>
                {/* Left Column */}
                <div
                    className={`flex flex-col w-full ${
                        isMobile ? "" : "lg:flex-1 max-w-[70%]"
                    }`}
                >
                    {/* Listing Header */}
                    <ListingHeader
                        formattedAddress={propertyData.formattedAddress}
                        propertyType={propertyData.propertyType}
                        status={propertyData.status}
                    />
                    {/* Media Carousel */}
                    <MediaCarousel
                        key={activeTab}
                        setCarouselApi={setCarouselApi}
                        combinedMedia={
                            activeTab === "details"
                                ? combinedMedia
                                : activeTab === "images"
                                ? combinedImages
                                : combinedVideos
                        }
                        isMobile={isMobile}
                        latitude={propertyData.latitude}
                        longitude={propertyData.longitude}
                        address={propertyData.formattedAddress}
                    />
                    {/* Image Tag Card (Mobile Only) */}
                    {isMobile && (
                        <ImageTagCard
                            combinedMedia={combinedMedia}
                            carouselApi={carouselApi}
                            propertyData={propertyData}
                            setImages={setImages}
                            images={images}
                            setPropertyData={setPropertyData}
                        />
                    )}
                    {/* Tabs Component */}
                    <TabsComponent
                        images={images}
                        videos={videos}
                        setIsLoading={setIsLoading}
                        carouselApi={carouselApi}
                        propertyData={propertyData}
                        fetchListing={fetchListing}
                        fetchAssets={fetchAssets}
                        setPropertyData={setPropertyData}
                        originalPropertyData={originalPropertyData}
                        setOriginalPropertyData={setOriginalPropertyData}
                        combinedMedia={combinedMedia}
                        combinedImages={combinedImages}
                        combinedVideos={combinedVideos}
                        mlsApproved={mlsApproved}
                        setShowSaveWarning={setShowSaveWarning}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        carouselIndices={carouselIndices}
                        setCarouselIndices={setCarouselIndices}
                        isUploading={isUploading}
                        setIsUploading={setIsUploading}
                    />

                    {showSaveWarning ? (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
                            <div
                                className={`relative ${
                                    isMobile ? "w-full max-w-md" : "w-1/3"
                                } rounded-lg bg-white p-6 shadow-lg`}
                            >
                                <button
                                    onClick={() => setShowSaveWarning(false)}
                                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close"
                                >
                                    <ImCross className="h-6 w-6" />
                                </button>
                                <div className="flex flex-col items-center text-center">
                                    <FiAlertCircle className="h-16 w-16 text-yellow-500" />
                                    <h2 className="mt-4 text-xl sm:text-2xl font-bold text-primary">
                                        Changes Made
                                    </h2>
                                    <p className="mt-2 text-gray-600 text-sm sm:text-base">
                                        Any edits made to details will only
                                        apply to the next campaign renewal.
                                        Changes to details will not impact their
                                        use in any ongoing or existing campaign
                                        but will be reflected in future campaign
                                        renewals. Please review your edits
                                        carefully before saving.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* Right Column */}
                <div
                    className={`flex flex-col w-full ${
                        isMobile ? "gap-4" : "lg:w-1/3 max-w-[30%]"
                    } gap-8`}
                >
                    {/* Campaign Card */}
                    {allListing !== null && (
                        <CampaignCard
                            allListing={allListing}
                            mlsApproved={mlsApproved || false}
                            isUploading={isUploading}
                            setLoading={setIsLoading}
                            setPropertyData={setPropertyData}
                            setOriginalPropertyData={setOriginalPropertyData}
                            fetchListing={fetchListing}
                        />
                    )}

                    {/* Image Tag Card (Desktop Only) */}
                    {!isMobile && (
                        <ImageTagCard
                            combinedMedia={combinedMedia}
                            carouselApi={carouselApi}
                            propertyData={propertyData}
                            setImages={setImages}
                            images={images}
                            setPropertyData={setPropertyData}
                        />
                    )}

                    {/* Slideshow Generator */}
                    <SlideshowGeneratorCard
                        openSlideshow={openSlideshow}
                        isGenerating={isGenerating}
                        isJobInProgress={isJobInProgress}
                        isUploading={isUploading}
                    />

                    {/* User Notes Card */}
                    <UserNotesCard
                        propertyData={propertyData}
                        setPropertyData={setPropertyData}
                        mlsApproved={mlsApproved || false}
                    />
                </div>
            </div>

            {/* Slideshow Component */}
            <Slideshow
                listingId={propertyData._id}
                listingType={propertyData.listingType}
                images={images}
                assets={assets}
                isOpen={isSlideshowOpen}
                onClose={closeSlideshow}
                videos={videos}
                setVideos={setVideos}
                formattedAddress={propertyData.formattedAddress}
                isGenerating={isGenerating}
                setIsGenerating={setIsGenerating}
                jobId={jobId}
                setJobId={setJobId}
                jobStatus={jobStatus}
                setJobStatus={setJobStatus}
                fetchListing={fetchListing}
                isJobInProgress={isJobInProgress}
            />
        </div>
    );
};

export default ListingView;
