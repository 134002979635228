import TutorialsComingSoon from "../../misc/HelpComingSoon";
// import ConnectSocialMediaPlatforms from "./pages/ConnectSocialMediaPlatforms";

export interface Tutorial {
    id: number;
    title: string;
    description: string;
    link: string;
    component?: React.FC;
}

export const tutorialsData: Tutorial[] = [
    {
        id: 1,
        title: "Create a listing",
        description: "Learn how to create a new listing in the app.",
        link: "create-listing",
        component: TutorialsComingSoon,
    },
    {
        id: 2,
        title: "Create MLS Listing",
        description: "Step-by-step guide to creating an MLS listing.",
        link: "create-mls-listing",
        component: TutorialsComingSoon,
    },
    {
        id: 3,
        title: "Create Promotion",
        description: "Discover how to create effective promotions.",
        link: "create-promotion",
        component: TutorialsComingSoon,
    },
    {
        id: 4,
        title: "Create Listing Campaign",
        description: "Learn to set up campaigns for your listings.",
        link: "create-listing-campaign",
        component: TutorialsComingSoon,
    },
    {
        id: 5,
        title: "Create Promotion Campaign",
        description: "Guide to creating promotional campaigns.",
        link: "create-promotion-campaign",
        component: TutorialsComingSoon,
    },
    {
        id: 6,
        title: "Add Media to Listings",
        description: "Learn how to enhance your listings with media.",
        link: "add-media-listings",
        component: TutorialsComingSoon,
    },
    {
        id: 7,
        title: "Create Slideshow videos for listings",
        description: "Create engaging slideshows for your listings.",
        link: "create-slideshow-videos",
        component: TutorialsComingSoon,
    },
    {
        id: 8,
        title: "Add Media to promotions",
        description: "Enhance your promotions with media content.",
        link: "add-media-promotions",
        component: TutorialsComingSoon,
    },
    {
        id: 9,
        title: "Manage all media from My Contents Page",
        description: "Learn to efficiently manage your media content.",
        link: "manage-media",
        component: TutorialsComingSoon,
    },
    {
        id: 10,
        title: "Change Plan",
        description: "Guide to changing your subscription plan.",
        link: "change-plan",
        component: TutorialsComingSoon,
    },
    {
        id: 11,
        title: "Change Password",
        description: "Steps to update your account password.",
        link: "change-password",
        component: TutorialsComingSoon,
    },
    {
        id: 12,
        title: "Add AdHoc Post",
        description: "Learn how to create ad hoc posts.",
        link: "add-adhoc-post",
        component: TutorialsComingSoon,
    },
    {
        id: 13,
        title: "Edit Post",
        description: "Guide to editing your existing posts.",
        link: "edit-post",
        component: TutorialsComingSoon,
    },
    {
        id: 14,
        title: "Connect Social Media Platforms",
        description: "Link your social media accounts to the app.",
        link: "connect-social-media",
        component: TutorialsComingSoon, //ConnectSocialMediaPlatforms,
    },
    {
        id: 15,
        title: "Cancel Plan",
        description: "Learn how to cancel your subscription plan.",
        link: "cancel-plan",
        component: TutorialsComingSoon,
    },
];
