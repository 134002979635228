import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { FaArrowLeft, FaClock } from "react-icons/fa";
import { Card, CardContent, CardHeader } from "../misc/Card";
import { Badge } from "../calendar/Badge";
import { Button } from "../misc/Button";
import {
    CalendarIcon,
    Check,
    ImageIcon,
    RefreshCw,
    Trash2,
} from "lucide-react";
import { toast } from "react-toastify";
import axios from "axios";
import FacebookPost from "./FacebookPost";
import InstagramPost from "./InstagramPost";
import LoadingScreen from "../../screens/LoadingScreen";
import { useIsMobile } from "../../hooks/useIsMobile";
import { FaPencil } from "react-icons/fa6";
import PostMediaPicker from "./PostMediaPicker";
import { PostRelatedMedia } from "./types";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";
import EditPostDialog from "./EditPostDialog";
import RegeneratePostDialog from "./RegeneratePostDialog";
import { Post as PostType } from "../../types/posts";
import { getAuthHeader } from "../../utils/authHeader";
import CustomToast from "../misc/CustomToast";
import { format, isBefore, setHours, setMinutes } from "date-fns";
import { Dialog, DialogContent } from "../misc/Dialog";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import CustomDatePicker from "../misc/CutomDatePicker";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../misc/Select";
import RandomStatus from "../misc/RandomStatus";

interface UpdateTimeDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (newDateTime: string) => void;
    initialScheduledFor: string;
}

const UpdateTimeDialog: React.FC<UpdateTimeDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    initialScheduledFor,
}) => {
    // Prefill date with initialScheduledFor if provided; otherwise use current date.
    const initialDate = initialScheduledFor
        ? new Date(initialScheduledFor)
        : new Date();
    const [date, setDate] = useState<Date>(initialDate);
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

    // Store time in 24-hour format
    const initialHour = initialScheduledFor
        ? new Date(initialScheduledFor).getHours()
        : new Date().getHours();
    const initialMinute = initialScheduledFor
        ? new Date(initialScheduledFor).getMinutes()
        : new Date().getMinutes();
    const [time, setTime] = useState<{ hour: number; minute: number }>({
        hour: initialHour,
        minute: initialMinute,
    });

    // When the dialog opens (or initialScheduledFor changes), update state.
    useEffect(() => {
        const newInitialDate = initialScheduledFor
            ? new Date(initialScheduledFor)
            : new Date();
        setDate(newInitialDate);
        setTime({
            hour: newInitialDate.getHours(),
            minute: newInitialDate.getMinutes(),
        });
    }, [initialScheduledFor, isOpen]);

    // Compute period (AM/PM) and display hour from the 24-hour stored value.
    const period = time.hour >= 12 ? "PM" : "AM";
    const displayHour = time.hour % 12 === 0 ? 12 : time.hour % 12;

    const hourOptions = Array.from({ length: 12 }, (_, i) => i + 1);
    const minuteOptions = Array.from({ length: 60 }, (_, i) => i);

    const handleHourChange = (value: string) => {
        const selectedHour12 = parseInt(value, 10);
        let newHour = selectedHour12;
        if (period === "PM" && selectedHour12 !== 12) {
            newHour = selectedHour12 + 12;
        } else if (period === "AM" && selectedHour12 === 12) {
            newHour = 0;
        }
        setTime((prev) => ({ ...prev, hour: newHour }));
    };

    const handleMinuteChange = (value: string) => {
        const newMinute = parseInt(value, 10);
        setTime((prev) => ({ ...prev, minute: newMinute }));
    };

    const handlePeriodChange = (value: string) => {
        // Convert the current displayed hour (in 12-hour format) into 24-hour format based on the new period.
        const currentDisplayHour = time.hour % 12 === 0 ? 12 : time.hour % 12;
        const newHour =
            value === "AM"
                ? currentDisplayHour === 12
                    ? 0
                    : currentDisplayHour
                : currentDisplayHour === 12
                ? 12
                : currentDisplayHour + 12;
        setTime((prev) => ({ ...prev, hour: newHour }));
    };

    const handleSubmit = () => {
        // Combine the selected date and time.
        const scheduledDateTime = setHours(
            setMinutes(new Date(date), time.minute),
            time.hour
        );
        if (isBefore(scheduledDateTime, new Date())) {
            toast.error(
                <CustomToast
                    message="Cannot schedule in the past."
                    type="error"
                />,
                { autoClose: 3000 }
            );
            return;
        }
        onSubmit(scheduledDateTime.toISOString());
    };

    if (!isOpen) return null;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="bg-white relative p-4 w-full max-w-md mx-auto">
                <h2 className="text-xl font-semibold mb-4 text-primary">
                    Update Scheduled Time
                </h2>
                <p className="mb-4">
                    The current scheduled time is in the past. Please choose a
                    new date and time.
                </p>
                <div className="flex flex-col items-center justify-center mb-4">
                    <div className="flex flex-col gap-4">
                        {/* Date Picker */}
                        <Popover
                            open={isCalendarOpen}
                            onOpenChange={setIsCalendarOpen}
                        >
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="w-[240px] justify-start text-left"
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {date ? format(date, "PPP") : "Select date"}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                className="w-auto p-0"
                                align="start"
                            >
                                <CustomDatePicker
                                    date={date}
                                    setDate={setDate}
                                />
                            </PopoverContent>
                        </Popover>
                        {/* Time Picker */}
                        <div className="flex items-center space-x-2">
                            <Select
                                value={displayHour.toString()}
                                onValueChange={handleHourChange}
                            >
                                <SelectTrigger className="w-[70px]">
                                    <SelectValue placeholder="Hour" />
                                </SelectTrigger>
                                <SelectContent>
                                    {hourOptions.map((hour) => (
                                        <SelectItem
                                            key={hour}
                                            value={hour.toString()}
                                        >
                                            {hour.toString().padStart(2, "0")}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <Select
                                value={time.minute.toString()}
                                onValueChange={handleMinuteChange}
                            >
                                <SelectTrigger className="w-[70px]">
                                    <SelectValue placeholder="Min" />
                                </SelectTrigger>
                                <SelectContent>
                                    {minuteOptions.map((minute) => (
                                        <SelectItem
                                            key={minute}
                                            value={minute.toString()}
                                        >
                                            {minute.toString().padStart(2, "0")}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <Select
                                value={period}
                                onValueChange={handlePeriodChange}
                            >
                                <SelectTrigger className="w-[70px]">
                                    <SelectValue placeholder="AM/PM" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="AM">AM</SelectItem>
                                    <SelectItem value="PM">PM</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between gap-2 mt-4">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

type ConfirmAction = "approve" | "pause" | "delete" | null;

const Post: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { postId } = useParams<{ postId: string }>();

    useEffect(() => {
        if (!postId && !location.state?.postId) {
            navigate(-1);
        }
    }, [postId, location.state, navigate]);

    const actualPostId = postId || location.state?.postId;

    const [post, setPost] = useState<PostType | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const [isRegenerateDialogOpen, setIsRegenerateDialogOpen] =
        useState<boolean>(false);
    const [isMediaPickerOpen, setIsMediaPickerOpen] = useState<boolean>(false);
    const [media, setMedia] = useState<PostRelatedMedia | null>(null);
    const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [isUpdateTimeDialogOpen, setIsUpdateTimeDialogOpen] =
        useState<boolean>(false);

    const [regenerating, setRegenerating] = useState<boolean>(false);

    const getPostData = useCallback(async () => {
        if (!actualPostId) return;

        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.get(
                `${backendUrl}/post/${actualPostId}`,
                {
                    headers,
                }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to fetch post data.");
            }

            setPost(response.data);
            console.log(response.data);
        } catch (error: unknown) {
            console.error("Error fetching post data", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch post data."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    }, [actualPostId]);

    useEffect(() => {
        getPostData();
    }, [getPostData]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleSaveEdit = async (updatedFields: Partial<PostType>) => {
        setLoading(true);
        setIsEditDialogOpen(false);

        if (!actualPostId || !post) {
            toast.error(
                <CustomToast message="Invalid post data." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post/`,
                { postIds: [actualPostId], ...updatedFields },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to update post.");
            }

            setPost({ ...post, ...updatedFields });
            toast.success(
                <CustomToast
                    message="Post updated successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            getPostData();
        } catch (error: unknown) {
            console.error("Error updating post", error);
            toast.error(
                <CustomToast message="Failed to update post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleRegenerate = async (instructions: string) => {
        setIsRegenerateDialogOpen(false);
        setRegenerating(true);

        if (!actualPostId || !post) {
            toast.error(
                <CustomToast message="Invalid post data." type="error" />,
                { autoClose: 3000 }
            );
            return;
        }

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.post(
                `${backendUrl}/post/regenerate/`,
                {
                    postId: actualPostId,
                    text: instructions,
                },
                { headers }
            );

            if (!response || response.status !== 200 || !response.data) {
                throw new Error("Failed to regenerate post content.");
            }

            setPost({ ...post, content: response.data });
            toast.success(
                <CustomToast
                    message="Post content regenerated successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error: unknown) {
            console.error("Error regenerating post content", error);
            toast.error(
                <CustomToast
                    message="Failed to regenerate post content."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setRegenerating(false);
        }
    };

    const handleEditMedia = async () => {
        setLoading(true);
        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await axios.get(
                `${backendUrl}/post/all-media/${actualPostId}`,
                {
                    headers,
                }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to fetch media data.");
            }

            setMedia(response.data);
        } catch (error: unknown) {
            console.error("Error fetching media data", error);
            toast.error(
                <CustomToast
                    message="Failed to fetch media data."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }

        setIsMediaPickerOpen(true);
    };

    const isMobile = useIsMobile(900);

    const confirmAction = async () => {
        if (!actionToConfirm) return;

        if (actionToConfirm === "approve") {
            await handleApprovePost();
        } else if (actionToConfirm === "pause") {
            await handlePausePost();
        } else if (actionToConfirm === "delete") {
            await handleDeletePost();
        }
    };

    // Updated approval handler with scheduledFor check
    const handleApprovePost = async () => {
        // If scheduledFor is in the past, open the update time dialog
        if (post?.scheduledFor && new Date(post.scheduledFor) < new Date()) {
            setIsUpdateTimeDialogOpen(true);
            setIsOverlayOpen(false);
            return;
        }

        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post`,
                { postIds: [actualPostId], status: "APPROVED" },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to approve post.");
            }

            setPost({ ...post!, status: "Scheduled" });
            getPostData();
            toast.success(
                <CustomToast
                    message="Post approved and scheduled successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error: unknown) {
            console.error("Error approving post", error);
            toast.error(
                <CustomToast message="Failed to approve post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    // Called when the user submits a new scheduled time from the dialog
    const handleApprovePostWithNewTime = async (newTime: string) => {
        setIsUpdateTimeDialogOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post`,
                {
                    postIds: [actualPostId],
                    scheduledFor: newTime,
                    status: "APPROVED",
                },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error(
                    "Failed to approve post. Make sure the campaign is active before approving again."
                );
            }

            setPost({ ...post!, status: "Scheduled", scheduledFor: newTime });
            getPostData();
            toast.success(
                <CustomToast
                    message="Post approved and scheduled successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
        } catch (error: unknown) {
            console.error(
                "Error approving post. Make sure the campaign is active before approving again.",
                error
            );
            toast.error(
                <CustomToast
                    message="Error approving post. Make sure the campaign is active before approving again."
                    type="error"
                />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handlePausePost = async () => {
        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.patch(
                `${backendUrl}/post`,
                { postIds: [actualPostId], status: "PAUSED" },
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to pause post.");
            }

            setPost({ ...post!, status: "Paused" });
            toast.success(
                <CustomToast
                    message="Post paused successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            getPostData();
        } catch (error: unknown) {
            console.error("Error pausing post", error);
            toast.error(
                <CustomToast message="Failed to pause post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePost = async () => {
        setIsOverlayOpen(false);
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        try {
            const response = await axios.delete(
                `${backendUrl}/post/${actualPostId}`,
                { headers }
            );

            if (!response || response.status !== 200) {
                throw new Error("Failed to delete post.");
            }

            toast.success(
                <CustomToast
                    message="Post deleted successfully."
                    type="success"
                />,
                { autoClose: 3000 }
            );
            navigate(-1);
        } catch (error: unknown) {
            console.error("Error deleting post", error);
            toast.error(
                <CustomToast message="Failed to delete post." type="error" />,
                { autoClose: 3000 }
            );
        } finally {
            setLoading(false);
        }
    };

    const getOverlayContent = () => {
        switch (actionToConfirm) {
            case "approve":
                return {
                    title: "Approve Post",
                    message: "Are you sure you want to approve this Post?",
                    messageTwo:
                        "Approving this post will schedule it for publishing.",
                    confirmText: "Approve Post",
                };
            case "delete":
                return {
                    title: "Delete Post",
                    message: "Are you sure you want to delete this Post?",
                    messageTwo: "This action cannot be undone.",
                    confirmText: "Delete Post",
                };
            default:
                return {
                    title: "",
                    message: "",
                    messageTwo: "",
                    confirmText: "",
                };
        }
    };

    const { title, message, messageTwo, confirmText } = getOverlayContent();

    const handleAction = (action: ConfirmAction) => {
        setActionToConfirm(action);
        setIsOverlayOpen(true);
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
        setActionToConfirm(null);
    };

    return (
        <div
            className={`relative w-full ${
                regenerating ? "overflow-hidden" : "overflow-y-visible"
            }`}
            style={{ minHeight: "calc(var(--vh, 1vh) * 100)" }}
        >
            {regenerating ? <RandomStatus /> : null}
            <div className={`p-4 ${isMobile ? "p-2" : "p-4"}`}>
                {loading && <LoadingScreen />}

                <ReconfirmOverlay
                    isOpen={isOverlayOpen}
                    onClose={closeOverlay}
                    onConfirm={confirmAction}
                    title={title}
                    message={message}
                    messageTwo={messageTwo}
                    confirmText={confirmText}
                />

                {/* Update Time Dialog */}
                <UpdateTimeDialog
                    isOpen={isUpdateTimeDialogOpen}
                    onClose={() => setIsUpdateTimeDialogOpen(false)}
                    onSubmit={handleApprovePostWithNewTime}
                    initialScheduledFor={post?.scheduledFor || ""}
                />

                <Button
                    id="back-btn"
                    onClick={handleBack}
                    aria-label="Go Back"
                    className={`flex items-center mb-4 ${
                        isMobile ? "px-2 py-1 text-sm" : "px-4 py-2"
                    }`}
                >
                    <FaArrowLeft
                        className={`mr-2 ${isMobile ? "w-4 h-4" : "w-5 h-5"}`}
                    />
                    Back
                </Button>

                {post && (
                    <EditPostDialog
                        isOpen={isEditDialogOpen}
                        onClose={() => setIsEditDialogOpen(false)}
                        post={post}
                        onSave={handleSaveEdit}
                    />
                )}

                <RegeneratePostDialog
                    isOpen={isRegenerateDialogOpen}
                    onClose={() => setIsRegenerateDialogOpen(false)}
                    onRegenerate={handleRegenerate}
                />

                {post?.status === "ERROR" ? (
                    <div className="max-w-3xl mx-auto shadow-lg bg-gray-100 p-3 rounded-md mt-4">
                        <p className="text-sm text-gray-600">
                            <strong className="text-[#d50000]">Error :</strong>{" "}
                            Post error is being investigated by our team. We
                            will reach out to you with more information soon.
                        </p>
                        <p className="text-sm text-gray-600">
                            In the meantime, if you have any questions or
                            concerns, please don't hesitate to reach out to our
                            support team through our{" "}
                            <Link
                                className="text-primary hover:text-secondary font-semibold"
                                to="/"
                            >
                                contact form.
                            </Link>
                        </p>
                    </div>
                ) : null}
                <Card
                    className={`max-w-3xl mx-auto shadow-lg ${
                        isMobile ? "w-full" : ""
                    }`}
                >
                    <CardHeader className="space-y-2">
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="flex items-center space-x-4">
                                {post?.scheduledFor && (
                                    <Badge className="flex gap-2 text-sm mb-2">
                                        <FaClock className="w-4 h-4" />
                                        {new Date(
                                            post.scheduledFor
                                        ).toLocaleString()}
                                    </Badge>
                                )}
                                {post?.contentLength && (
                                    <Badge
                                        className="text-white text-sm mb-2 px-4"
                                        variant="secondary"
                                    >
                                        {post.contentLength} Post
                                    </Badge>
                                )}
                            </div>
                            {post?.type && (
                                <Badge
                                    className={`text-white ${
                                        isMobile ? "text-xs" : "text-sm"
                                    }`}
                                >
                                    {post.type}
                                </Badge>
                            )}
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-6 pt-0">
                        <div className="space-y-4">
                            <div
                                className={`flex justify-between items-center flex-wrap ${
                                    isMobile ? "" : "mb-4"
                                }`}
                            >
                                <div className="w-full flex justify-between items-center">
                                    <div className="flex flex-col">
                                        <h3
                                            className={`text-lg font-semibold text-primary ${
                                                isMobile ? "text-md" : "text-lg"
                                            }`}
                                        >
                                            Post Preview
                                            {post?.type && post?.address && (
                                                <> - {post.address}</>
                                            )}
                                            {post?.contentType && (
                                                <>
                                                    {" "}
                                                    -{" "}
                                                    {post.contentType
                                                        .replace(
                                                            /([A-Z])/g,
                                                            " $1"
                                                        )
                                                        .replace(/\b\w/g, (c) =>
                                                            c.toUpperCase()
                                                        )}
                                                </>
                                            )}
                                        </h3>
                                        <span className="text-neutral-500 text-sm">
                                            Preview your social media post
                                        </span>
                                    </div>
                                    {isMobile &&
                                        post?.status.toLowerCase() !==
                                            "posted" && (
                                            <Button
                                                id="edit-post-btn"
                                                size="sm"
                                                onClick={() =>
                                                    setIsEditDialogOpen(true)
                                                }
                                                className="flex items-center justify-center"
                                            >
                                                <FaPencil className="w-4 h-4 mr-2" />
                                                Edit
                                            </Button>
                                        )}
                                </div>
                                <div className="w-full flex flex-row items-end justify-between">
                                    <div
                                        className={`space-x-2 mt-2 flex flex-row ${
                                            post?.status.toLowerCase() ===
                                            "posted"
                                                ? "justify-end"
                                                : post?.type.toLowerCase() ===
                                                  "ad hoc"
                                                ? "justify-start"
                                                : "justify-evenly"
                                        } w-full`}
                                    >
                                        {!isMobile &&
                                            post?.status.toLowerCase() !==
                                                "posted" && (
                                                <Button
                                                    id="edit-post-btn"
                                                    size="sm"
                                                    onClick={() =>
                                                        setIsEditDialogOpen(
                                                            true
                                                        )
                                                    }
                                                    className={`flex items-center justify-center ${
                                                        isMobile
                                                            ? "w-full mb-2"
                                                            : ""
                                                    }`}
                                                >
                                                    <FaPencil className="w-4 h-4 mr-2" />
                                                    Edit
                                                </Button>
                                            )}
                                        {post?.status.toLowerCase() ===
                                            "draft" ||
                                        post?.status.toLowerCase() ===
                                            "unposted" ? (
                                            <Button
                                                id="approve-post-btn"
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => {
                                                    handleAction("approve");
                                                }}
                                                className={`flex items-center justify-center ${
                                                    isMobile ? "w-full" : ""
                                                }`}
                                            >
                                                <Check
                                                    className={`w-4 h-4 ${
                                                        isMobile
                                                            ? "m-0"
                                                            : "mr-2"
                                                    }`}
                                                />
                                                {!isMobile && "Approve Post"}
                                            </Button>
                                        ) : null}

                                        {post?.status.toLowerCase() !==
                                            "posted" && (
                                            <>
                                                {post?.type.toLowerCase() !==
                                                "ad hoc" ? (
                                                    <Button
                                                        id="regenerate-post-btn"
                                                        size="sm"
                                                        onClick={() =>
                                                            setIsRegenerateDialogOpen(
                                                                true
                                                            )
                                                        }
                                                        className={`flex items-center justify-center ${
                                                            isMobile
                                                                ? "w-full mb-2"
                                                                : ""
                                                        }`}
                                                    >
                                                        <RefreshCw
                                                            className={`w-4 h-4 ${
                                                                isMobile
                                                                    ? "m-0"
                                                                    : "mr-2"
                                                            }`}
                                                        />
                                                        {!isMobile &&
                                                            "Regenerate"}
                                                    </Button>
                                                ) : null}
                                                <Button
                                                    id="edit-media-btn"
                                                    size="sm"
                                                    variant="secondary"
                                                    onClick={handleEditMedia}
                                                    className={`flex items-center justify-center ${
                                                        isMobile ? "w-full" : ""
                                                    }`}
                                                >
                                                    <ImageIcon
                                                        className={`w-4 h-4 ${
                                                            isMobile
                                                                ? "m-0"
                                                                : "mr-2"
                                                        }`}
                                                    />
                                                    {!isMobile && "Edit Media"}
                                                </Button>
                                                {isMobile ? (
                                                    <Button
                                                        id="delete-post-btn"
                                                        variant="destructive"
                                                        size="sm"
                                                        onClick={() => {
                                                            handleAction(
                                                                "delete"
                                                            );
                                                        }}
                                                        className={`flex items-center justify-center ${
                                                            isMobile
                                                                ? "w-full"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Trash2
                                                            className={`w-4 h-4 ${
                                                                isMobile
                                                                    ? "m-0"
                                                                    : "mr-2"
                                                            }`}
                                                        />
                                                        {!isMobile &&
                                                            "Delete Post"}
                                                    </Button>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                    {!isMobile ? (
                                        <div>
                                            <Button
                                                id="delete-post-btn"
                                                variant="destructive"
                                                size="sm"
                                                onClick={() => {
                                                    handleAction("delete");
                                                }}
                                                className={`flex items-center justify-center ${
                                                    isMobile ? "w-full" : ""
                                                }`}
                                            >
                                                <Trash2
                                                    className={`w-4 h-4 ${
                                                        isMobile
                                                            ? "m-0"
                                                            : "mr-2"
                                                    }`}
                                                />
                                                {!isMobile && "Delete Post"}
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {post &&
                            post.platform.toLowerCase() === "facebook" ? (
                                <FacebookPost
                                    post={post}
                                    setLoading={setLoading}
                                />
                            ) : post &&
                              post.platform.toLowerCase() === "instagram" ? (
                                <InstagramPost
                                    post={post}
                                    setLoading={setLoading}
                                />
                            ) : !loading ? (
                                <div className="text-red-500">
                                    Unsupported platform: {post?.platform}
                                </div>
                            ) : null}
                        </div>
                    </CardContent>

                    <PostMediaPicker
                        isMobile={isMobile}
                        isMediaPickerOpen={isMediaPickerOpen}
                        setIsMediaPickerOpen={setIsMediaPickerOpen}
                        media={media}
                        platform={post?.platform || ""}
                        postMedia={post?.media || []}
                        setLoading={setLoading}
                        setPost={setPost}
                        postId={actualPostId}
                        getPostData={getPostData}
                    />
                </Card>
            </div>
        </div>
    );
};

export default Post;
