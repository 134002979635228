import React, { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "../misc/Tabs";
import PromotionTab from "./PromotionTab";
import { PromotionTabsComponentProps, PromotionType } from "./types";
import TabButtons from "./TabButtons";
import PromotionAssetsTab from "./PromotionAssetsTab";
import { Assets } from "../../types/asset-type";
import { isPromotionType } from "./util";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Button } from "../misc/Button";
import { FaEdit } from "react-icons/fa";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import axios from "axios";

const TabsComponent: React.FC<PromotionTabsComponentProps> = ({
    isEditing,
    setIsEditing,
    promotion,
    assets,
    activeTab,
    setActiveTab,
    activePromotionType,
    setActivePromotionType,
    carouselApi,
    carouselAssetIndexMap,
    setLoading,
    setPromotion,
    fetchAssets,
    originalPromotion,
    fetchPromotions,
    setOriginalPromotion,
    setShowSaveWarning,
    selectedImageIndices,
    setSelectedImageIndices,
}) => {
    // State to manage selected categories (assuming it's used within PromotionTab)
    const [selectedCategories, setSelectedCategories] = useState<
        PromotionType[]
    >(promotion?.types || []);

    // State to manage selected media (assuming it's used within PromotionAssetsTab)
    const [selectedMedia, setSelectedMedia] = useState<Assets[]>([]);

    const selectedAssetCount = assets.filter(
        (asset) =>
            isPromotionType(asset.assetType) &&
            selectedCategories.includes(asset.assetType)
    ).length;

    const [isUploading, setIsUploading] = useState(false);

    const isMobile = useIsMobile(1300);

    const handleSave = async () => {
        if (!promotion || !originalPromotion) {
            toast.error(
                <CustomToast
                    message="Failed to save promotion. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        // Check if there are changes
        const hasChanges =
            JSON.stringify(selectedCategories) !==
                JSON.stringify(originalPromotion.types) ||
            promotion.color !== originalPromotion.color;

        if (!hasChanges) {
            toast.success(
                <CustomToast message="Promotion Saved" type="success" />,
                {
                    autoClose: 3000,
                }
            );
            setIsEditing(false);
            return;
        }

        if (!selectedCategories || selectedCategories.length === 0) {
            toast.error(
                <CustomToast
                    message="Please select at least one category."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            return;
        }

        setLoading(true);

        try {
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            console.log("Saving promotion with data:", promotion.id);
            const response = await axios.patch(
                `${backendUrl}/promotions/${promotion.id}`,
                { types: selectedCategories, color: promotion.color },
                { headers }
            );

            console.log("Response data:", response.data);

            if (!response.data) {
                throw new Error("Response data is null.");
            }

            if (response.status === 200) {
                // Assuming response.data contains the updated promotion
                setPromotion(response.data);
                setOriginalPromotion(response.data);
                setIsEditing(false);

                // Check the updated campaignStatus
                if (
                    response.data.campaignStatus?.toString().toLowerCase() ===
                    "active"
                ) {
                    console.log("Setting showSaveWarning to true");
                    setShowSaveWarning(true);
                } else {
                    toast.success(
                        <CustomToast
                            message="Promotion saved successfully."
                            type="success"
                        />,
                        {
                            autoClose: 3000,
                        }
                    );
                }
            } else {
                throw new Error("Failed to save promotion.");
            }
        } catch (error: unknown) {
            console.error("Failed to save promotion:", error);
            toast.error(
                <CustomToast
                    message="Failed to save promotion. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
        } finally {
            setLoading(false);
        }
    };

    const editHandler = () => {
        if (!isEditing) {
            setSelectedCategories(promotion?.types || []);
            setIsEditing(true);
        } else {
            handleSave();
            setIsEditing(false);
        }
    };

    return (
        <Tabs
            value={activeTab}
            onValueChange={(value: string) =>
                setActiveTab(value as "categories" | "promotion")
            }
        >
            {/* Responsive Container: Stack vertically on mobile, horizontally on desktop */}
            <div
                className={`w-full flex ${
                    isMobile ? "flex-col mb-2" : "flex-row mb-4"
                } justify-between items-start md:items-center`}
            >
                {/* TabsList: Scrollable on mobile */}
                <TabsList
                    isMobile={isMobile}
                    className={`w-full ${
                        isMobile
                            ? "flex overflow-x-auto space-x-2 mb-2 mt-2 md:mb-0"
                            : "space-x-4"
                    }`}
                >
                    <TabsTrigger
                        value="categories"
                        className="whitespace-nowrap px-4 py-2 text-sm md:text-base "
                    >
                        Categories
                    </TabsTrigger>
                    {promotion ? (
                        <TabsTrigger
                            value="promotion"
                            className="whitespace-nowrap px-4 py-2 text-sm md:text-base"
                        >
                            Assets ({selectedAssetCount})
                        </TabsTrigger>
                    ) : null}
                    {isMobile && !isEditing ? (
                        <Button
                            id="edit-save-promo-btn"
                            variant="secondary"
                            onClick={editHandler}
                        >
                            <FaEdit className="mr-2 h-6 w-6 text-white" />
                            {isEditing ? "Save" : "Edit"}
                        </Button>
                    ) : null}
                </TabsList>

                {/* Tab Buttons: Positioned below tabs on mobile */}
                <TabButtons
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    promotion={promotion}
                    setLoading={setLoading}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    setPromotion={setPromotion}
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    fetchAssets={fetchAssets}
                    originalPromotion={originalPromotion}
                    fetchPromotions={fetchPromotions}
                    setOriginalPromotion={setOriginalPromotion}
                    isMobile={isMobile}
                    handleSave={handleSave}
                    mediaLength={selectedMedia.length}
                />
            </div>

            {/* Categories Tab Content */}
            <PromotionTab
                isEditing={isEditing}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                promotion={promotion}
                setPromotion={setPromotion}
            />

            {/* Assets Tab Content */}
            {promotion?.id ? (
                <PromotionAssetsTab
                    isEditing={isEditing}
                    assets={assets}
                    promotionTypes={promotion.types}
                    promotionId={promotion.id}
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    carouselApi={carouselApi}
                    carouselAssetIndexMap={carouselAssetIndexMap}
                    fetchAssets={fetchAssets}
                    activePromotionType={activePromotionType}
                    setActivePromotionType={setActivePromotionType}
                    value="promotion"
                    setLoading={setLoading}
                    isMobile={isMobile}
                    selectedImageIndices={selectedImageIndices}
                    setSelectedImageIndices={setSelectedImageIndices}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                />
            ) : null}
        </Tabs>
    );
};

export default TabsComponent;
