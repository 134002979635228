import type React from "react";
import { format, addDays, startOfWeek, isSameDay, compareAsc } from "date-fns";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import DayView from "./DayView";
import type { CalendarProps } from "../../types/calendar-props";
import PostCard from "./PostCard";
import { useIsMobile } from "../../hooks/useIsMobile";

interface WeeklyViewProps extends CalendarProps {
    currentDate: Date;
}

const WeeklyView: React.FC<WeeklyViewProps> = ({
    posts,
    currentDate,
    loading,
    handleMoreClick,
    closeDayView,
    selectedDay,
}) => {
    const isMobile = useIsMobile(700);

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // Start on Monday
    const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

    const renderDayColumn = (day: Date) => {
        const dayPosts = posts
            .filter((post) => isSameDay(new Date(post.scheduledFor), day))
            .sort((a, b) =>
                compareAsc(new Date(a.scheduledFor), new Date(b.scheduledFor))
            );

        const isToday = isSameDay(day, new Date());

        const postsToShow = dayPosts.slice(0, 6);
        const hasMorePosts = dayPosts.length > 6;

        return (
            <div
                id={`${format(day, "EEE-d")}-column`}
                key={day.toString()}
                className="flex-1 w-full min-w-[14.28%] sxl:min-w-[14.28%] h-full flex flex-col"
                style={{
                    backgroundColor: isToday
                        ? "rgba(240, 223, 175, 0.50)"
                        : "transparent",
                    borderRadius: isToday ? "8px" : "0",
                }}
            >
                <div
                    className={`text-sm font-semibold mb-0 border border-b-secondary py-1 px-2.5 ${
                        isToday ? "bg-lightSecondary" : "bg-white"
                    }`}
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                    }}
                >
                    <div
                        className={`flex items-center text-base  ${
                            isMobile ? "justify-start" : "justify-center"
                        }`}
                    >
                        {isMobile ? format(day, "EEE d") : format(day, "EEEE")}
                    </div>
                </div>
                <div className="space-y-4 px-2 py-2 border border-gray-200 flex-1">
                    <div className="text-xs font-semibold">
                        {format(day, "d")}
                    </div>
                    {postsToShow.map((post, index) => (
                        <PostCard
                            date={format(day, "EEE-d")}
                            idx={index}
                            key={post.id}
                            post={post}
                        />
                    ))}
                    {hasMorePosts && (
                        <div
                            className="flex justify-center items-center cursor-pointer mt-2"
                            onClick={() => handleMoreClick(day)}
                        >
                            <HiOutlineDotsHorizontal
                                size={20}
                                className="text-primary"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="flex flex-col sxl:flex-row h-full">
                {weekDays.map(renderDayColumn)}
            </div>
            {selectedDay && (
                <DayView
                    day={selectedDay}
                    posts={posts}
                    onClose={closeDayView}
                    loading={loading}
                />
            )}
        </>
    );
};

export default WeeklyView;
