import { compileAddress } from "../../../utils/addressCompiler";

export const getChanges = <T extends object>(
    original: T,
    updated: T
): Partial<T> => {
    const changes: Partial<T> = {};

    (Object.keys(updated) as Array<keyof T>).forEach((key) => {
        const updatedValue = updated[key];
        const originalValue = original[key];

        if (key === "features" || key === "footer") {
            if (key === "footer" && updatedValue && originalValue) {
                // Serialize agencyAddress using compileAddress if it has changed
                const originalFooter = originalValue as any;
                const updatedFooter = updatedValue as any;

                if (
                    JSON.stringify(updatedFooter.agencyAddress) !==
                    JSON.stringify(originalFooter.agencyAddress)
                ) {
                    changes[key] = {
                        ...updatedFooter,
                        agencyAddress: compileAddress(
                            updatedFooter.agencyAddress
                        ),
                    };
                } else if (
                    JSON.stringify(updatedValue) !==
                    JSON.stringify(originalValue)
                ) {
                    changes[key] = updatedValue;
                }
            } else {
                if (
                    JSON.stringify(updatedValue) !==
                    JSON.stringify(originalValue)
                ) {
                    changes[key] = updatedValue;
                }
            }
            return;
        }

        if (
            typeof updatedValue === "object" &&
            updatedValue !== null &&
            !Array.isArray(updatedValue) &&
            typeof originalValue === "object" &&
            originalValue !== null &&
            !Array.isArray(originalValue)
        ) {
            const nestedChanges = getChanges(originalValue, updatedValue);
            if (Object.keys(nestedChanges).length > 0) {
                changes[key] = nestedChanges as T[keyof T];
            }
        } else if (originalValue !== updatedValue) {
            changes[key] = updatedValue;
        }
    });

    return changes;
};
