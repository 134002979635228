import React from "react";
import { format } from "date-fns";
import { Card, CardContent } from "./Card";
import { Badge } from "./Badge";
import { GoDotFill } from "react-icons/go";
import facebook_logo from "../../assets/images/facebook_logo.png";
import instagram_logo from "../../assets/images/instagram_logo.png";
import { AllPosts } from "../../types/posts";
import { useNavigate } from "react-router-dom";

interface PostCardProps {
    post: AllPosts;
    idx: number;
    date?: string;
}

const PostCard: React.FC<PostCardProps> = ({ post, idx, date }) => {
    const navigate = useNavigate();

    const postTime = format(new Date(post.scheduledFor), "h:mm a");

    const getStatusColor = (status: string) => {
        switch (status.toUpperCase()) {
            case "DRAFT":
                return "#9c27b0";
            case "SCHEDULED":
                return "#FF8E15";
            case "POSTED":
                return "#69B34C";
            case "ERROR":
                return "#FF0D0D";
            case "PAUSED":
                return "#ffe11a";
            case "UNPOSTED":
                return "#8C8C8C";
            default:
                return "#000000";
        }
    };

    const handlePostNavigation = () => {
        console.log(post.id);
        navigate("/post", {
            state: { postId: post.id },
        });
    };
    return (
        <div>
            <Card
                id={`${date}-post-${idx}`}
                className="mb-2 hover:bg-gray-50 h-full cursor-pointer"
                leftBorderColor={post.color}
                onClick={handlePostNavigation}
            >
                <CardContent className="p-2">
                    <div className="flex justify-between items-center">
                        <span className="font-medium text-base truncate block w-full flex-1">
                            {post.contentType
                                .replace(/([A-Z])/g, " $1")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                        </span>
                        <span className="text-xs ml-2 text-gray-500">
                            {postTime}
                        </span>
                    </div>
                    <div className="flex flex-row items-center mb-1">
                        <GoDotFill
                            size={22}
                            color={getStatusColor(post.status)}
                        />
                        <span className="px-2 py-0.5 rounded-full text-xs w-full truncate">
                            {post.status === "DRAFT"
                                ? "Pending Approval"
                                : post.status.charAt(0).toUpperCase() +
                                  post.status.toLowerCase().slice(1)}
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        {post.platform.toLowerCase() === "facebook" ? (
                            <img
                                src={facebook_logo}
                                alt="facebook"
                                className="w-6 h-6 rounded-full"
                            />
                        ) : (
                            <img
                                src={instagram_logo}
                                alt="instagram"
                                className="w-6 h-6"
                            />
                        )}
                        <Badge variant="secondary" className="flex-1">
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                {post.type.toLowerCase() === "listing"
                                    ? post?.address?.split(",")[0]
                                    : post.type.charAt(0).toUpperCase() +
                                      post.type.slice(1)}
                            </span>
                        </Badge>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default PostCard;
