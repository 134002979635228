export const customStatuses = [
    "Warming up the creative engines...",
    "Gathering campaign details...",
    "Mixing up some marketing magic...",
    "Polishing your campaign...",
    "Stitching strategic insights...",
    "Drafting a brilliant outline...",
    "Reviewing campaign angles...",
    "Finalizing key ingredients...",
    "Almost there – hang tight!",
    "Blending creativity & analytics...",
    "Spinning the wheels of genius...",
    "Cooking up the perfect campaign...",
    "Adding the final touches...",
    "Almost ready to launch!",
];
