import { useEffect, useMemo, useState } from "react";
import { customStatuses } from "../../utils/customStatuses";

interface ProcessingStatusProps {
    progress: any;
}

const ProcessingStatus: React.FC<ProcessingStatusProps> = ({ progress }) => {
    const {
        currentStep,
        imagesProcessed,
        videosProcessed,
        totalImages,
        totalVideos,
    } = progress;

    const counter = useMemo(() => {
        if (totalImages && imagesProcessed < totalImages) {
            return imagesProcessed;
        } else if (totalVideos && videosProcessed < totalVideos) {
            return videosProcessed;
        }
        return 0;
    }, [totalImages, imagesProcessed, totalVideos, videosProcessed]);

    const [isStalled, setIsStalled] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(currentStep);

    useEffect(() => {
        setIsStalled(false);
        setDisplayStatus(currentStep);

        const timer = setTimeout(() => {
            setIsStalled(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, [counter, currentStep]);

    useEffect(() => {
        if (!isStalled) return;
        const intervalId = setInterval(() => {
            const randomIndex = Math.floor(
                Math.random() * customStatuses.length
            );
            setDisplayStatus(customStatuses[randomIndex]);
            console.log("Updated random status:", customStatuses[randomIndex]);
        }, 1500);

        return () => clearInterval(intervalId);
    }, [isStalled]);

    return <span>{displayStatus}</span>;
};

export default ProcessingStatus;
