import React, { useEffect, useMemo } from "react";
import { Card, CardContent } from "../misc/Card";
import { itemsPerPage, PromotionAssetsTabProps, PromotionType } from "./types";
import { Button } from "../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DragAndDropMedia from "../misc/DragAndDrop";
import { calculateTotalPages, isPromotionType } from "./util";
import MediaThumbnails from "./MediaThumbnail";
import { Assets } from "../../types/asset-type";
import { DeleteMediaButton } from "./DeleteMediaButton";
import CustomSelect from "../misc/CustomSelect";
import { TabsContent } from "../misc/Tabs";
import { Link, useNavigate } from "react-router-dom";

interface OptionType {
    label: string;
    value: PromotionType;
}

const PromotionAssetsTab: React.FC<PromotionAssetsTabProps> = ({
    isEditing,
    assets,
    promotionTypes,
    promotionId,
    selectedMedia,
    setSelectedMedia,
    carouselApi,
    fetchAssets,
    activePromotionType,
    setActivePromotionType,
    carouselAssetIndexMap,
    value,
    setLoading,
    isMobile,
    onThumbnailClick,
    selectedImageIndices,
    setSelectedImageIndices,
    isUploading,
    setIsUploading,
}) => {
    const navigate = useNavigate();
    const perPage = isMobile ? 4 : itemsPerPage;

    // Initialize currentPages state with each PromotionType set to page 1 or 0 based on asset availability
    const [currentPages, setCurrentPages] = React.useState<{
        [key in PromotionType]?: number;
    }>(() => {
        const initialPages: { [key in PromotionType]?: number } = {};
        promotionTypes.forEach((type) => {
            initialPages[type] = 1; // Default to page 1
        });
        return initialPages;
    });

    // Update currentPages and activePromotionType when promotionTypes or filtered assets change
    useEffect(() => {
        setCurrentPages((prevPages) => {
            const updatedPages = { ...prevPages };
            promotionTypes.forEach((type) => {
                const hasAssets = assets?.some(
                    (asset) =>
                        isPromotionType(asset.assetType) &&
                        asset.assetType === type
                );
                updatedPages[type] = hasAssets ? 1 : 0;
            });
            return updatedPages;
        });

        // Reset activePromotionType if it's no longer valid
        if (
            activePromotionType &&
            !promotionTypes.includes(activePromotionType)
        ) {
            if (promotionTypes.length > 0) {
                setActivePromotionType(promotionTypes[0]);
            }
        }
    }, [promotionTypes, activePromotionType, setActivePromotionType, assets]);

    // Filter assets based on the active promotion type
    const filteredAssets = useMemo(() => {
        if (!activePromotionType) return [];
        return assets?.filter(
            (asset) =>
                isPromotionType(asset.assetType) &&
                asset.assetType === activePromotionType
        );
    }, [assets, activePromotionType]);

    // Calculate total pages based on the number of filtered assets
    const totalPages = useMemo(() => {
        if (!activePromotionType) return 0;
        return filteredAssets?.length || [].length > 0
            ? calculateTotalPages(filteredAssets?.length || 0, perPage)
            : 0;
    }, [activePromotionType, filteredAssets, perPage]);

    // Get the current page for the active promotion type
    const currentPage = activePromotionType
        ? filteredAssets?.length || [].length > 0
            ? currentPages[activePromotionType] || 1
            : 0
        : 0;

    // Toggle media selection
    const toggleSelectMedia = (media: Assets) => {
        setSelectedMedia(
            selectedMedia.some((m) => m.id === media.id)
                ? selectedMedia.filter((m) => m.id !== media.id)
                : [...selectedMedia, media]
        );
    };

    // Handle upload completion
    const handleUploadComplete = () => {
        fetchAssets();
    };

    // Handle page changes
    const handlePageChange = (newPage: number) => {
        if (activePromotionType) {
            setCurrentPages((prevPages) => ({
                ...prevPages,
                [activePromotionType]: newPage,
            }));
        }
    };

    const createPromotionHandler = () => {
        navigate("/promotions", { state: { createPromotion: true } });
    };

    // Create options for the select box
    const promotionTypeOptions: OptionType[] = promotionTypes.map((type) => {
        const count =
            assets?.filter(
                (asset) =>
                    isPromotionType(asset.assetType) && asset.assetType === type
            ).length || 0;

        return {
            value: type,
            label: `${
                type.charAt(0).toUpperCase() +
                type.slice(1).replace(/([A-Z])/g, " $1")
            } (${count})`,
        };
    });

    useEffect(() => {
        if (carouselApi && activePromotionType && selectedImageIndices) {
            if (!filteredAssets) {
                const savedIndex =
                    selectedImageIndices[activePromotionType] || 0;
                carouselApi.scrollTo(savedIndex);
            } else {
                const savedIndex =
                    selectedImageIndices[activePromotionType] || 0;
                const boundedIndex = Math.min(
                    savedIndex,
                    filteredAssets.length - 1
                );
                console.log(
                    `Scrolling to index ${boundedIndex} for Promotion Type ${activePromotionType}`
                );
                carouselApi.scrollTo(boundedIndex);
            }
        }
    }, [
        activePromotionType,
        carouselApi,
        selectedImageIndices,
        filteredAssets,
    ]);

    const handleThumbnailClick = (carouselIndex: number) => {
        if (activePromotionType && setSelectedImageIndices) {
            console.log("activePromotionType", activePromotionType);
            setSelectedImageIndices((prev) => ({
                ...prev,
                [activePromotionType]: carouselIndex,
            }));
        }

        if (onThumbnailClick) {
            onThumbnailClick(carouselIndex);
        }
    };

    return (
        <TabsContent value="promotion">
            {promotionId ? (
                <Card>
                    <CardContent className="pt-6 bg-neutral-100">
                        {promotionTypes.length > 0 ? (
                            <>
                                {/* Promotion Type Select */}
                                <div className="flex justify-center">
                                    <div
                                        className={
                                            isMobile ? "w-[80%]" : "w-[30%]"
                                        }
                                    >
                                        <CustomSelect
                                            id="promotion-type-select-dropdown"
                                            options={promotionTypeOptions}
                                            value={
                                                activePromotionType
                                                    ? promotionTypeOptions.find(
                                                          (option) =>
                                                              option.value ===
                                                              activePromotionType
                                                      )
                                                    : null
                                            }
                                            onChange={(selectedOption) => {
                                                setActivePromotionType(
                                                    selectedOption?.value as PromotionType
                                                );
                                            }}
                                            placeholder="Select Promotion Type"
                                            isClearable={false}
                                            className="mb-4 w-full"
                                            bg="#F5F5F5"
                                        />
                                    </div>
                                </div>
                                {/* Asset Thumbnails and Controls */}
                                {promotionTypes.length > 0 ? (
                                    <div className="bg-white p-4">
                                        {filteredAssets &&
                                        filteredAssets?.length > 0 ? (
                                            <MediaThumbnails
                                                assets={filteredAssets.slice(
                                                    (currentPage - 1) * perPage,
                                                    currentPage * perPage
                                                )}
                                                currentPage={currentPage}
                                                toggleSelectMedia={
                                                    toggleSelectMedia
                                                }
                                                selectedMedia={selectedMedia}
                                                isEditing={isEditing}
                                                carouselApi={carouselApi}
                                                carouselAssetIndexMap={
                                                    carouselAssetIndexMap
                                                }
                                                isMobile={isMobile}
                                                onThumbnailClick={
                                                    handleThumbnailClick
                                                }
                                            />
                                        ) : null}
                                        <div className="flex justify-between items-center mt-4">
                                            <Button
                                                variant="outline"
                                                onClick={() =>
                                                    handlePageChange(
                                                        Math.max(
                                                            currentPage - 1,
                                                            1
                                                        )
                                                    )
                                                }
                                                disabled={currentPage <= 1}
                                            >
                                                <FaArrowLeft className="mr-2 h-4 w-4" />{" "}
                                                Previous
                                            </Button>
                                            <span>
                                                {isMobile ? "" : "Page"}{" "}
                                                {totalPages > 0
                                                    ? currentPage
                                                    : 0}{" "}
                                                of {totalPages}
                                            </span>
                                            <Button
                                                variant="outline"
                                                onClick={() =>
                                                    handlePageChange(
                                                        Math.min(
                                                            currentPage + 1,
                                                            totalPages
                                                        )
                                                    )
                                                }
                                                disabled={
                                                    currentPage >= totalPages ||
                                                    totalPages === 0
                                                }
                                            >
                                                Next{" "}
                                                <FaArrowRight className="ml-2 h-4 w-4" />
                                            </Button>
                                        </div>
                                        {isEditing &&
                                        selectedMedia.length > 0 ? (
                                            <div className="my-2 flex justify-end">
                                                <DeleteMediaButton
                                                    selectedMedia={
                                                        selectedMedia
                                                    }
                                                    setLoading={setLoading}
                                                    promotionId={promotionId}
                                                    fetchAssets={
                                                        handleUploadComplete
                                                    }
                                                    setSelectedMedia={
                                                        setSelectedMedia
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                        <DragAndDropMedia
                                            type="assetImage"
                                            listingId={promotionId}
                                            onUploadComplete={
                                                handleUploadComplete
                                            }
                                            assetType={activePromotionType}
                                            isUploading={isUploading}
                                            setIsUploading={setIsUploading}
                                        />
                                    </div>
                                ) : (
                                    <span>
                                        No types available for this promotion.
                                    </span>
                                )}
                            </>
                        ) : (
                            <span className="text-primary">
                                No promotion types selected. Please add
                                promotion types to start adding media or go to{" "}
                                <Link
                                    to="/my-content"
                                    className="text-secondary hover:text-black"
                                >
                                    my content
                                </Link>{" "}
                                page.
                            </span>
                        )}
                    </CardContent>
                </Card>
            ) : (
                <>
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center w-[510px]">
                        <h2 className="text-2xl font-semibold mb-4 text-primary">
                            No Promotion Created
                        </h2>
                        <p className="mb-2 text-gray-600">
                            Create a new promotion to view promotion media here.
                        </p>
                        <p className="mb-6 text-gray-600">
                            Click the button below to create a new promotion.
                        </p>
                        <button
                            className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-secondary transition-colors"
                            onClick={createPromotionHandler}
                        >
                            Create Promotion
                        </button>
                    </div>
                </>
            )}
        </TabsContent>
    );
};

export default PromotionAssetsTab;
