import { useIsMobile } from "../../hooks/useIsMobile";
import { Button } from "./Button";

interface StatusOverlayProps {
    isOpen: boolean;
    title: string;
    message: string;
    messageTwo?: string;
    messageThree?: string;
    allowNext: boolean;
    onNext: () => void;
    onBack?: () => void;
}

const StatusOverlay: React.FC<StatusOverlayProps> = ({
    isOpen,
    title,
    message,
    messageTwo,
    messageThree,
    allowNext,
    onNext,
    onBack,
}) => {
    const isMobile = useIsMobile(900);
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm p-4 z-50">
            <div
                className={`relative ${
                    isMobile ? "w-full max-w-md" : "w-1/3"
                } rounded-lg bg-white p-6 shadow-lg z-50`}
            >
                <div className="flex flex-col items-center text-center">
                    <h2 className="mt-4 text-xl sm:text-2xl font-bold text-primary">
                        {title}
                    </h2>
                    <p className="mt-2 text-gray-600 text-sm sm:text-base">
                        {message}
                    </p>
                    {messageTwo && (
                        <p className="mt-1 text-gray-600 text-sm sm:text-base">
                            {messageTwo}
                        </p>
                    )}
                    {messageThree && (
                        <p className="mt-1 text-gray-600 text-sm sm:text-base">
                            {messageThree}
                        </p>
                    )}
                    <div
                        className={`mt-6 w-full gap-2 ${
                            isMobile
                                ? "flex flex-col space-y-2"
                                : "flex justify-between sapce-x-2"
                        }`}
                    >
                        {onBack && onNext ? (
                            <Button
                                onClick={() => {
                                    title === "Invalid Listing Status"
                                        ? onNext()
                                        : onBack();
                                }}
                                variant="secondary"
                                className="w-full sm:w-auto"
                            >
                                Back
                            </Button>
                        ) : null}
                        {allowNext && (
                            <Button
                                onClick={onNext}
                                className="w-full sm:w-auto"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusOverlay;
