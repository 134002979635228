import React, { useEffect, useState } from "react";
import { ContentTabsProps, promotionTypes } from "./types";
import { Tabs, TabsList, TabsTrigger } from "../misc/Tabs";
import ListingsTab from "./ListingsTab";
import PromotionAssetsTab from "../promotions/PromotionAssetsTab";
import { Assets } from "../../types/asset-type";
import { Button } from "../misc/Button";
import { FaCircleCheck, FaPencil, FaCircleQuestion } from "react-icons/fa6";
import ResourcesTab from "./ResourcesTab";

const ContentTabs: React.FC<ContentTabsProps> = ({
    promotions,
    activeTab,
    setActiveTab,
    listingMedia,
    listingTabs,
    activeListingTab,
    setActiveListingTab,
    loading,
    mlsApproved,
    carouselApi,
    fetchAssets,
    fetchListingMedia,
    setLoading,
    carouselIndexMap,
    resources,
    fetchResources,
    isMobile,
    activeMediaTab,
    setActiveMediaTab,
    activePromotionType,
    setActivePromotionType,
    activeResourceType,
    setActiveResourceType,
    onThumbnailClick,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Assets[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        setSelectedMedia([]);
    }, [setIsEditing]);

    return (
        <Tabs
            value={activeTab}
            onValueChange={(value: string) =>
                setActiveTab(value as "listing" | "promotion" | "resource")
            }
        >
            <div
                className={`flex ${
                    isMobile ? "flex-col" : "flex-row"
                } justify-between mb-4`}
            >
                <div className="flex items-center">
                    <TabsList
                        isMobile={isMobile}
                        className={`${isMobile ? "mb-2" : ""}`}
                    >
                        <TabsTrigger id="listings-tab" value="listing">
                            Listings
                        </TabsTrigger>
                        <TabsTrigger id="promotions-tab" value="promotion">
                            Promotion
                        </TabsTrigger>
                        <TabsTrigger id="resources-tab" value="resource">
                            Shared Media
                        </TabsTrigger>
                    </TabsList>
                    {/* Tooltip Icon */}
                    <div className="relative group ml-2">
                        <FaCircleQuestion
                            className={`cursor-pointer w-5 h-5 text-gray-600 ${
                                isMobile && `mb-2`
                            }`}
                        />
                        <div
                            className={`absolute z-10 top-full mt-2 border border-primary ${
                                isMobile
                                    ? "-left-52 transform-none w-64"
                                    : "left-1/2 transform -translate-x-1/2 w-64"
                            } px-2 py-1 text-xs text-black bg-white rounded opacity-0 group-hover:opacity-100 transition-opacity`}
                        >
                            <p className="mb-1">
                                <strong className="text-primary">
                                    Listings:
                                </strong>{" "}
                                Manage user uploaded listing media - images and
                                videos.
                            </p>
                            <p className="mb-1">
                                <strong className="text-primary">
                                    Promotion:
                                </strong>{" "}
                                Manage user uploaded promotion images. These
                                images will be prioritized over the Marabot
                                stock library.
                            </p>
                            <p>
                                <strong className="text-primary">
                                    Shared Media:
                                </strong>{" "}
                                Manage user uploaded media that is not linked to
                                a listing or promotion campaign, ideal for ad
                                hoc posts, or manual edits to posts.
                            </p>
                        </div>
                    </div>
                </div>
                <Button
                    id="edit-done-btn"
                    onClick={() => {
                        setIsEditing(!isEditing);
                        setSelectedMedia([]);
                    }}
                    className={`${isMobile ? "w-full" : ""}`}
                >
                    {isEditing ? (
                        <FaCircleCheck className="mr-1 w-4 h-4" />
                    ) : (
                        <FaPencil className="mr-1 w-4 h-4" />
                    )}
                    {isEditing ? "Done" : "Edit"}
                </Button>
            </div>
            <div className="relative">
                <ListingsTab
                    listingMedia={listingMedia}
                    listingTabs={listingTabs}
                    activeListingTab={activeListingTab}
                    setActiveListingTab={setActiveListingTab}
                    activeTab={activeTab}
                    loading={loading}
                    mlsApproved={mlsApproved}
                    carouselApi={carouselApi}
                    setLoading={setLoading}
                    fetchListingMedia={fetchListingMedia}
                    carouselIndexMap={carouselIndexMap}
                    isEditing={isEditing}
                    isMobile={isMobile}
                    activeMediaTab={activeMediaTab}
                    setActiveMediaTab={setActiveMediaTab}
                    onThumbnailClick={onThumbnailClick}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                />
            </div>
            <PromotionAssetsTab
                isEditing={isEditing}
                assets={
                    Array.isArray(promotions?.assets) ? promotions?.assets : []
                }
                promotionTypes={promotionTypes}
                promotionId={promotions?.id}
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                carouselApi={carouselApi}
                fetchAssets={fetchAssets}
                activePromotionType={activePromotionType}
                setActivePromotionType={setActivePromotionType}
                carouselAssetIndexMap={carouselIndexMap}
                value="promotion"
                setLoading={setLoading}
                isMobile={isMobile}
                onThumbnailClick={onThumbnailClick}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
            />
            <ResourcesTab
                resources={resources}
                isEditing={isEditing}
                carouselApi={carouselApi}
                carouselIndexMap={carouselIndexMap}
                fetchResources={fetchResources}
                setLoading={setLoading}
                isMobile={isMobile}
                activeResourceType={activeResourceType}
                setActiveResourceType={setActiveResourceType}
                onThumbnailClick={onThumbnailClick}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
            />
        </Tabs>
    );
};

export default ContentTabs;
