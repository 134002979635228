import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import NoItems from "../misc/NoItems";
import LoadingScreen from "../../screens/LoadingScreen";
import ListingDisplay from "./ListingDisplay";
import { AllListings } from "../../types/allListings";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import WelcomeListing from "./WelcomeListing";
import { SocketProvider } from "../../context/SocketContext";
import axios from "axios";
import MLSWelcome from "./MLSWelcome";

interface UserListingsProps {
    mlsApproved: boolean | null;
    mlsWelcome: boolean;
}

const UserListings: React.FC<UserListingsProps> = ({
    mlsApproved,
    mlsWelcome,
}) => {
    const listingsCreated = useSelector(
        (state: RootState) => state.auth.listingCreated
    );

    const navigate = useNavigate();

    const [listings, setListings] = useState<AllListings[]>([]);

    const [listingsActiveLimit, setListingsActiveLimit] = useState<number>(0);
    const [listingsLimit, setListingsLimit] = useState<number>(0);
    const [listingsWarn, setListingsWarn] = useState<number>(0);

    const [loading, setLoading] = useState(true);

    const hasFetched = useRef(false); // Ref to prevent duplicate fetches

    const [userStateUpdated, setUserStateUpdated] = useState(true);

    const [canceledMlsWelcome, setCanceledMlsWelcome] = useState(false);

    const checkUser = useCallback(async () => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const headers = await getAuthHeader();

        try {
            const response = await axios.post(
                `${backendUrl}/listing/check-user`,
                {
                    mlsApproved,
                    mlsWelcome,
                },
                { headers }
            );

            if (response.status === 200) {
                setUserStateUpdated(response.data);
            }
        } catch (error) {
            console.error("Error checking user:", error);
        }
    }, [mlsApproved, mlsWelcome]);

    useEffect(() => {
        checkUser();
    }, [checkUser]);

    useEffect(() => {
        if (!userStateUpdated) {
            window.location.reload();
        }
    }, [userStateUpdated]);

    const fetchListings = useCallback(async () => {
        try {
            setLoading(true);
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            if (!backendUrl) {
                throw new Error("REACT_APP_BACKEND_URL is not defined.");
            }

            const response = await fetch(`${backendUrl}/listing`, { headers });

            console.log("response in UserListings:", response);

            if (!response.ok) {
                throw new Error(
                    "Failed to fetch listings. Please try again later."
                );
            }

            const responseBody = response.body;
            if (!responseBody) {
                throw new Error("Response body is null.");
            }

            const reader = responseBody.getReader();
            const decoder = new TextDecoder();
            let { value, done } = await reader.read();
            let buffer = "";
            const fetchedListings: AllListings[] = [];

            let isFirstLine = true;
            let isSecondLine = true;
            let isThirdLine = true;

            while (!done) {
                buffer += decoder.decode(value, { stream: true });
                let lines = buffer.split("\n");
                buffer = lines.pop() || "";

                for (let line of lines) {
                    if (line.trim()) {
                        if (isFirstLine) {
                            console.log("Setting listings limit:", line.trim());
                            setListingsActiveLimit(Number(line.trim()));
                            isFirstLine = false;
                            continue;
                        } else if (isSecondLine) {
                            setListingsWarn(Number(line.trim()));
                            isSecondLine = false;
                            continue;
                        } else if (isThirdLine) {
                            setListingsLimit(Number(line.trim()));
                            isThirdLine = false;
                            continue;
                        }

                        try {
                            const listing = JSON.parse(line);
                            fetchedListings.push(listing);
                        } catch (parseError) {
                            console.error(
                                "Error parsing listing:",
                                parseError,
                                "Line:",
                                line
                            );
                        }
                    }
                }

                const result = await reader.read();
                value = result.value;
                done = result.done;
            }
            // Process any remaining data
            if (buffer.trim()) {
                try {
                    const listing = JSON.parse(buffer);
                    fetchedListings.push(listing);
                } catch (parseError) {
                    console.error(
                        "Error parsing final listing:",
                        parseError,
                        "Buffer:",
                        buffer
                    );
                }
            }

            // Optionally remove duplicates based on unique identifier
            const uniqueListings = fetchedListings.reduce(
                (acc: AllListings[], current) => {
                    const exists = acc.some((item) => item.id === current.id);
                    if (!exists) acc.push(current);
                    return acc;
                },
                []
            );

            setListings(uniqueListings);
        } catch (error) {
            toast.error(
                <CustomToast
                    message="Failed to fetch listings. Please try again later."
                    type="error"
                />,
                {
                    autoClose: 3000,
                }
            );
            console.error("Error fetching listings:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (hasFetched.current) return; // Prevent duplicate fetches
        hasFetched.current = true;

        fetchListings();
    }, [fetchListings]);

    const createListingHandler = () => {
        navigate("/create-listing");
    };

    const welcome: boolean = sessionStorage.getItem("welcome") === "true";
    console.log("mlsWelcome in UserListings:", mlsWelcome);
    return (
        <SocketProvider>
            <div>
                {loading && <LoadingScreen />}
                {mlsWelcome && !canceledMlsWelcome && (
                    <MLSWelcome
                        setCancelMlsWelcome={setCanceledMlsWelcome}
                        listingCount={listings?.length || 0}
                    />
                )}
                {!loading &&
                listings.length === 0 &&
                listingsCreated &&
                !mlsWelcome ? (
                    <NoItems
                        title="No Listings Found"
                        description="You have not created any listings yet."
                        descriptionTwo="Click the button below to create a new listing."
                        buttonText="Create Listing"
                        clickHandler={createListingHandler}
                    />
                ) : !loading && listings.length === 0 && !mlsWelcome ? (
                    <WelcomeListing welcome={welcome} />
                ) : null}
                {!loading && listings.length > 0 && (
                    <ListingDisplay
                        listings={listings}
                        mlsApproved={mlsApproved || false}
                        fetchListings={fetchListings}
                        listingsActiveLimit={listingsActiveLimit}
                        listingsWarn={listingsWarn}
                        listingsLimit={listingsLimit}
                    />
                )}
            </div>
        </SocketProvider>
    );
};

export default UserListings;
