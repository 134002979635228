import React from "react";
import { NoItemProps } from "./types";
import { X } from "lucide-react";

const NoItems: React.FC<NoItemProps> = ({
    title,
    description,
    descriptionTwo,
    buttonText,
    clickHandler,
    close,
}) => {
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex flex-col items-center justify-center z-40">
            <div className="relative bg-white p-8 rounded-lg shadow-lg text-center">
                {close ? (
                    <X
                        className="absolute top-4 right-4 text-primary hover:text-secondary cursor-pointer"
                        onClick={() => close()}
                    />
                ) : null}
                <h2 className="text-2xl font-semibold mb-4 text-primary">
                    {title}
                </h2>
                <p className="mb-2 text-gray-600">{description}</p>
                {descriptionTwo && (
                    <p className="mb-6 text-gray-600">{descriptionTwo}</p>
                )}
                <button
                    className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-secondary transition-colors"
                    onClick={clickHandler}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default NoItems;
