import React from "react";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";

interface DataLoadingErrorProps {
    heading: string;
    text: string;
    textTwo?: string;
    setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
    step?: number;
}

const DataLoadingError: React.FC<DataLoadingErrorProps> = ({
    heading,
    text,
    textTwo,
    setCurrentStep,
    step,
}) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile(900);
    return (
        <div className="absolute w-full h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm p-4 z-50">
            <div
                className={`relative ${
                    isMobile ? "w-full max-w-md" : "w-1/3"
                } rounded-lg bg-white p-6 shadow-lg z-50`}
            >
                <div className="flex flex-col items-center text-center">
                    <h2 className="mt-4 text-xl sm:text-2xl font-bold text-primary">
                        {heading}
                    </h2>
                    <p className="mt-2 text-gray-600 text-sm sm:text-base">
                        {text}
                    </p>
                    {textTwo && (
                        <p className="mt-1 text-gray-600 text-sm sm:text-base">
                            {textTwo}
                        </p>
                    )}
                    <div
                        className={`mt-6 w-full gap-2 ${
                            isMobile
                                ? "flex flex-col space-y-2"
                                : "flex justify-between sapce-x-2"
                        }`}
                    >
                        <Button
                            onClick={() =>
                                setCurrentStep && step
                                    ? setCurrentStep(step)
                                    : navigate(-1)
                            }
                            variant="secondary"
                            className="w-full sm:w-auto"
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataLoadingError;
