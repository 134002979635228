import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import ListingHeader from "../listing/ListingHeader";
import MediaCarousel from "../../misc/MediaCarousel";
import { MediaAdderProps } from "./types";
import LoadingScreen from "../../../screens/LoadingScreen";
import { CarouselApi } from "../../misc/Carousel";
import { CombinedImage, CombinedMedia, CombinedVideo } from "../listing/types";
import { Images } from "../../../types/listingImages";
import { ListingVideos } from "../../../types/listingVideos";
import ImageTagCard from "../listing/ImageTagCard";
import { Tabs, TabsList, TabsTrigger } from "../../misc/Tabs";
import ImagesTab from "../listing/tabs/ImagesTab";
import VideosTab from "../listing/tabs/VideosTab";
import { getAuthHeader } from "../../../utils/authHeader";
import { UserListing } from "../../../types/userListing";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { useNavigate } from "react-router-dom";
import { Button } from "../../misc/Button";

const MediaAdder: React.FC<MediaAdderProps> = ({
    isMobile,
    mlsApproved,
    propertyData,
    setPropertyData,
    setCurrentStep,
}) => {
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const element = document.getElementById("media-adder");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const [loading, setLoading] = useState(false);

    const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
    const [activeTab, setActiveTab] = useState<"images" | "videos">("images");

    const [images, setImages] = useState<Images[]>(propertyData?.images || []);
    const [videos, setVideos] = useState<ListingVideos[]>(
        propertyData?.videos || []
    );
    const [isUploading, setIsUploading] = useState(false);

    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    const combinedMedia: CombinedMedia[] = [
        ...images.map(
            (image) => ({ ...image, type: "image" } as CombinedImage)
        ),
        ...videos.map(
            (video) => ({ ...video, type: "video" } as CombinedVideo)
        ),
    ];

    const combinedImages = useMemo(
        () =>
            images.map(
                (image) => ({ ...image, type: "image" } as CombinedImage)
            ),
        [images]
    );

    const combinedVideos = useMemo(
        () =>
            videos.map(
                (video) => ({ ...video, type: "video" } as CombinedVideo)
            ),
        [videos]
    );

    const [carouselIndices, setCarouselIndices] = useState<{
        images: number;
        videos: number;
        details: number;
    }>({
        images: 0,
        videos: 0,
        details: 0,
    });

    useEffect(() => {
        if (carouselApi) {
            const index = carouselIndices[activeTab] || 0;
            carouselApi.scrollTo(index);
        }
    }, [activeTab, carouselApi, carouselIndices]);

    const handleCarouselScroll = (newIndex: number) => {
        setCarouselIndices((prev) => ({
            ...prev,
            [activeTab]: newIndex,
        }));
    };

    const fetchMedia = async () => {
        try {
            setLoading(true);
            const headers = await getAuthHeader();
            const backendUrl = process.env.REACT_APP_BACKEND_URL;

            const response = await fetch(
                `${backendUrl}/listing/${
                    propertyData?.id || propertyData?._id.toString()
                }`,
                {
                    headers,
                    method: "GET",
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            if (data) {
                const updatedData: UserListing = data;
                setPropertyData(updatedData);

                setImages(data.images || []);
                setVideos(data.videos || []);
            }
        } catch (error) {
            console.error("Error fetching listing:", error);
            toast.error(
                <CustomToast message="Failed to load listing." type="error" />,
                {
                    autoClose: 3000,
                }
            );

            setTimeout(() => {
                navigate("/listings");
            }, 3000);
        } finally {
            setLoading(false);
        }
    };

    if (!propertyData) {
        setTimeout(() => {
            window.location.href = "/listings";
        }, 3000);
        return <LoadingScreen />;
    }

    console.log("Property Data in MediaAdder:", propertyData);
    return (
        <main
            id="media-adder"
            className={`flex overflow-auto flex-col justify-center items-center min-h-full shadow-[0px_4px_4px_rgba(0,0,0,0.25)] ${
                isMobile ? "px-0 bg-white" : "bg-background"
            }`}
        >
            {loading && <LoadingScreen />}

            <div className={`${isMobile ? "w-[90%]" : "w-[60%]"} py-10`}>
                <h1 className="mb-6 text-primary text-3xl font-bold leading-none text-center">
                    Add Media
                </h1>
                <div className={`flex flex-col`}>
                    {/* Left Column */}
                    <div className={!isMobile ? "px-20" : ""}>
                        <div className={`flex flex-col w-full`}>
                            {/* Listing Header */}
                            <ListingHeader
                                formattedAddress={propertyData.formattedAddress}
                                propertyType={propertyData.propertyType}
                                status={propertyData.status}
                            />
                            {/* Media Carousel */}
                            <MediaCarousel
                                key={activeTab}
                                setCarouselApi={setCarouselApi}
                                combinedMedia={
                                    activeTab === "images"
                                        ? combinedImages
                                        : combinedVideos
                                }
                                isMobile={isMobile}
                                latitude={propertyData.latitude}
                                longitude={propertyData.longitude}
                                address={propertyData.formattedAddress}
                            />
                        </div>
                        <ImageTagCard
                            combinedMedia={combinedMedia}
                            carouselApi={carouselApi}
                            propertyData={propertyData}
                            setImages={setImages}
                            images={images}
                            setPropertyData={setPropertyData}
                        />
                        <div
                            className={`${isMobile ? "my-2" : "my-0 px-0"} ${
                                images?.length > 0 ? "mt-2" : ""
                            }`}
                        >
                            <Tabs
                                value={activeTab}
                                onValueChange={(value: string) =>
                                    setActiveTab(value as "images" | "videos")
                                }
                            >
                                <div
                                    className={`flex ${
                                        isMobile
                                            ? "flex-col"
                                            : "flex-row justify-between items-center"
                                    }`}
                                >
                                    <TabsList
                                        className={`${
                                            isMobile
                                                ? "flex overflow-x-auto space-x-2 pb-2"
                                                : "space-x-4 w-full"
                                        }`}
                                    >
                                        <TabsTrigger value="images">
                                            Images{" "}
                                            {isMobile
                                                ? ""
                                                : `(${images.length})`}
                                        </TabsTrigger>
                                        <TabsTrigger value="videos">
                                            Videos{" "}
                                            {isMobile
                                                ? ""
                                                : `(${videos.length})`}
                                        </TabsTrigger>
                                    </TabsList>
                                </div>
                                {/* Images Tab */}
                                <ImagesTab
                                    images={images}
                                    listingId={propertyData._id}
                                    handleUploadComplete={fetchMedia}
                                    toggleSelectMedia={() => {}}
                                    selectedMedia={[]}
                                    isEditing={false}
                                    carouselApi={carouselApi}
                                    combinedMedia={combinedImages}
                                    mlsApproved={mlsApproved}
                                    setSelectedMedia={() => {}}
                                    setIsLoading={setLoading}
                                    itemsPerPage={10}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    onCarouselScroll={handleCarouselScroll}
                                    propertyStatus={propertyData.status || ""}
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    listingType={propertyData.listingType}
                                />

                                {/* Videos Tab */}
                                <VideosTab
                                    videos={videos}
                                    listingId={propertyData._id}
                                    address1={
                                        propertyData.formattedAddress.split(
                                            ","
                                        )[0]
                                    }
                                    handleUploadComplete={fetchMedia}
                                    toggleSelectMedia={() => {}}
                                    selectedMedia={[]}
                                    isEditing={false}
                                    carouselApi={carouselApi}
                                    combinedMedia={combinedVideos}
                                    mlsApproved={mlsApproved}
                                    setSelectedMedia={() => {}}
                                    setIsLoading={setLoading}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={10}
                                    onCarouselScroll={handleCarouselScroll}
                                    propertyStatus={propertyData.status || ""}
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    listingType={propertyData.listingType}
                                />
                            </Tabs>
                        </div>
                        <div className="mt-6 flex justify-between items-center">
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setCurrentStep(3);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/listing", {
                                        state: {
                                            listingId:
                                                propertyData.id ||
                                                propertyData._id.toString(),
                                        },
                                    });
                                }}
                            >
                                Finish
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default MediaAdder;
